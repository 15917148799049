import { ActionItem, ActionMenu } from "@barscience/global-components";
import { useNavigate } from "react-router-dom";

export default function HeaderActions() {
  const navigate = useNavigate();

 return (
  <ActionMenu label='New'>
    <ActionItem label='Community post' onClick={() => { navigate('/community/posts/create'); }} />
  </ActionMenu>
 );
}