import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, CircularSpinner, Colors, ConfirmModal, ErrorPage, FormModal, Icons, InfoPanel, Link as StyledLink, Modal, ModalBody, ModalFooter, ModalHeader, ModalLauncher, NotFound, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextField, View, generateId, useAlertState, NoPermission, Icon, Checkbox } from "@barscience/global-components";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link, useNavigate, useParams } from "react-router-dom";
import HasAgentPermission from "../../components/auth/HasAgentPermission";
import { useState } from "react";
import { KNOWLEDGE_BASE_PERMISSIONS_INFO_LINK } from "./KBAdminHome";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

/* Get Category Query */
const GET_CATEGORY = gql`
query getSupportArticleCategoryDetailsForAdmins($id: ID!) {
  supportArticleCategory(id: $id) {
    id
    name
    description
    icon
    folders {
      id
      name
      description
    }
    canPublish
    isHiddenFromHomepage
  }
}
`;

type GetCategoryResponse = {
  supportArticleCategory: Category | null;
}

type Category = {
  id: string;
  name: string;
  description: string | null;
  icon: string;
  folders: Folder[] | null;
  canPublish: boolean;
  isHiddenFromHomepage: boolean;
}

type Folder = {
  id: string;
  name: string;
  description: string | null;
}

/* Edit Category Mutation */
const EDIT_CATEGORY = gql`
mutation editSupportArticleCategory($id: ID!, $input: EditSupportArticleCategoryInput!) {
  editSupportArticleCategory(id: $id, input: $input) {
    id
    name
    description
    icon
    isHiddenFromHomepage
  }
}
`;

type EditCategoryResponse = {
  editSupportArticleCategory: Category | null;
}

type EditCategoryInput = {
  name: string;
  description: string;
  icon: string;
  isHiddenFromHomepage: boolean;
}

/* Delete Category Mutation */
const DELETE_CATEGORY = gql`
mutation deleteSupportArticleCategory($id: ID!) {
  deleteSupportArticleCategory(id: $id) {
    id
  }
}
`;

type DeleteCategoryResponse = {
  deleteSupportArticleCategory: {
    id: string;
  } | null;
}

/* Get Category Permissions Query */
const GET_CATEGORY_PERMISSIONS = gql`
query getPermissionsForSupportArticleCategory($id: ID!) {
  permissionsForSupportArticleCategory(id: $id) {
    user {
      id
      firstName
      lastName
      email
    }
    permission
  }
}
`;

type GetCategoryPermissionsResponse = {
  permissionsForSupportArticleCategory: PermissionAssignment[] | null;
}

type PermissionAssignment = {
  user: User;
  permission: Permission;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
}

enum Permission {
  EDIT = 'EDIT',
  PUBLISH = 'PUBLISH',
}

/* Grant Permission Mutation */
const GRANT_PERMISSION = gql`
mutation grantSupportArticleCategoryPermission($categoryId: ID!, $userId: ID!, $permission: KnowledgeBasePermission!) {
  grantSupportArticleCategoryPermission(categoryId: $categoryId, userId: $userId, permission: $permission) {
    user {
      id
      firstName
      lastName
      email
    }
    permission
  }
}
`;

type GrantPermissionResponse = {
  grantSupportArticleCategoryPermission: PermissionAssignment | null;
}

/* Edit Permission Mutation */
const EDIT_PERMISSION = gql`
mutation editSupportArticleCategoryPermission($categoryId: ID!, $userId: ID!, $permission: KnowledgeBasePermission!) {
  editSupportArticleCategoryPermission(categoryId: $categoryId, userId: $userId, permission: $permission) {
    user {
      id
    }
    permission
  }
}
`;

type EditPermissionResponse = {
  editSupportArticleCategoryPermission: {
    user: {
      id: string;
    };
    permission: Permission;
  } | null;
}

/* Revoke Permission Mutation */
const REVOKE_PERMISSION = gql`
mutation revokeSupportArticleCategoryPermission($categoryId: ID!, $userId: ID!) {
  success: revokeSupportArticleCategoryPermission(categoryId: $categoryId, userId: $userId)
}
`;

type RevokePermissionResponse = {
  success: boolean;
}

/* Search Agents */
export const SEARCH_AGENTS = gql`
query searchSupportAgents($name: String, $email: String) {
  supportAgents(name: $name, email: $email) {
    id
    firstName
    lastName
    email
  }
}
`;

export type SearchAgentsResponse = {
  supportAgents: User[] | null;
}

/* Create Folder Mutation */
const CREATE_FOLDER = gql`
mutation createSupportArticleFolder($input: CreateSupportArticleFolderInput!) {
  createSupportArticleFolder(input: $input) {
    id
    name
    description
  }
}
`;

type CreateFolderResponse = {
  createSupportArticleFolder: Folder | null;
}

type CreateFolderInput = {
  name: string;
  description: string;
}

/* Edit Folder Order Mutation */
const EDIT_FOLDER_ORDER = gql`
mutation editSupportArticleFolderOrder($categoryId: ID!, $order: [ID!]!) {
  success: editSupportArticleFolderOrder(categoryId: $categoryId, order: $order)
}
`;

type EditFolderOrderResponse = {
  success: boolean;
}

export default function KBAdminCategory() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { addAlert } = useAlertState();
  const { state: agentState } = useAgentAuthState();
  const [isEditingOrder, setIsEditingOrder] = useState<boolean>(false);
  const { data: categoryData, loading: categoryIsLoading, error: categoryError } = useQuery<GetCategoryResponse>(GET_CATEGORY, {
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      if (data.supportArticleCategory) {
        setFolderOrder(data.supportArticleCategory.folders || []);
      }
    },
  });
  const [editCategory] = useMutation<EditCategoryResponse>(EDIT_CATEGORY);
  const [deleteCategory] = useMutation<DeleteCategoryResponse>(DELETE_CATEGORY, {
    update(cache, { data }) {
      if (data?.deleteSupportArticleCategory) {
        cache.modify({
          fields: {
            supportArticleCategories(existingCategories = [], { readField }) {
              return existingCategories.filter((categoryRef: any) => data.deleteSupportArticleCategory?.id !== readField('id', categoryRef));
            },
          },
        });
      }
    },

  });
  const [createFolder] = useMutation<CreateFolderResponse>(CREATE_FOLDER, {
    update(cache, { data }) {
      if (data?.createSupportArticleFolder && categoryData?.supportArticleCategory) {
        cache.modify({
          id: cache.identify(categoryData.supportArticleCategory),
          fields: {
            folders(existingFolders = []) {
              const newFolderRef = cache.writeFragment({
                data: data.createSupportArticleFolder,
                fragment: gql`
                  fragment NewFolder on SupportArticleFolder {
                    id
                    name
                    description
                  }
                `,
              });

              return [...existingFolders, newFolderRef];
            },
          },
        });

        setFolderOrder([...folderOrder, data.createSupportArticleFolder]);
      }
    },

  });
  const [editFolderOrder, { loading: editFolderOrderIsLoading }] = useMutation<EditFolderOrderResponse>(EDIT_FOLDER_ORDER);
  const [folderOrder, setFolderOrder] = useState<Folder[]>([]);

  /* Edit Category */
  const handleEditCategory = async (values: EditCategoryInput) => {
    const { errors } = await editCategory({
      variables: {
        id: id,
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          icon: values.icon,
          isHiddenFromHomepage: values.isHiddenFromHomepage,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update category' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editCategoryModal = (
    <FormModal<EditCategoryInput> title='Edit Category' onSubmit={handleEditCategory} initialValues={{ name: categoryData?.supportArticleCategory?.name || '', description: categoryData?.supportArticleCategory?.description || '', icon: categoryData?.supportArticleCategory?.icon || '', isHiddenFromHomepage: categoryData?.supportArticleCategory?.isHiddenFromHomepage || false }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' required />
        <TextField label='Description' name='description' />
        <TextField label='Icon' name='icon' description='The FontAwesome ID of the icon' required />
        <Checkbox label='Hide from Help Center home page' name='isHiddenFromHomepage' />
      </View>
    </FormModal>
  );

  /* Delete Category */
  const handleDeleteCategory = async () => {
    const { errors } = await deleteCategory({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to delete category' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Category deleted' type='success' id={id} />
      addAlert(id, alert);

      navigate('/agent/kb');
    }
  }

  const deleteCategoryModal = (
    <ConfirmModal title='Delete Category?' confirmLabel='Delete' destructive onConfirm={handleDeleteCategory}>
      <StyledParagraph>This category will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  /* Create Folder */
  const handleCreateFolder = async (values: CreateFolderInput) => {
    const { errors } = await createFolder({
      variables: {
        input: {
          categoryId: id,
          name: values.name,
          description: values.description ? values.description : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to create folder' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const createFolderModal = (
    <FormModal<CreateFolderInput> title='Create Folder' submitLabel='Create' onSubmit={handleCreateFolder} initialValues={{ name: '', description: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' required />
        <TextField label='Description' name='description' />
      </View>
    </FormModal>
  );

  /* Edit Folder Order */
  const handleDragEnd = async (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const newFolderOrder = Array.from(folderOrder);
    newFolderOrder.splice(source.index, 1);
    newFolderOrder.splice(destination.index, 0, folderOrder[source.index]);

    setFolderOrder(newFolderOrder);
  }

  const handleSaveOrder = async () => {
    const { errors } = await editFolderOrder({
      variables: {
        categoryId: id,
        order: folderOrder.map((folder) => folder.id),
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error saving folder order' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Folder order saved' type='success' id={id} />
      addAlert(id, alert);
    }

    setIsEditingOrder(false);
  }

  if (!agentState.user?.isSupportAgent) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (categoryIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (categoryError || !categoryData?.supportArticleCategory) {
    if (categoryError?.graphQLErrors[0]?.extensions.status === 404) {
      return (
        <StandardGrid>
          <NotFound />
        </StandardGrid>
      );
    }

    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Knowledge Base' to='/agent/kb' />
          <Breadcrumb label={categoryData?.supportArticleCategory.name} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
          <StyledHeading tag='h4'>{categoryData?.supportArticleCategory.name}</StyledHeading>

          <View style={{ flexDirection: 'row', gap: '24px' }}>
            {(categoryData.supportArticleCategory.canPublish || agentState.user?.supportAgentPermissions?.canManageAllArticles) && !isEditingOrder && (
              <ModalLauncher modal={createFolderModal}>
                {({ openModal }) => (
                  <Button label='Create Folder' leftIcon={Icons.Plus} iconSize='medium' variant='secondary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            )}

            {(categoryData.supportArticleCategory.canPublish || agentState.user?.supportAgentPermissions?.canManageAllArticles) &&
              <>
                {isEditingOrder ?
                  <Button label='Save order' variant='tertiary' role='button' loading={editFolderOrderIsLoading} action={handleSaveOrder} />
                  :
                  <Button label='Reorder' leftIcon={Icons.Reorder} variant='tertiary' role='button' action={() => { setIsEditingOrder(true); }} />
                }
              </>
            }

            {!isEditingOrder && <HasAgentPermission permissions={['canManageAllArticles']}>
              <ModalLauncher modal={<CategoryPermissionsModal id={id || ''} />}>
                {({ openModal }) => (
                  <Button label='Share' leftIcon={Icons.UserPlus} iconSize='medium' variant='tertiary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            </HasAgentPermission>}

            {!isEditingOrder && <HasAgentPermission permissions={['canManageAllArticles']}>
              <ModalLauncher modal={editCategoryModal}>
                {({ openModal: openEditModal }) => (
                  <ModalLauncher modal={deleteCategoryModal}>
                    {({ openModal: openDeleteModal }) => (
                      <ActionMenu alignment='right'>
                        <ActionItem label='Edit' onClick={openEditModal} />
                        {categoryData.supportArticleCategory?.folders?.length === 0 && <ActionItem label='Delete' onClick={openDeleteModal} />}
                      </ActionMenu>
                    )}
                  </ModalLauncher>
                )}
              </ModalLauncher>
            </HasAgentPermission>}
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {isEditingOrder ?
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='kb-folders'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} >
                  <View style={{ gap: '16px', marginTop: '16px' }}>
                    {folderOrder.map((folder, index) => {
                      return (
                        <Draggable draggableId={folder.id} index={index} key={folder.id}>
                          {(provided) => (
                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                              <Card>
                                <View style={{ flexDirection: 'row', gap: '16px' }}>
                                  <Icon icon={Icons.Grip} size='medium' />
                                  <StyledHeading tag='h6'>{folder.name}</StyledHeading>
                                </View>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}

                    {provided.placeholder}
                  </View>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          :
          <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 1000: 2, 1500: 3 }}>
            <Masonry gutter='16px'>
              {folderOrder.map((folder) => (
                <Link to={`/agent/kb/folders/${folder.id}`} key={folder.id} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <Card size='medium' style={{ ':hover': { backgroundColor: Colors.neutral50 }, ':active': { backgroundColor: Colors.neutral100 } }}>
                    <View style={{ gap: '4px' }}>
                      <StyledHeading tag='h6' style={{ color: Colors.primary900 }}>{folder.name}</StyledHeading>
                      {folder.description && <StyledParagraph style={{ color: Colors.neutral700 }}>{folder.description}</StyledParagraph>}
                    </View>
                  </Card>
                </Link>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        }
      </Cell>
    </StandardGrid>
  );
}

type CategoryPermissionsModalProps = {
  id: string;
  handleClose?: (data?: any) => void;
}

function CategoryPermissionsModal(props: CategoryPermissionsModalProps) {
  const { addAlert } = useAlertState();
  const { loading: permissionsAreLoading } = useQuery<GetCategoryPermissionsResponse>(GET_CATEGORY_PERMISSIONS, {
    variables: {
      id: props.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.permissionsForSupportArticleCategory) {
        setPermissions(data.permissionsForSupportArticleCategory);
      }
    }
  });
  const [grantPermission, { loading: grantPermissionIsLoading }] = useMutation<GrantPermissionResponse>(GRANT_PERMISSION);
  const [editPermission, { loading: editPermissionIsLoading }] = useMutation<EditPermissionResponse>(EDIT_PERMISSION);
  const [revokePermission, { loading: revokePermissionIsLoading }] = useMutation<RevokePermissionResponse>(REVOKE_PERMISSION);
  const [searchAgents, { data: searchData, loading: searchAgentsAreLoading }] = useLazyQuery<SearchAgentsResponse>(SEARCH_AGENTS, {
    fetchPolicy: 'cache-and-network',
  });
  const [permissions, setPermissions] = useState<PermissionAssignment[]>([]);
  const [addUserId, setAddUserId] = useState<string | null>(null);
  const [addUserRole, setAddUserRole] = useState<Permission | null>(Permission.EDIT);

  const handleGrantPermission = async () => {
    if (!addUserId || !addUserRole) {
      return;
    }

    const { errors } = await grantPermission({
      variables: {
        categoryId: props.id,
        userId: addUserId,
        permission: addUserRole,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to grant permission' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Permission granted' type='success' id={id} />
      addAlert(id, alert);

      // Add the permission to the cache
      const newPermissions = [...permissions];
      newPermissions.push({
        user: {
          id: addUserId,
          firstName: searchData?.supportAgents?.find((agent) => agent.id === addUserId)?.firstName || '',
          lastName: searchData?.supportAgents?.find((agent) => agent.id === addUserId)?.lastName || '',
          email: searchData?.supportAgents?.find((agent) => agent.id === addUserId)?.email || null,
        },
        permission: addUserRole,
      });

      newPermissions.sort((a, b) => {
        const aName = `${a.user.firstName} ${a.user.lastName}`;
        const bName = `${b.user.firstName} ${b.user.lastName}`;

        return aName.localeCompare(bName);
      });

      setPermissions(newPermissions);

      // Reset the add user fields
      setAddUserId(null);
      setAddUserRole(Permission.EDIT);
    }
  }

  const handlePermissionChange = (name: string, value: string | null) => {
    if (value === null) {
      // Remove permission
      const newPermissions = [...permissions];
      const index = newPermissions.findIndex((p) => p.user.id === name);
      newPermissions.splice(index, 1);
      setPermissions(newPermissions);

      // Make API call to revoke permission
      revokePermission({
        variables: {
          categoryId: props.id,
          userId: name,
        },
      }).then(({ errors }) => {
        if (errors) {
          const id = generateId();
          const alert = <StandardAlert title='Failed to remove permission' description={errors[0].message} type='error' id={id} />
          addAlert(id, alert);
        }
      });

      return;
    }

    // Update the cached permission
    const newPermissions = [...permissions];
    const index = newPermissions.findIndex((p) => p.user.id === name);
    newPermissions[index].permission = parsePermissionStringValue(value);
    setPermissions(newPermissions);

    // Make API call to update permission
    editPermission({
      variables: {
        categoryId: props.id,
        userId: name,
        permission: value,
      },
    }).then(({ errors }) => {
      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Failed to update permissions' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      }
    });
  }

  const handleSearch = (name: string, value: string) => {
    setAddUserId(null);

    if (value.length < 3) {
      return;
    }

    if (value.includes('@')) {
      searchAgents({
        variables: {
          email: value,
          name: null,
        },
        fetchPolicy: 'cache-and-network',
      });
    } else {
      searchAgents({
        variables: {
          email: null,
          name: value,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }

  const foundUsers = searchData?.supportAgents?.filter((agent) => {
    // Filter out any agents that already have access
    return !permissions.some((p) => p.user.id === agent.id);
  });

  const header = <ModalHeader title='Manage Permissions' />;

  const body = (
    <ModalBody>
      {permissionsAreLoading ?
        <View style={{ alignItems: 'center' }}>
          <CircularSpinner size='medium' />
        </View>
        :
        <View style={{ gap: '48px', minHeight: 'fit-content', overflow: 'scroll' }}>
          <View style={{ gap: '16px', minHeight: 'fit-content' }}>
            <StyledHeading tag='h6'>Grant access</StyledHeading>
            <View style={{ flexDirection: 'row', gap: '16px', minHeight: 'fit-content' }}>
              <SingleSelect name='addUserId' value={addUserId} placeholder='Select a user' filterPlaceholder='Search by name or email' filterable autoFocusSearch onFilter={handleSearch} onChange={(_: string, value: string | null) => { setAddUserId(value); }}>
                {searchAgentsAreLoading ?
                  <View style={{ flexDirection: 'row', justifyContent: 'center' }} key='agent-search-loading'>
                    <CircularSpinner size='small' />
                  </View>
                  :
                  <View>
                    {foundUsers?.length === 0 ?
                      <StyledParagraph style={{ color: Colors.neutral700, margin: '16px', textAlign: 'center' }}>No agents found.</StyledParagraph>
                      :
                      foundUsers?.map((agent) => (
                        <Choice label={`${agent.firstName} ${agent.lastName}`} description={agent.email || undefined} value={agent.id} key={agent.id} />
                      ))
                    }
                  </View>
                }
              </SingleSelect>
              <SingleSelect name='addUserRole' value={addUserRole} style={{ flexGrow: 0, width: '150px' }} onChange={(_: string, value: string | null) => { setAddUserRole(parsePermissionStringValue(value)); }}>
                <Choice label='Editor' value={Permission.EDIT} />
                <Choice label='Publisher' value={Permission.PUBLISH} />
              </SingleSelect>
            </View>
            <Button label='Grant Access' variant='primary' role='button' action={handleGrantPermission} loading={grantPermissionIsLoading} disabled={addUserId === null} />
          </View>
          <View style={{ minHeight: 'fit-content' }}>
            <InfoPanel type='warning'>
              <View style={{ gap: '16px' }}>
                <StyledParagraph><span style={{ fontWeight: 600 }}>Grant these permissions with caution.</span> For more information on Knowledge Base permissions, visit <StyledLink href={KNOWLEDGE_BASE_PERMISSIONS_INFO_LINK}>this article</StyledLink>.</StyledParagraph>
              </View>
            </InfoPanel>
          </View>
          <View style={{ minHeight: 'fit-content' }}>
            <StyledHeading tag='h6'>People with access</StyledHeading>
            <View style={{ gap: '16px', marginTop: '16px' }}>
              {permissions.length === 0 && <StyledParagraph style={{ color: Colors.neutral700 }}>There are no permissions granted for this category.</StyledParagraph>}
              {permissions.map((p) => (
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                  <View>
                    <StyledParagraph bold>{p.user.firstName} {p.user.lastName}</StyledParagraph>
                    {p.user.email && <StyledParagraph style={{ color: Colors.neutral700 }}>{p.user.email}</StyledParagraph>}
                  </View>

                  <SingleSelect name={p.user.id} value={p.permission.toString()} style={{ flexGrow: 0, width: '200px' }} onChange={handlePermissionChange}>
                    <Choice label='Editor' value='EDIT' />
                    <Choice label='Publisher' value='PUBLISH' />
                    <Choice label='Remove' value={null} style={{ borderTop: `1px solid ${Colors.neutral300}`, marginTop: '4px' }} />
                  </SingleSelect>
                </View>
              ))}
            </View>
          </View>
        </View>
      }
    </ModalBody>
  );

  const footer = (
    <ModalFooter>
      <View style={{ alignItems: 'center', flexDirection: 'row', height: '40px', justifyContent: 'space-between', width: '100%' }}>
        {(editPermissionIsLoading || revokePermissionIsLoading) ?
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
            <StyledParagraph style={{ color: Colors.neutral700 }}>Saving changes...</StyledParagraph>
            <CircularSpinner size='xsmall' />
          </View>
          :
          <StyledParagraph style={{ color: Colors.neutral700 }}>All changes saved</StyledParagraph>
        }

        <Button label='Done' variant='primary' role='button' action={props.handleClose ? props.handleClose : () => { }} />
      </View>
    </ModalFooter>
  );

  return (
    <Modal onClose={props.handleClose} header={header} body={body} footer={footer} />
  );
}

function parsePermissionStringValue(value: string | null): Permission {
  switch (value) {
    case 'EDIT':
      return Permission.EDIT;
    case 'PUBLISH':
      return Permission.PUBLISH;
    default:
      return Permission.EDIT;
  }
}