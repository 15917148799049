import { Dispatch, createContext, useEffect, useState } from "react";
import { AgentAuthState, AuthAction, agentAuthStateReducer, initialState, login, logout } from "./agentAuthStateReducer";
import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { StyleSheet, css } from "aphrodite";
import { AtomSpinner } from "@barscience/global-components";

type AgentAuthStateContextProps = {
  state: AgentAuthState;
  dispatch: Dispatch<AuthAction>;
}

type AgentAuthStateProviderProps = {
  children: React.ReactNode;
}

export const AgentAuthStateContext = createContext<AgentAuthStateContextProps>({} as AgentAuthStateContextProps);

const GET_AGENT_QUERY = gql`
query getSupportAgentOrUser {
  currentUser {
    id
    isSupportAgent
    supportAgentRole {
      permissions {
        canChangeTicketAssignee
        canEditTicketProperties
        canEditTicketSkills
        canMergeTickets
        canDeleteTickets
        canCreatePublicReplies
        canEditEndUserAccess
        canCreateTickets
        canManageAgents
        canManageAgentRoles
        canManageAgentGroups
        canManageAgentStatuses
        canManageViews
        canManageMacros
        canManageCannedResponses
        canManageTicketFormsAndFields
        canManageRouting
        canManageSkills
        canEditAgentSkills
        canManageSLAs
        canModerateCommunityContent
        canManagePostTopics
        canManageAllArticles
        canManageCommunityBadges
        canManagePostStatuses
        canSetPostStatuses
      }
    }
  }
}
`;

type GetAgentResponse = {
  currentUser: {
    id: string;
    isSupportAgent: boolean;
    supportAgentRole: {
      permissions: SupportAgentPermissions;
    } | null;
  } | null;
}

export type SupportAgentPermissions =  {
  canChangeTicketAssignee: boolean;
  canEditTicketProperties: boolean;
  canEditTicketSkills: boolean;
  canMergeTickets: boolean;
  canDeleteTickets: boolean;
  canCreatePublicReplies: boolean;
  canEditEndUserAccess: boolean;
  canCreateTickets: boolean;
  canManageAgents: boolean;
  canManageAgentRoles: boolean;
  canManageAgentGroups: boolean;
  canManageAgentStatuses: boolean;
  canManageViews: boolean;
  canManageMacros: boolean;
  canManageCannedResponses: boolean;
  canManageTicketFormsAndFields: boolean;
  canManageRouting: boolean;
  canManageSkills: boolean;
  canEditAgentSkills: boolean;
  canManageSLAs: boolean;
  canModerateCommunityContent: boolean;
  canManagePostTopics: boolean;
  canManageAllArticles: boolean;
  canManageCommunityBadges: boolean;
  canManagePostStatuses: boolean;
  canSetPostStatuses: boolean;
}

/**
 * AgentAuthStateProvider wraps an entire application and is used to provide information about the current user and their permissions.
 * 
 * You can then use the `useAgentAuthState` hook from any child component to access the global user object.
 * 
 * ### Usage
 * ```jsx
 * <AgentAuthStateProvider>
 *  Application components go here
 * </AgentAuthStateProvider>
 * ```
 */
export const AgentAuthStateProvider = ({ children }: AgentAuthStateProviderProps) => {
  const [state, dispatch] = React.useReducer(agentAuthStateReducer, initialState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [getUser] = useLazyQuery<GetAgentResponse>(GET_AGENT_QUERY);


  useEffect(() => {
    getUser().then(({ data }) => {
      if (data?.currentUser) {
        dispatch(login({
          id: data.currentUser.id,
          isSupportAgent: data.currentUser.isSupportAgent,
          supportAgentPermissions: data.currentUser.supportAgentRole?.permissions || null,
        }));
      } else {
        dispatch(logout());
      }

      setIsLoading(false);
    });
  }, [getUser]);

  const styles = StyleSheet.create({
    loadingContainer: {
      alignContent: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
  });

  if (isLoading) {
    return (
      <div className={css(styles.loadingContainer)}>
        <AtomSpinner size='large' />
      </div>
    );
  }

  return (
    <AgentAuthStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AgentAuthStateContext.Provider>
  )
}