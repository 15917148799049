import { AtomSpinner, Button, Card, Cell, Checkbox, Colors, ErrorPage, FormModal, Icon, Icons, Link, ModalLauncher, NoPermission, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import { gql, useMutation, useQuery } from "@apollo/client";
import HasAgentPermission from "../../components/auth/HasAgentPermission";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

export const KNOWLEDGE_BASE_PERMISSIONS_INFO_LINK = 'https://barscience.atlassian.net/wiki/spaces/Support/pages/108724226/Understanding+Knowledge+Base+Permissions';

/* Get Categories Query */
export const GET_CATEGORIES = gql`
query getSupportArticleCategoriesForAdmins {
  supportArticleCategories {
    id
    name
    description
    icon
    isHiddenFromHomepage
  }
}
`;

export type GetCategoriesResponse = {
  supportArticleCategories: Category[] | null;
}

type Category = {
  id: string;
  name: string;
  description: string | null;
  icon: string | null;
}

/* Create Category Mutation */
const CREATE_CATEGORY = gql`
mutation createSupportArticleCategory($input: CreateSupportArticleCategoryInput!) {
  createSupportArticleCategory(input: $input) {
    id
    name
    description
    icon
    isHiddenFromHomepage
  }
}
`;

type CreateCategoryResponse = {
  createSupportArticleCategory: Category | null;
}

type CreateCategoryInput = {
  name: string;
  description: string;
  icon: string;
  hideFromHomepage: boolean;
}

/* Edit Category Order Mutation */
const EDIT_CATEGORY_ORDER = gql`
mutation editSupportArticleCategoryOrder($order: [ID!]!) {
  success: editSupportArticleCategoryOrder(order: $order)
}
`;

type EditCategoryOrderResponse = {
  success: boolean;
}

export default function KBAdminHome() {
  const { state: agentState } = useAgentAuthState();
  const { addAlert } = useAlertState();
  const [isEditingOrder, setIsEditingOrder] = useState<boolean>(false);
  const { loading: categoriesAreLoading, error: categoriesError } = useQuery<GetCategoriesResponse>(GET_CATEGORIES, {
    onCompleted: (data) => {
      setCategoryOrder(data.supportArticleCategories || []);
    }
  });
  const [createCategory] = useMutation<CreateCategoryResponse>(CREATE_CATEGORY, {
    update(cache, { data }) {
      if (data?.createSupportArticleCategory) {
        cache.modify({
          fields: {
            supportArticleCategories(existingCategories = []) {
              const newCategoryRef = cache.writeFragment({
                data: data.createSupportArticleCategory,
                fragment: gql`
                  fragment NewCategory on SupportArticleCategory {
                    id
                    name
                    description
                    icon
                  }
                `
              });
              return [...existingCategories, newCategoryRef];
            }
          }
        });
      }
    },
    onCompleted: (data) => {
      if (data.createSupportArticleCategory) {
        const categories = [...categoryOrder];
        categories.push(data.createSupportArticleCategory);
        setCategoryOrder(categories);
      }
    }
  });
  const [editCategoryOrder, { loading: editCategoryOrderIsLoading }] = useMutation<EditCategoryOrderResponse>(EDIT_CATEGORY_ORDER);
  const [categoryOrder, setCategoryOrder] = useState<Category[]>([]);

  const handleCreateCategory = async (values: CreateCategoryInput) => {
    const { errors } = await createCategory({
      variables: {
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          icon: values.icon,
          isHiddenFromHomepage: values.hideFromHomepage,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating category' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const createCategoryModal = (
    <FormModal<CreateCategoryInput> title='Create Category' onSubmit={handleCreateCategory} submitLabel='Create' initialValues={{ name: '', description: '', icon: '', hideFromHomepage: false }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' required />
        <TextField label='Description' name='description' />
        <TextField label='Icon' name='icon' description='The FontAwesome ID of the icon' required />
        <Checkbox label='Hide from Help Center home page' name='hideFromHomepage' />
      </View>
    </FormModal>
  );

  const handleDragEnd = async (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const newCategoryOrder = Array.from(categoryOrder);
    newCategoryOrder.splice(source.index, 1);
    newCategoryOrder.splice(destination.index, 0, categoryOrder[source.index]);

    setCategoryOrder(newCategoryOrder);
  }

  const handleSaveOrder = async () => {
    const { errors } = await editCategoryOrder({
      variables: {
        order: categoryOrder.map((category) => category.id),
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error saving category order' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Category order saved' type='success' id={id} />
      addAlert(id, alert);
    }

    setIsEditingOrder(false);
  }

  if (!agentState.user?.isSupportAgent) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (categoriesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Knowledge Base</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ margin: '16px 0' }}>
        <View style={{ flexDirection: 'row', gap: '16px' }}>
          <Card style={{ width: 'fit-content' }}>
            <View style={{ gap: '8px' }}>
              <StyledHeading tag='h6'>Publish Queue</StyledHeading>
              <StyledParagraph>View all articles that have drafts that are ready to publish.</StyledParagraph>
              <Link href='/agent/kb/publish-queue'>Go to publish queue</Link>
            </View>
          </Card>
          <Card style={{ width: 'fit-content' }}>
            <View style={{ gap: '8px' }}>
              <StyledHeading tag='h6'>Help Center</StyledHeading>
              <StyledParagraph>View the help center as an end user.</StyledParagraph>
              <Link href='/help-center'>Go to Help Center</Link>
            </View>
          </Card>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', justifyContent: 'space-between' }}>
          <StyledHeading tag='h4'>Categories</StyledHeading>
          <View style={{ flexDirection: 'row', gap: '32px' }}>
            <HasAgentPermission permissions={['canManageAllArticles']}>
              {!isEditingOrder &&
                <ModalLauncher modal={createCategoryModal}>
                  {({ openModal }) => (
                    <Button label='Create Category' leftIcon={Icons.Plus} iconSize='small' variant='secondary' role='button' action={openModal} />
                  )}
                </ModalLauncher>
              }

              {isEditingOrder ?
                <Button label='Save order' variant='tertiary' role='button' loading={editCategoryOrderIsLoading} action={handleSaveOrder} />
                :
                <Button label='Reorder' leftIcon={Icons.Reorder} variant='tertiary' role='button' action={() => { setIsEditingOrder(true); }} />
              }
            </HasAgentPermission>
          </View>
        </View>
        {categoriesAreLoading ?
          <AtomSpinner size='medium' />
          :
          (isEditingOrder ?
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='kb-categories'>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} >
                    <View style={{ gap: '16px', marginTop: '16px' }}>
                      {categoryOrder.map((category, index) => {
                        return (
                          <Draggable draggableId={category.id} index={index} key={category.id}>
                            {(provided) => (
                              <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                <Card>
                                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                                    <Icon icon={Icons.Grip} size='medium' />
                                    <StyledHeading tag='h6'>{category.name}</StyledHeading>
                                  </View>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}

                      {provided.placeholder}
                    </View>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            :
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '48px 24px', marginTop: '24px' }}>
              {categoryOrder.map((category) => (
                <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, gap: '16px', maxWidth: '350px', minWidth: 'min(100%, 350px)' }} key={category.id}>
                  <Link href={`/agent/kb/categories/${category.id}`} linkStyle={{ alignItems: 'center', color: 'inherit', display: 'flex', flexDirection: 'row', gap: '16px', width: 'fit-content', ':active': { color: 'inherit' } }}>
                    {category.icon &&
                      <Card style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Icon size='large' icon={Icons.Folder} customFaIcon={category.icon} style={{ color: Colors.neutral900 }} />
                      </Card>
                    }
                    <View style={{ gap: '4px' }}>
                      <StyledHeading tag='h6' style={{ color: Colors.neutral900 }}>{category.name}</StyledHeading>
                      {category.description &&
                        <StyledParagraph style={{ color: Colors.neutral700 }}>{category.description}</StyledParagraph>
                      }
                    </View>
                  </Link>
                </View>
              ))}
            </View>
          )
        }
      </Cell>
    </StandardGrid>
  );
}