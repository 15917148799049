import { SupportAgentPermissions } from "./AgentAuthStateProvider";
import useAgentAuthState from "./useAgentAuthState";

type PermissionName = keyof SupportAgentPermissions;

export type HasAgentPermissionProps = {
  children: React.ReactNode;
  permissions: PermissionName[];
  requireAll?: boolean;
}

/**
 * HasAgentPermission renders its children only if the user is a Bar Science support agent and has the required permission(s).
 * 
 * ### Usage
 * 
 * ```jsx
 * <HasAgentPermission permissions={["canEditTicketProperties", "canChangeTicketAssignee"] requireAll}>
 *   <p>Child components go here. They will only be displayed to support agents with all of the required permissions.</p>
 * </HasAgentPermission>
 * ```
 */
export default function HasAgentPermission(props: HasAgentPermissionProps) {
  const { state } = useAgentAuthState();

  if (!state.user || !state.user.isSupportAgent || !state.user.supportAgentPermissions) {
    return null;
  }

  // Require the user to have all specified permissions
  if (props.requireAll) {
    let hasPermission = true;

    props.permissions.forEach((p) => {
      if (!state.user?.supportAgentPermissions || !state.user?.supportAgentPermissions[p]) {
        // The user is missing one of the required permissions
        hasPermission = false;
      }
    });

    if (hasPermission) {
      return (
        <>
          {props.children}
        </>
      );
    } else {
      return null;
    }
  } else {
    let hasPermission = false;

    props.permissions.forEach((p) => {
      if (state.user?.supportAgentPermissions && state.user?.supportAgentPermissions[p]) {
        hasPermission = true;
      }
    });

    if (hasPermission) {
      return (
        <>
          {props.children}
        </>
      );
    } else {
      return null;
    }
  }
}