import { gql, useMutation } from "@apollo/client";
import { FormModal, Radio, RadioGroup, StandardAlert, StyledParagraph, View, generateId, useAlertState } from "@barscience/global-components";

const FLAG_POST_MUTATION = gql`
mutation flagCommunityPost($id: ID!, $category: SupportPostFlagCategory!) {
  flagCommunityPost(id: $id, category: $category) {
    id
    isFlaggedByUser
  }
}
`;

const FLAG_COMMENT = gql`
mutation flagCommunityPostComment($id: ID!, $category: SupportPostFlagCategory!) {
  flagCommunityPostComment(id: $id, category: $category) {
    id
    isFlaggedByUser
  }
}
`;

type FlagPostModalProps = {
  postID: string;
  postType: 'post' | 'comment';
  handleClose?: () => void;
}

type FlagPostInput = {
  category: string;
}

export default function FlagPostModal(props: FlagPostModalProps) {
  const { addAlert } = useAlertState();
  const [flagPost] = useMutation(FLAG_POST_MUTATION);
  const [flagComment] = useMutation(FLAG_COMMENT);

  const handleFlagPost = async (values: FlagPostInput) => {
    if (props.postType === 'post') {
      const { errors } = await flagPost({ variables: { id: props.postID, category: values.category } });

      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Error flagging post' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      }
    } else {
      const { errors } = await flagComment({ variables: { id: props.postID, category: values.category } });

      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Error flagging post' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      }
    }
  }

  return (
    <FormModal<FlagPostInput> title='Flag post' submitLabel='Flag' onSubmit={handleFlagPost} initialValues={{ category: '' }} handleClose={props.handleClose}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This post will be reported to the moderation team for review.</StyledParagraph>

        <RadioGroup label='How does this post violate our guidelines?' name='category' required>
          <Radio label='Offensive' description='Ths post is offensive, inappropriate, or disrespectful' value='INAPPROPRIATE' />
          <Radio label='Off-topic' description='The post is low quality, a duplicate, or spam' value='LOW_QUALITY' />
          <Radio label='Incorrect topic' description='The post is located in the wrong topic' value='WRONG_CATEGORY' />
          <Radio label='Sensitive information' description='The post shares sensitive personal or company information' value='SENSITIVE_INFORMATION' />
        </RadioGroup>
      </View>
    </FormModal>
  );
}