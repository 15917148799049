import { ToolbarButtonConfig } from "@barscience/global-components/dist/typings/TextEditor/Toolbar/Toolbar"

export const COMMUNITY_POST_EDITOR: ToolbarButtonConfig = {
  undo: true,
  redo: true,
  bold: true,
  italic: true,
  underline: true,
  strikethrough: false,
  unorderedList: true,
  orderedList: true,
  quote: true,
  code: false,
  subscript: false,
  superscript: false,
  clearFormat: true,
  alignment: false,
  headings: true,
  link: true,
  youtube: false,
  infoPanel: false,
  table: false,
  image: true,
}

export const ARTICLE_EDITOR: ToolbarButtonConfig = {
  undo: true,
  redo: true,
  bold: true,
  italic: true,
  underline: true,
  strikethrough: true,
  unorderedList: true,
  orderedList: true,
  quote: true,
  code: true,
  subscript: true,
  superscript: true,
  clearFormat: true,
  alignment: true,
  headings: true,
  link: true,
  youtube: true,
  infoPanel: true,
  table: true,
  image: true,
}