import { gql, useQuery } from "@apollo/client";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Choice, ErrorPage, Link, MultiSelect, NoPermission, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View } from "@barscience/global-components";
import { useState } from "react";
import { GET_CATEGORIES, GetCategoriesResponse } from "./KBAdminHome";

/* Get Articles Query */
const GET_ARTICLES = gql`
query getPublishableSupportArticles($categories: [ID!]) {
  publishableSupportArticles(categories: $categories) {
    id
    title
    folder {
      id
      name
      category {
        id
        name
      }
    }
  }
}
`;

type GetArticlesResponse = {
  publishableSupportArticles: Article[] | null;
}

type Article = {
  id: string;
  title: string;
  folder: {
    id: string;
    name: string;
    category: {
      id: string;
      name: string;
    }
  }
}

export default function KBAdminPublishQueue() {
  const { state: agentState } = useAgentAuthState();
  const [selectedCategories, setSelectedCategories] = useState<{ [categoryId: string]: any }>({});
  const { data, loading, error } = useQuery<GetArticlesResponse>(GET_ARTICLES, {
    variables: {
      categories: selectedCategories ? Object.keys(selectedCategories).filter((categoryId) => selectedCategories[categoryId]) : null,
    },
  });
  const { data: categoryData, loading: categoriesAreLoading } = useQuery<GetCategoriesResponse>(GET_CATEGORIES, {
    onCompleted: (data) => {
      const categories: { [categoryId: string]: any } = {};
      data?.supportArticleCategories?.forEach((category) => {
        categories[category.id] = false;
      });
      setSelectedCategories(categories);
    },
  });

  if (!agentState.user?.isSupportAgent) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (error) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  if (categoriesAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Knowledge Base' to='/agent/kb' />
          <Breadcrumb label='Publish Queue' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', gap: '32px', flexWrap: 'wrap' }}>
          <StyledHeading tag='h4'>Publish Queue</StyledHeading>
          <MultiSelect placeholder='Filter by category' name='categoryFilter' value={selectedCategories} onChange={(_, value) => { setSelectedCategories(value); }} style={{ maxWidth: '300px', width: '300px' }}>
            {categoryData?.supportArticleCategories?.map((category) => (
              <Choice label={category.name} value={category.id} key={category.id} />
            ))}
          </MultiSelect>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {loading ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <Card>
            {(data?.publishableSupportArticles?.length || 0) > 0 ?
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Article</TableHeaderCell>
                    <TableHeaderCell>Category</TableHeaderCell>
                    <TableHeaderCell>Folder</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data?.publishableSupportArticles?.map(article => (
                    <TableRow key={article.id}>
                      <TableCell><Link href={`/agent/kb/articles/${article.id}?referrer=publishQueue`}>{article.title}</Link></TableCell>
                      <TableCell>{article.folder.category.name}</TableCell>
                      <TableCell>{article.folder.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              :
              <StyledParagraph>There are no articles ready to publish.</StyledParagraph>
            }
          </Card>
        }
      </Cell>
    </StandardGrid>
  );
}