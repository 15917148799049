import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Choice, CircularSpinner, Colors, ErrorPage, FormModal, generateId, Grid, Icon, Icons, Link, ModalLauncher, OptionBar, OptionItem, PageButtons, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextArea, TextField, useAlertState, useAuthState, View } from "@barscience/global-components";
import { useParams } from "react-router-dom";
import HelpCenterHeader from "../helpCenter/HelpCenterHeader";
import { css, CSSProperties, StyleSheet } from "aphrodite";
import { useEffect, useState } from "react";
import ReactTimeago from "react-timeago";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import { GET_CUSTOM_BADGES, GET_NAME_BADGES, GetCustomBadgesResponse, GetNameBadgesResponse } from "../settings/badges/Badges";
import HasAgentPermission from "../../components/auth/HasAgentPermission";

/* Get Profile Query */
const GET_USER_PROFILE = gql`
query getSupportUserProfile($id: ID!) {
  supportUser(id: $id) {
    id
    nickname
    bio
    postCount
    commentCount
    badges {
      id
      title
      description
      imageUrl
      type
    }
    nameBadge {
      id
    }
  }
}
`;

type GetUserProfileResponse = {
  supportUser: User | null;
}

type User = {
  id: string;
  nickname: string;
  bio?: string;
  postCount: number;
  commentCount: number;
  badges: Badge[];
  nameBadge?: {
    id: string;
  }
}

type Badge = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  type: string;
}

/* Get Posts Query */
const GET_USER_POSTS = gql`
query getSupportUserPosts($id: ID!, $page: Int!) {
  supportUser(id: $id) {
    id
    posts(page: $page) {
      id
      title
      topic {
        id
        name
      }
      created
      textPreview(characterLimit: 200)
    }
  }
}
`;

type GetUserPostsResponse = {
  supportUser: {
    id: string;
    posts: Post[];
  }
}

type Post = {
  id: string;
  title: string;
  topic: {
    id: string;
    name: string;
  };
  created: string;
  textPreview: string;
}

/* Get Comments Query */
const GET_USER_COMMENTS = gql`
query getSupportUserComments($id: ID!, $page: Int!) {
  supportUser(id: $id) {
    id
    comments(page: $page) {
      id
      textPreview
      created
      post {
        id
        title
        topic {
          id
          name
        }
      }
    }
  }
}
`;

type GetUserCommentsResponse = {
  supportUser: {
    id: string;
    comments: Comment[];
  }
}

type Comment = {
  id: string;
  textPreview: string;
  created: string;
  post: {
    id: string;
    title: string;
    topic: {
      id: string;
      name: string;
    }
  }
}

/* Edit Profile Mutation */
const EDIT_PROFILE = gql`
mutation editSupportUserProfile($id: ID!, $input: EditSupportUserProfileInput!) {
  editSupportUserProfile(id: $id, input: $input) {
    id
    nickname
    bio
  }
}
`;

type EditProfileInput = {
  nickname: string;
  bio: string;
  nameBadgeId: string
}

/* Assign Custom Badge Mutation */
const ASSIGN_BADGE = gql`
mutation assignCustomBadgeToUser($userId: ID!, $badgeId: ID!) {
  assignBadge(userId: $userId, badgeId: $badgeId) {
    id
    badges {
      id
      title
      description
      imageUrl
      type
    }
  }
}
`;

type AssignCustomBadgeInput = {
  badgeId: string;
}

/* Assign Name Badge Mutation */
const ASSIGN_NAME_BADGE = gql`
mutation assignNameBadgeToUser($userId: ID!, $badgeId: ID) {
  assignNameBadge(userId: $userId, badgeId: $badgeId) {
    id
    nameBadge {
      id
      title
      hexColor
    }
  }
}
`;

/* Page Styles */
const gridStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  boxSizing: 'border-box',
  columnGap: '32px',
  flexGrow: 1,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    columnGap: '16px',
  },
  '@media (max-width: 767px)': {
    columnGap: '8px',
  },
};

const styles = StyleSheet.create({
  badgeImage: {
    height: '120px',
    maxHeight: '120px',
    minHeight: '120px',
    maxWidth: '120px',
    minWidth: '120px',
    width: '120px',
  }
});

const POSTS_PER_PAGE = 10;

export default function UserProfile() {
  const { userId } = useParams();
  const { addAlert } = useAlertState();
  const { state } = useAuthState();
  const { state: agentState } = useAgentAuthState();
  const [pageType, setPageType] = useState<string>('POSTS');
  const [page, setPage] = useState<number>(0);
  const { data: userData, loading: userIsLoading, error: userError } = useQuery<GetUserProfileResponse>(GET_USER_PROFILE, {
    variables: {
      id: userId,
    }
  });
  const [getUserPosts, { data: postData, loading: postsAreLoading, error: postsError }] = useLazyQuery<GetUserPostsResponse>(GET_USER_POSTS, {
    variables: {
      id: userId,
      page: 0,
    }
  });
  const [getUserComments, { data: commentData, loading: commentsAreLoading, error: commentError }] = useLazyQuery<GetUserCommentsResponse>(GET_USER_COMMENTS, {
    variables: {
      id: userId,
      page: 0,
    }
  });
  const [getAllCustomBadges, { data: customBadgeData, loading: customBadgesAreLoading, error: customBadgesError }] = useLazyQuery<GetCustomBadgesResponse>(GET_CUSTOM_BADGES, {
    fetchPolicy: 'cache-first',
  });
  const [getAllNameBadges, { data: nameBadgeData, loading: nameBadgesAreLoading, error: nameBadgesError }] = useLazyQuery<GetNameBadgesResponse>(GET_NAME_BADGES, {
    fetchPolicy: 'cache-first',
  });
  const [editProfile] = useMutation(EDIT_PROFILE);
  const [assignBadge] = useMutation(ASSIGN_BADGE);
  const [assignNameBadge] = useMutation(ASSIGN_NAME_BADGE);

  useEffect(() => {
    if (pageType === 'POSTS') {
      getUserPosts({
        variables: {
          id: userId,
          page: page,
        }
      })
    } else if (pageType === 'COMMENTS') {
      getUserComments({
        variables: {
          id: userId,
          page: page,
        }
      })
    }
  }, [pageType, page, getUserPosts, getUserComments, userId]);

  /* Edit Profile */
  const handleEditProfile = async (values: EditProfileInput) => {
    if (values.nickname !== userData?.supportUser?.nickname || values.bio !== userData?.supportUser?.bio) {
      const { errors } = await editProfile({
        variables: {
          id: userId,
          input: {
            nickname: values.nickname ? values.nickname : null,
            bio: values.bio ? values.bio : null,
          },
        },
      });

      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Error updating profile' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      }
    }

    if (values.nameBadgeId !== userData?.supportUser?.nameBadge?.id) {
      const { errors } = await assignNameBadge({
        variables: {
          userId: userId,
          badgeId: values.nameBadgeId === 'NONE' ? null : values.nameBadgeId,
        },
      });

      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Error assigning name badge' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      } else {
        const id = generateId();
        const alert = <StandardAlert title='Name badge updated' type='success' id={id} />
        addAlert(id, alert);
      }
    }
  }

  const editProfileModal = (
    <FormModal<EditProfileInput> title='Edit Profile' onSubmit={handleEditProfile} initialValues={{ nickname: userData?.supportUser?.nickname || '', bio: userData?.supportUser?.bio || '', nameBadgeId: userData?.supportUser?.nameBadge?.id || 'NONE' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Nickname' name='nickname' />
        <TextArea label='Bio' name='bio' />

        <HasAgentPermission permissions={['canManageCommunityBadges', 'canManageAgents']} requireAll>
          <SingleSelect label='Name Badge' name='nameBadgeId' required>
            {nameBadgesAreLoading ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='small' />
              </View>
              :
              <>
                <Choice label='None' value='NONE' />

                {nameBadgeData?.nameBadges.map((nameBadge) => {
                  return (
                    <Choice key={nameBadge.id} label={nameBadge.title} value={nameBadge.id} />
                  );
                })}
              </>
            }
          </SingleSelect>
        </HasAgentPermission>
      </View>
    </FormModal>
  );

  /* Assign Custom Badge */
  const handleAssignCustomBadge = async (values: AssignCustomBadgeInput) => {
    const { errors } = await assignBadge({
      variables: {
        userId: userId,
        badgeId: values.badgeId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error assigning badge' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const assignCustomBadgeModal = (
    <FormModal<AssignCustomBadgeInput> title='Assign Badge' onSubmit={handleAssignCustomBadge} initialValues={{ badgeId: '' }} submitLabel='Assign Badge'>
      <View style={{ gap: '16px' }}>
        {customBadgesAreLoading ?
          <View style={{ alignItems: 'center' }}>
            <CircularSpinner size='medium' />
          </View>
          :
          <SingleSelect label='Select a badge' name='badgeId' autoFocusSearch filterable required>
            {customBadgeData?.supportCommunityCustomBadges.filter((customBadge) => { return !userData?.supportUser?.badges.some(userBadge => userBadge.id === customBadge.id) }).map((badge) => {
              return (
                <Choice key={badge.id} label={badge.title} value={badge.id} />
              );
            })}
          </SingleSelect>
        }
      </View>
    </FormModal>
  );

  if (userIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <View style={{ alignItems: 'center' }}>
            <AtomSpinner size='large' />
          </View>
        </Cell>
      </StandardGrid>
    );
  }

  if (userError || postsError || commentError || customBadgesError || nameBadgesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <Grid style={gridStyle}>
      <HelpCenterHeader />

      <Cell lg={12} md={8} sm={4} style={{ padding: '32px 40px' }}>
        <View style={{ gap: '16px' }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '24px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
            <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '48px', width: '48px', borderRadius: '24px' }} />
            <StyledHeading tag='h3'>{userData?.supportUser?.nickname}</StyledHeading>
            {(state.user?.id === userId || agentState.user?.supportAgentPermissions?.canModerateCommunityContent) && <ModalLauncher modal={editProfileModal}>
              {({ openModal }) => (
                <Button label='Edit Profile' variant='tertiary' role='button' action={() => {
                  if (agentState.user?.supportAgentPermissions?.canManageCommunityBadges && agentState.user?.supportAgentPermissions?.canManageAgents) {
                    getAllNameBadges();
                  }

                  openModal();
                }} />
              )}
            </ModalLauncher>}
          </View>

          {userData?.supportUser?.bio && <StyledParagraph>{userData?.supportUser?.bio}</StyledParagraph>}
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ padding: '0px 40px' }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', '@media (max-width: 767px)': { flexDirection: 'column', gap: '24px' } }}>
          <OptionBar selectedValue={pageType} onChange={setPageType}>
            <OptionItem label={`Posts (${userData?.supportUser?.postCount.toLocaleString()})`} value='POSTS' />
            <OptionItem label={`Comments (${userData?.supportUser?.commentCount.toLocaleString()})`} value='COMMENTS' />
            <OptionItem label={`Badges (${userData?.supportUser?.badges.length.toLocaleString()})`} value='BADGES' />
          </OptionBar>

          <PageButtons currentPage={page} onPageChange={setPage} numPages={((postData && postData?.supportUser.posts.length === POSTS_PER_PAGE)) ? page + 1 : page} />
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ padding: '32px 40px' }}>
        {(function () {
          if (pageType === 'POSTS') {
            return (
              <View style={{ gap: '24px' }}>
                <StyledHeading tag='h5'>Recent Posts</StyledHeading>

                {postsAreLoading ?
                  <View style={{ alignItems: 'center' }}>
                    <CircularSpinner size='medium' />
                  </View>
                  :
                  ((postData?.supportUser.posts.length || 0) > 0 ?
                    <View style={{ gap: '16px' }}>
                      {postData?.supportUser.posts.map((post) => {
                        return (
                          <View key={post.id} style={{ borderBottom: `1px solid ${Colors.neutral300}`, paddingBottom: '16px', ':last-of-type': { borderBottom: 'none' } }}>
                            <View style={{ gap: '8px' }}>
                              <Link href={`/community/posts/${post.id}`}><StyledParagraph bold>{post.title}</StyledParagraph></Link>
                              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                                <Link href='/community' linkStyle={{ color: Colors.neutral700, fontSize: '14px' }}>Community</Link>
                                <Icon icon={Icons.ChevronRight} size='small' style={{ color: Colors.neutral700, fontSize: '14px' }} />
                                <Link href={`/community/topics/${post.topic.id}`} linkStyle={{ color: Colors.neutral700, fontSize: '14px' }}>{post.topic.name}</Link>
                              </View>

                              <StyledParagraph style={{ color: Colors.neutral700, margin: '16px 0px' }}>{post.textPreview}</StyledParagraph>

                              <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>Posted <ReactTimeago date={post.created} /></StyledParagraph>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                    :
                    <StyledParagraph>This user has not made any posts.</StyledParagraph>
                  )
                }
              </View>
            );
          } else if (pageType === 'COMMENTS') {
            return (
              <View style={{ gap: '24px' }}>
                <StyledHeading tag='h5'>Recent Comments</StyledHeading>

                {commentsAreLoading ?
                  <View style={{ alignItems: 'center' }}>
                    <CircularSpinner size='medium' />
                  </View>
                  :
                  ((commentData?.supportUser.comments.length || 0) > 0 ?
                    <View style={{ gap: '16px' }}>
                      {commentData?.supportUser.comments.map((comment) => {
                        return (
                          <View key={comment.id} style={{ borderBottom: `1px solid ${Colors.neutral300}`, paddingBottom: '16px', ':last-of-type': { borderBottom: 'none' } }}>
                            <View style={{ gap: '8px' }}>
                              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                                <Link href='/community' linkStyle={{ color: Colors.neutral700, fontSize: '14px' }}>Community</Link>
                                <Icon icon={Icons.ChevronRight} size='small' style={{ color: Colors.neutral700, fontSize: '14px' }} />
                                <Link href={`/community/topics/${comment.post.topic.id}`} linkStyle={{ color: Colors.neutral700, fontSize: '14px' }}>{comment.post.topic.name}</Link>
                                <Icon icon={Icons.ChevronRight} size='small' style={{ color: Colors.neutral700, fontSize: '14px' }} />
                                <Link href={`/community/posts/${comment.post.id}`} linkStyle={{ color: Colors.neutral700, fontSize: '14px' }}>{comment.post.title}</Link>
                              </View>

                              <StyledParagraph style={{ color: Colors.neutral700, margin: '16px 0px' }}>{comment.textPreview}</StyledParagraph>

                              <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>Posted <ReactTimeago date={comment.created} /></StyledParagraph>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                    :
                    <StyledParagraph>This user has not made any comments.</StyledParagraph>
                  )
                }
              </View>
            );
          } else if (pageType === 'BADGES') {
            return (
              <View style={{ gap: '24px' }}>
                <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <StyledHeading tag='h5'>Badges</StyledHeading>
                  <HasAgentPermission permissions={['canManageCommunityBadges']}>
                    <ModalLauncher modal={assignCustomBadgeModal}>
                      {({ openModal }) => (
                        <Button label='Assign Badge' variant='secondary' role='button' action={() => { getAllCustomBadges(); openModal(); }} />
                      )}
                    </ModalLauncher>
                  </HasAgentPermission>
                </View>

                {((userData?.supportUser?.badges.length || 0) > 0 ?
                  <View style={{ gap: '16px', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                    {userData?.supportUser?.badges.map((badge) => {
                      return (
                        <Card key={badge.id} style={{ alignItems: 'center', boxSizing: 'border-box', display: 'flex', flexDirection: 'row', gap: '24px', minWidth: '400px', width: 'calc((100% - 32px) / 3)', '@media (max-width: 1611px)': { width: 'calc((100% - 16px) / 2)' }, '@media (max-width: 1195px)': { width: '100%', maxWidth: '450px' }, '@media (max-width: 527px)': { flexDirection: 'column', textAlign: 'center' } }}>
                          <img src={badge.imageUrl} alt='An award badge' className={css(styles.badgeImage)} />

                          <View style={{ gap: '4px' }}>
                            <StyledHeading tag='h6' style={{ fontSize: '16px', width: '100%' }}>{badge.title}</StyledHeading>
                            <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', width: '100%' }}>{badge.description}</StyledParagraph>
                          </View>
                        </Card>
                      );
                    })}
                  </View>
                  :
                  <StyledParagraph>This user has not earned any badges.</StyledParagraph>
                )}
              </View>
            );
          } else {
            return <></>
          }
        })()}
      </Cell>
    </Grid>
  );
}