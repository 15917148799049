import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_AGENT_ROLES, GetAgentRolesResponse } from "./AgentRoles";
import { Button, Choice, CircularSpinner, Colors, Form, Icon, Icons, InfoPanel, Modal, ModalBody, ModalFooter, ModalHeader, SingleSelect, StyledHeading, StyledParagraph, TextField, View, useForm } from "@barscience/global-components";
import { SET_ROLE, SetRoleResponse } from "./Agents";

/* Lookup Users Query */
const LOOKUP_USERS = gql`
query lookupUsersForAgentRoles($input: UserSearchInput!, $page: Int!) {
  userSearch(input: $input, page: $page) {
    id
    firstName
    lastName
    email
  }
}
`;

type LookupUsersResponse = {
  userSearch: User[] | null;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

type UserSearchInput = {
  userEmail: string;
}

/* Add Agent Mutation */
type RoleInput = {
  roleId: string;
}

/* Properties */
type AddAgentModalProps = {
  handleClose?: () => void;
}

enum Page {
  USER_SEARCH,
  ROLE_SELECT,
  CONFIRMATION,
}

export default function AddAgentModal(props: AddAgentModalProps) {
  const [page, setPage] = useState<Page>(Page.USER_SEARCH);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [lookupUser, { loading: userIsLoading, error: userError }] = useLazyQuery<LookupUsersResponse>(LOOKUP_USERS, {
    onCompleted: (data) => {
      if (data) {
        setUserData(data);
      }
    },
  });
  const [userData, setUserData] = useState<LookupUsersResponse | null>(null);
  const { data: rolesData, loading: rolesAreLoading } = useQuery<GetAgentRolesResponse>(GET_AGENT_ROLES);
  const [setRole, { loading: setRoleIsLoading, error: setRoleError, data: setRoleData }] = useMutation<SetRoleResponse>(SET_ROLE, {
    update(cache, { data }) {
      if (data?.setSupportAgentRole) {
        cache.modify({
          fields: {
            supportAgents(existingAgents = [], { readField }) {
              const newAgentRef = cache.writeFragment({
                data: data.setSupportAgentRole,
                fragment: gql`
                  fragment NewAgent on User {
                    id
                    firstName
                    lastName
                    email
                    supportAgentRole {
                      id
                      name
                    }
                    isSupportAgent
                  }
                `
              });

              return [...existingAgents, newAgentRef].sort((a: any, b: any) => (readField('firstName', a)?.toLocaleString().localeCompare(readField('firstName', b)?.toLocaleString() || '') || 0));
            }
          },
        });
      }
    },
  });

  const handleClose = () => {
    setPage(Page.USER_SEARCH);
    setSelectedUser(null);
    setUserData(null);
  }

  const userSearchForm = useForm<UserSearchInput>({
    initialValues: {
      userEmail: '',
    },
    onSubmit: (values) => {
      setUserData(null);

      lookupUser({
        variables: {
          input: {
            query: values.userEmail,
            type: 'EMAIL',
          },
          page: 0,
        },
      });
    }
  });

  const roleForm = useForm<RoleInput>({
    initialValues: {
      roleId: '',
    },
    onSubmit: (values) => {

    }
  });

  const handleAddAgent = async (values: RoleInput) => {
    if (selectedUser && values.roleId) {
      const { data, errors } = await setRole({
        variables: {
          userId: selectedUser.id,
          roleId: values.roleId,
        },
      });

      if (!errors && data) {
        setPage(Page.CONFIRMATION);
      }
    }
  }

  if (page === Page.USER_SEARCH) {
    const body = (
      <ModalBody>
        <View style={{ gap: '32px' }}>
          <Form handleSubmit={userSearchForm.handleSubmit}>
            <View style={{ alignItems: 'flex-start', flexDirection: 'row', gap: '16px' }}>
              <TextField label={'Enter user\'s email'} name='userEmail' type='email' value={userSearchForm.values.userEmail} error={userSearchForm.errors.userEmail} onChange={userSearchForm.handleChange} onValidate={userSearchForm.handleValidate} required />
              <Button label='Search' variant='primary' role='button' type='submit' action={() => { }} style={{ marginTop: '31px' }} disabled={!userSearchForm.values.userEmail} />
            </View>
          </Form>

          {userIsLoading &&
            <View style={{ alignItems: 'center' }}>
              <CircularSpinner size='medium' />
            </View>
          }

          {(!userIsLoading && userError) &&
            <InfoPanel type='error'>
              <StyledParagraph>{userError.message}</StyledParagraph>
            </InfoPanel>
          }

          {(!userIsLoading && userData !== null && userData.userSearch?.length === 0) &&
            <StyledParagraph>No users found.</StyledParagraph>
          }

          {(!userIsLoading && userData !== null && (userData.userSearch?.length || 0) > 0) &&
            <View style={{ gap: '16px' }}>
              {userData?.userSearch?.map((user) => (
                <View key={user.id}>
                  <StyledParagraph bold>{user.firstName} {user.lastName}</StyledParagraph>
                  <StyledParagraph>{user.email}</StyledParagraph>
                  <Button label='Select User' variant='tertiary' role='button' action={() => { setSelectedUser(user); setPage(Page.ROLE_SELECT); }} />
                </View>
              ))}
            </View>
          }
        </View>
      </ModalBody>
    );

    return (
      <Modal
        header={<ModalHeader title='Add Support Agent' />}
        body={body}
        footer={<ModalFooter />}
        handleClose={props.handleClose}
        onClose={handleClose}
      />
    );
  } else if (page === Page.ROLE_SELECT) {
    const body = (
      <ModalBody>
        <View style={{ gap: '32px' }}>
          {rolesAreLoading ?
            <View style={{ alignItems: 'center' }}>
              <CircularSpinner size='medium' />
            </View>
            :
            <Form handleSubmit={roleForm.handleSubmit}>
              <View style={{ gap: '16px' }}>
                <SingleSelect label='Select a role' name='roleId' value={roleForm.values.roleId} error={roleForm.errors.roleId} onChange={roleForm.handleChange} onValidate={roleForm.handleValidate} filterable required>
                  {rolesData?.supportAgentRoles?.map((role) => (
                    <Choice label={role.name} description={role.description || undefined} value={role.id} key={role.id} />
                  ))}
                </SingleSelect>
                {setRoleError &&
                  <InfoPanel type='error'>
                    <StyledParagraph>{setRoleError.message}</StyledParagraph>
                  </InfoPanel>
                }
              </View>
            </Form>
          }
        </View>
      </ModalBody>
    );

    const footer = (
      <ModalFooter>
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'flex-end' }}>
          <Button label='Back' variant='tertiary' role='button' action={() => { setPage(Page.USER_SEARCH) }} />
          <Button label='Add Agent' variant='primary' role='button' action={() => { handleAddAgent(roleForm.values); }} disabled={roleForm.hasError} loading={setRoleIsLoading} />
        </View>
      </ModalFooter>
    );

    return (
      <Modal
        header={<ModalHeader title='Add Support Agent' />}
        body={body}
        footer={footer}
        handleClose={props.handleClose}
        onClose={handleClose}
      />
    );
  } else if (page === Page.CONFIRMATION) {
    const body = (
      <ModalBody>
        <View style={{ gap: '32px' }}>
          <View style={{ alignItems: 'center', gap: '16px' }}>
            <Icon icon={Icons.CircleCheckmark} size='large' style={{ color: Colors.primary500, height: '100px', width: '100px' }} />
            <StyledHeading tag='h6'>Support agent successfully added!</StyledHeading>
          </View>
          <StyledParagraph style={{ textAlign: 'center' }}>{selectedUser?.firstName} {selectedUser?.lastName} has been added to the {setRoleData?.setSupportAgentRole?.supportAgentRole?.name} role.</StyledParagraph>
        </View>
      </ModalBody>
    );

    const footer = (
      <ModalFooter>
        {({ closeModal }) => (
          <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'flex-end' }}>
            <Button label='Okay' variant='primary' role='button' action={closeModal} />
          </View>
        )}
      </ModalFooter>
    );

    return (
      <Modal
        header={<ModalHeader title='Add Support Agent' />}
        body={body}
        footer={footer}
        handleClose={props.handleClose}
        onClose={handleClose}
      />
    );
  } else {
    return null;
  }
}