import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Cell, Colors, ErrorPage, Grid, Link, NotFound, Row, StandardGrid, StyledHeading, StyledParagraph, TextEditor, View } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { useParams } from "react-router-dom";
import ReactTimeago from "react-timeago";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import HelpCenterHeader from "./HelpCenterHeader";

const GET_ARTICLE = gql`
query getSupportArticle($id: ID!) {
  supportArticle(id: $id) {
    id
    publishedRevision {
      title
      body
      published
    }
    folder {
      id
      name
      category {
        id
        name
      }
    }
    relatedArticles {
      id
      title
    }
    relatedPosts {
      id
      title
    }
  }
}
`;

type GetArticleResponse = {
  supportArticle: Article | null;
}

type Article = {
  id: string;
  publishedRevision: Revision;
  folder: Folder;
  relatedArticles: {
    id: string;
    title: string;
  }[] | null;
  relatedPosts: {
    id: string;
    title: string;
  }[] | null;
}

type Revision = {
  title: string;
  body: string;
  published: string;
}

type Folder = {
  id: string;
  name: string;
  category: Category;
}

type Category = {
  id: string;
  name: string;
}

const gridStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  boxSizing: 'border-box',
  columnGap: '32px',
  flexGrow: 1,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    columnGap: '16px',
  },
  '@media (max-width: 767px)': {
    columnGap: '8px',
  },
};

export default function HelpCenterArticle() {
  const { id } = useParams();
  const { state } = useAgentAuthState();
  const { data, loading, error } = useQuery<GetArticleResponse>(GET_ARTICLE, {
    variables: {
      id: id,
    },
  });

  if (loading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (error || !data?.supportArticle || !data.supportArticle.publishedRevision) {
    if (state.user?.isSupportAgent && !data?.supportArticle?.publishedRevision) {
      return (
        <View style={{ alignItems: 'center', gap: '16px', height: '100%', justifyContent: 'center', width: '100%' }}>
          <StyledParagraph style={{ color: Colors.neutral700, fontSize: '18px' }}>This article does not have a published version.</StyledParagraph>
          <Link href={`/agent/kb/articles/${data?.supportArticle?.id}`}>View in KB Admin</Link>
        </View>
      );
    }


    if (error?.graphQLErrors[0]?.extensions.status === 404) {
      return (
        <StandardGrid>
          <NotFound />
        </StandardGrid>
      );
    }

    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <Grid style={gridStyle}>
      <HelpCenterHeader />

      <Cell lg={12} md={8} sm={4} style={{ padding: '32px 40px' }}>
        <BreadcrumbGroup>
          <Breadcrumb label='Help Center' to='/help-center' />
          <Breadcrumb label={data.supportArticle.folder.category.name} to={`/help-center/categories/${data.supportArticle.folder.category.id}`} />
          <Breadcrumb label={data.supportArticle.folder.name} />
        </BreadcrumbGroup>
      </Cell>
      <Row>
        <Cell lg={9} md={6} sm={4} style={{ padding: '0 40px 80px 40px' }}>
          <View style={{ alignItems: 'center' }}>
            <View style={{ gap: '20px', maxWidth: 'min(800px, 100%)', minWidth: 'min(100%, 800px)' }}>
              <View style={{ gap: '4px' }}>
                <StyledHeading tag='h3'>{data.supportArticle.publishedRevision.title}</StyledHeading>
                <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic', fontSize: '14px' }}>Last updated <ReactTimeago date={data.supportArticle.publishedRevision.published} /></StyledParagraph>
                {state.user?.isSupportAgent && <Link href={`/agent/kb/articles/${data.supportArticle.id}`}>View in KB admin</Link>}
              </View>

              <TextEditor value={data.supportArticle.publishedRevision.body} name='article-viewer' isEditMode={false} />
            </View>
          </View>
        </Cell>
        <Cell lg={3} md={2} sm={4} style={{ paddingRight: '24px', '@media (max-width: 767px)': { padding: '0px 40px 32px 40px' } }}>
          <View style={{ gap: '64px' }}>
            {(data?.supportArticle?.relatedArticles?.length || 0) > 0 &&
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h6'>Related articles</StyledHeading>

                <View style={{ gap: '12px' }}>
                  {data?.supportArticle?.relatedArticles?.map((article) => (
                    <Link href={`/help-center/articles/${article.id}`} key={article.id}>{article.title}</Link>
                  ))}
                </View>
              </View>
            }

            {(data?.supportArticle?.relatedPosts?.length || 0) > 0 &&
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h6'>Related posts</StyledHeading>

                <View style={{ gap: '12px' }}>
                  {data?.supportArticle?.relatedPosts?.map((post) => (
                    <Link href={`/community/posts/${post.id}`} key={post.id}>{post.title}</Link>
                  ))}
                </View>
              </View>
            }
          </View>
        </Cell>
      </Row>
    </Grid>
  );
}