import { useBlocker, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import { ActionItem, ActionMenu, AtomSpinner, AutoOpen, Button, Cell, Checkbox, Choice, CircularSpinner, Colors, ConfirmModal, ErrorPage, Icon, Icons, InfoPanel, Link, MiB, Modal, ModalBody, ModalFooter, ModalHeader, ModalLauncher, NoPermission, NotFound, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TagSelector, TextEditor, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ArticleVisibility, parseArticleVisibility } from "./utils";
import { useState } from "react";
import ReactTimeago from "react-timeago";
import { ARTICLE_EDITOR } from "../../util/config/textEditorConfig";
import { SEARCH_AGENTS, SearchAgentsResponse } from "./KBAdminCategory";
import { ImagePayload } from "@barscience/global-components/dist/typings/TextEditor/nodes/ImageNode";

/* Get Article Query */
const GET_ARTICLE = gql`
query getSupportArticleDetailsForAdmins($id: ID!) {
  supportArticle(id: $id) {
    id
    folder {
      id
      name
      canPublish
      category {
        id
        name
        canPublish
      }
    }
    title
    created
    author {
      id
      firstName
      lastName
      email
    }
    canEdit
    canEditProperties
    canPublish
    visibility
    isFeatured
    publishedRevision {
      id
      title
      body
      published
      publishedBy {
        id
        firstName
        lastName
      }
    }
    draftRevision {
      id
      title
      body
      created
      isReadyToPublish
      lastUpdated
      lastUpdatedBy {
        id
        firstName
        lastName
      }
    }
    tags {
      id
      name
    }
  }
}
`;

export type GetArticleResponse = {
  supportArticle: Article | null;
}

type Article = {
  id: string;
  folder: Folder;
  title: string;
  created: string;
  author: User;
  canEdit: boolean;
  canEditProperties: boolean;
  canPublish: boolean;
  visibility: ArticleVisibility;
  isFeatured: boolean;
  publishedRevision: PublishedArticleRevision | null;
  draftRevision: DraftArticleRevision | null;
  tags: ContentTag[];
}

type Folder = {
  id: string;
  name: string;
  canPublish: boolean;
  category: {
    id: string;
    name: string;
    canPublish: boolean;
  }
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

type PublishedArticleRevision = {
  id: string;
  title: string;
  body: string;
  published: string;
  publishedBy: User;
}

type DraftArticleRevision = {
  id: string;
  title: string;
  body: string;
  created: string;
  isReadyToPublish: boolean;
  lastUpdated: string;
  lastUpdatedBy: User;
}

/* Edit Article Mutation */
const EDIT_ARTICLE = gql`
mutation editSupportArticle($id: ID!, $input: EditSupportArticleInput!) {
  editSupportArticle(id: $id, input: $input) {
    id
    draftRevision {
      id
      title
      body
      isReadyToPublish
      lastUpdated
      lastUpdatedBy {
        id
        firstName
        lastName
        email
      }
    }
    title
  }
}
`;

/* Publish Article Mutation */
const PUBLISH_ARTICLE = gql`
mutation publishSupportArticle($id: ID!) {
  publishSupportArticle(id: $id) {
    id
    draftRevision {
      id
    }
    publishedRevision {
      id
      title
      body
      published
      publishedBy {
        id
        firstName
        lastName
      }
    }
    title
  }
}
`;

/* Delete Article Mutation */
const DELETE_ARTICLE = gql`
mutation deleteSupportArticle($id: ID!) {
  success: deleteSupportArticle(id: $id)
}
`;

type DeleteArticleResponse = {
  success: boolean;
}

/* Delete Article Draft Mutation */
const DELETE_ARTICLE_DRAFT = gql`
mutation deleteSupportArticleDraft($articleId: ID!) {
  deleteSupportArticleDraft(articleId: $articleId) {
    id
    draftRevision {
      id
    }
  }
}
`;

/* Unpublish Article Mutation */
const UNPUBLISH_ARTICLE = gql`
mutation unpublishSupportArticle($id: ID!) {
  unpublishSupportArticle(id: $id) {
    id
    publishedRevision {
      id
    }
    draftRevision {
      id
    }
    title
  }
}
`;

/* Edit Article Properties Mutation */
const EDIT_ARTICLE_PROPERTIES = gql`
mutation editSupportArticleProperties($id: ID!, $input: EditSupportArticlePropertiesInput!) {
  editSupportArticleProperties(id: $id, input: $input) {
    id
    author {
      id
      firstName
      lastName
      email
    }
    folder {
      id
      name
      category {
        id
        name
        canPublish
      }
    }
    visibility
    isFeatured
  }
}
`;

type EditArticlePropertiesInput = {
  visibility: ArticleVisibility;
  folderId: string | null;
  categoryId: string;
  authorId: string;
  isFeatured: boolean;
}

/* Get Categories and Folders Query */
const GET_CATEGORIES_AND_FOLDERS = gql`
query getAllSupportArticleCategoriesAndFoldersForAdmins {
  supportArticleCategories {
    id
    name
    folders {
      id
      name
    }
  }
}
`;

type GetCategoriesAndFoldersResponse = {
  supportArticleCategories: Category[];
}

type Category = {
  id: string;
  name: string;
  folders: {
    id: string;
    name: string;
  }[];
}

/* Upload Image Query */
const GET_IMAGE_UPLOAD_URL = gql`
query getSupportArticleImageUploadURL($fileMimeType: String!, $fileByteSize: Int!) {
  getSupportArticleImageUploadURL(fileMimeType: $fileMimeType, fileByteSize: $fileByteSize) {
    uploadURL
    publicURL
  }
}
`;

type GetImageUploadURLResponse = {
  getSupportArticleImageUploadURL: {
    uploadURL: string;
    publicURL: string;
  } | null;
}

/* Create Content Tag Mutation */
const CREATE_CONTENT_TAG = gql`
mutation createSupportContentTagForArticle($name: String!) {
  createSupportContentTag(name: $name) {
    id
    name
  }
}
`;

type CreateContentTagResponse = {
  createSupportContentTag: ContentTag | null;
}

type ContentTag = {
  id: string;
  name: string;
}

/* Add Content Tag Mutation */
const ADD_CONTENT_TAG = gql`
mutation addSupportArticleTag($articleId: ID!, $tagId: ID!) {
  addSupportArticleTag(articleId: $articleId, tagId: $tagId) {
    id
    name
  }
}
`;

type AddContentTagResponse = {
  addSupportArticleTag: ContentTag | null;
}

/* Remove Content Tag Mutation */
const REMOVE_CONTENT_TAG = gql`
mutation removeSupportArticleTag($articleId: ID!, $tagId: ID!) {
  removeSupportArticleTag(articleId: $articleId, tagId: $tagId) {
    id
    name
  }
}
`;

type RemoveContentTagResponse = {
  removeSupportArticleTag: ContentTag | null;
}

/* Search Tags Query */
const SEARCH_TAGS = gql`
query searchSupportContentTagsForArticle($name: String!) {
  searchSupportContentTags(name: $name) {
    id
    name
  }
}
`;

type SearchTagsResponse = {
  searchSupportContentTags: ContentTag[];
}

export default function KBAdminArticle() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { state: agentState } = useAgentAuthState();
  const { addAlert } = useAlertState();
  const [searchParams] = useSearchParams();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { data: articleData, loading: articleIsLoading, error: articleError } = useQuery<GetArticleResponse>(GET_ARTICLE, {
    variables: {
      id: id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.supportArticle) {
        setEditedTitle(data.supportArticle.draftRevision?.title || data.supportArticle.publishedRevision?.title || '');
        setEditedBody(data.supportArticle.draftRevision?.body || data.supportArticle.publishedRevision?.body || '');
        setIsReadyToPublish(data.supportArticle.draftRevision?.isReadyToPublish || false);
      }
    }
  });
  const [editArticleContent, { loading: editArticleContentIsLoading }] = useMutation(EDIT_ARTICLE);
  const [publishArticle] = useMutation(PUBLISH_ARTICLE, {
    update: (cache, { data }) => {
      // Remove the article from the publish queue
      cache.modify({
        fields: {
          publishableSupportArticles(existingArticlesRefs, { readField }) {
            return existingArticlesRefs.filter((articleRef: any) => {
              return readField('id', articleRef) !== id;
            });
          }
        }
      });
    },
  });
  const [deleteArticle] = useMutation<DeleteArticleResponse>(DELETE_ARTICLE, {
    update: (cache, { data }) => {
      if (!articleData?.supportArticle?.folder) {
        return;
      }

      if (data?.success) {
        cache.modify({
          id: cache.identify(articleData.supportArticle.folder),
          fields: {
            articles(existingArticlesRefs, { readField }) {
              return existingArticlesRefs.filter((articleRef: any) => {
                return readField('id', articleRef) !== articleData.supportArticle?.id;
              });
            }
          }
        });
      }

      // Evict the article from the cache
      cache.evict({
        id: cache.identify(articleData.supportArticle),
      });
    }
  });
  const [deleteArticleDraft] = useMutation(DELETE_ARTICLE_DRAFT);
  const [unpublishArticle] = useMutation(UNPUBLISH_ARTICLE);
  const [editArticleProperties, { loading: editArticlePropertiesIsLoading }] = useMutation(EDIT_ARTICLE_PROPERTIES);
  const { data: foldersData } = useQuery<GetCategoriesAndFoldersResponse>(GET_CATEGORIES_AND_FOLDERS);
  const [searchAgents, { data: searchData, loading: searchAgentsAreLoading }] = useLazyQuery<SearchAgentsResponse>(SEARCH_AGENTS, {
    fetchPolicy: 'cache-and-network',
  });
  const [getImageUploadURL] = useLazyQuery<GetImageUploadURLResponse>(GET_IMAGE_UPLOAD_URL, {
    fetchPolicy: 'network-only',
  });
  const [editedTitle, setEditedTitle] = useState<string>('');
  const [editedBody, setEditedBody] = useState<string>('');
  const [isReadyToPublish, setIsReadyToPublish] = useState<boolean>(false);
  const [isViewingDraft, setIsViewingDraft] = useState<boolean>(false);
  const [editedProperties, setEditedProperties] = useState<EditArticlePropertiesInput | null>(null);
  const [createContentTag] = useMutation<CreateContentTagResponse>(CREATE_CONTENT_TAG, {
    update: (cache) => {
      cache.evict({
        fieldName: 'searchSupportContentTags',
      });
    },
  });
  const [addContentTag] = useMutation<AddContentTagResponse>(ADD_CONTENT_TAG, {
    update: (cache, { data }) => {
      if (!data?.addSupportArticleTag || !articleData?.supportArticle) {
        return;
      }

      cache.modify({
        id: cache.identify(articleData.supportArticle),
        fields: {
          tags(existingTagsRefs = [], { readField }) {
            return [...existingTagsRefs, cache.writeFragment({
              data: data.addSupportArticleTag,
              fragment: gql`
                fragment NewTag on SupportContentTag {
                  id
                  name
                }
              `,
            })].sort((a: any, b: any) => {
              const aName = readField('name', a)?.toString() || '';
              const bName = readField('name', b)?.toString() || '';

              return aName.localeCompare(bName);
            });
          }
        }
      });
    },
  });
  const [removeContentTag] = useMutation<RemoveContentTagResponse>(REMOVE_CONTENT_TAG, {
    update: (cache, { data }) => {
      if (!data?.removeSupportArticleTag || !articleData?.supportArticle) {
        return;
      }

      cache.modify({
        id: cache.identify(articleData.supportArticle),
        fields: {
          tags(existingTagsRefs, { readField }) {
            return existingTagsRefs.filter((tagRef: any) => {
              return readField('id', tagRef) !== data.removeSupportArticleTag?.id;
            });
          }
        }
      });
    },
  });
  const [searchTags] = useLazyQuery<SearchTagsResponse>(SEARCH_TAGS);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isEditing && currentLocation.pathname !== nextLocation.pathname
  );

  const confirmNavigationModal = (
    <ConfirmModal title='Leave page?' confirmLabel='Discard Changes' destructive onConfirm={() => { blocker.proceed && blocker.proceed() }} onCancel={() => { blocker.reset && blocker.reset() }}>
      <StyledParagraph>Are you sure you want to leave this page? Your unsaved changes will be <span style={{ fontWeight: 'bold' }}>permanently lost</span>.</StyledParagraph>
    </ConfirmModal>
  );

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/help-center/articles/${id}`);

    const alertId = generateId();
    const alert = <StandardAlert title='Link copied' type='success' id={alertId} />
    addAlert(alertId, alert);
  }

  /* Edit Article */
  const handleSaveChanges = async () => {
    const { errors } = await editArticleContent({
      variables: {
        id: id,
        input: {
          title: editedTitle,
          body: editedBody,
          isReadyToPublish: isReadyToPublish,
        },
      },
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error saving draft' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
      return;
    }

    setIsEditing(false);
    setIsViewingDraft(true);
  }

  const handleCancelChanges = () => {
    setIsEditing(false);
    setEditedTitle(articleData?.supportArticle?.draftRevision?.title || articleData?.supportArticle?.publishedRevision?.title || '');
    setEditedBody(articleData?.supportArticle?.draftRevision?.body || articleData?.supportArticle?.publishedRevision?.body || '');
    setIsReadyToPublish(articleData?.supportArticle?.draftRevision?.isReadyToPublish || false);
  }

  const confirmDiscardChangesModal = (
    <ConfirmModal title='Discard changes?' confirmLabel='Discard Changes' destructive onConfirm={handleCancelChanges} onCancel={() => { }}>
      <StyledParagraph>Are you sure you want to cancel these changes? Your unsaved changes will be <span style={{ fontWeight: 'bold' }}>permanently lost</span>.</StyledParagraph>
    </ConfirmModal>
  );

  /* Publish Article */
  const handlePublishArticle = async () => {
    const { errors } = await publishArticle({
      variables: {
        id: id,
      },
    });

    setIsViewingDraft(false);

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error publishing article' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    } else {
      const alertId = generateId();
      const alert = <StandardAlert title='Article published' type='success' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const publishArticleModal = (
    <ConfirmModal title='Publish article?' onConfirm={handlePublishArticle} confirmLabel='Publish'>
      <StyledParagraph>The content changes will become immediately visible to all users with access to this article.</StyledParagraph>
    </ConfirmModal>
  );

  /* Delete Article */
  const handleDeleteArticle = async () => {
    const { data, errors } = await deleteArticle({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error deleting article' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
      return;
    }

    if (data?.success) {
      const alertId = generateId();
      const alert = <StandardAlert title='Article deleted' type='success' id={alertId} />
      addAlert(alertId, alert);

      navigate(`/agent/kb/folders/${articleData?.supportArticle?.folder.id}`);
    }
  }

  const deleteArticleModal = (
    <ConfirmModal title='Delete article?' onConfirm={handleDeleteArticle} confirmLabel='Delete' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This article will be <span style={{ fontWeight: 600 }}>permanently deleted</span>. You will not be able to recover the contents of this article.</StyledParagraph>
        <StyledParagraph>If you wish to preserve the contents of this article, consider moving the article to a hidden section instead.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Delete Article Draft */
  const handleDeleteArticleDraft = async () => {
    const { errors } = await deleteArticleDraft({
      variables: {
        articleId: id,
      },
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error deleting draft' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
      return;
    }

    setIsViewingDraft(false);
    setIsEditing(false);
  }

  const deleteArticleDraftModal = (
    <ConfirmModal title='Delete draft?' onConfirm={handleDeleteArticleDraft} confirmLabel='Delete' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This draft will be <span style={{ fontWeight: 600 }}>permanently deleted</span>. You will not be able to recover the contents of this draft.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Unpublish Article */
  const handleUnpublishArticle = async () => {
    const { errors } = await unpublishArticle({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error unpublishing article' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
      return;
    }

    setIsViewingDraft(true);
  }

  const unpublishArticleModal = (
    <ConfirmModal title='Unpublish article?' onConfirm={handleUnpublishArticle} confirmLabel='Unpublish'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This article will be unpublished and will <span style={{ fontWeight: 600 }}>no longer be visible to users.</span> If you accidentally published content, consider reverting to a previous version instead.</StyledParagraph>
        {articleData?.supportArticle?.draftRevision ? <StyledParagraph>A new draft will be created with the currently published content.</StyledParagraph> : <StyledParagraph>The current draft revision will be unaffected.</StyledParagraph>}
      </View>
    </ConfirmModal>
  );

  /* Edit Properties */
  const handlePropertyChange = (name: string, value: string | null) => {
    if (!editedProperties || value === null) {
      return;
    }

    if (name === 'categoryId') {
      setEditedProperties({
        ...editedProperties,
        folderId: null,
        categoryId: value,
      });
      return;
    } else {
      setEditedProperties({
        ...editedProperties,
        [name]: value,
      });
    }
  }

  const handleSearch = (_: string, value: string) => {
    if (value.length < 3) {
      return;
    }

    if (value.includes('@')) {
      searchAgents({
        variables: {
          email: value,
          name: null,
        },
        fetchPolicy: 'cache-and-network',
      });
    } else {
      searchAgents({
        variables: {
          email: null,
          name: value,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }

  const handleEditProperties = async () => {
    if (!editedProperties) {
      return;
    }

    const { errors } = await editArticleProperties({
      variables: {
        id: id,
        input: {
          authorId: editedProperties.authorId,
          folderId: editedProperties.folderId,
          visibility: editedProperties.visibility,
          isFeatured: editedProperties.isFeatured,
        },
      },
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error editing article properties' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
      return;
    }

    setEditedProperties(null);
  }

  /* Image Upload */
  const handleImageUpload = async (file: File): Promise<ImagePayload | null> => {
    // Get the signed URL to upload the image to cloud storage
    const { data } = await getImageUploadURL({
      variables: {
        fileMimeType: file.type,
        fileByteSize: file.size,
      },
    });

    if (!data?.getSupportArticleImageUploadURL) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error uploading image' type='error' id={alertId} />
      addAlert(alertId, alert);

      return null;
    }

    // Upload the image to cloud storage
    const response = await fetch(data.getSupportArticleImageUploadURL.uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (!response.ok) {
      return null;
    }

    // Return the URL
    return {
      src: data.getSupportArticleImageUploadURL.publicURL,
    };
  }

  /* Tags */
  const handleTagCreate = async (name: string) => {
    const { data, errors } = await createContentTag({
      variables: {
        name: name,
      },
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error creating tag' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    } else {
      const { errors } = await addContentTag({
        variables: {
          articleId: id,
          tagId: data?.createSupportContentTag?.id,
        },
      });

      if (errors) {
        const alertId = generateId();
        const alert = <StandardAlert title='Error adding tag' description={errors[0].message} type='error' id={alertId} />
        addAlert(alertId, alert);
      }
    }
  }

  const handleTagAdd = async (tag: ContentTag) => {
    const { errors } = await addContentTag({
      variables: {
        articleId: id,
        tagId: tag.id,
      },
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error adding tag' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleTagRemove = (tag: ContentTag) => {
    removeContentTag({
      variables: {
        articleId: id,
        tagId: tag.id,
      },
    });
  }

  const handleTagSearch = async (name: string) => {
    const { data, error } = await searchTags({
      variables: {
        name: name
      },
    });

    if (error) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error searching tags' description={error.message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }

    return data?.searchSupportContentTags || [];
  }

  if (!agentState.user?.isSupportAgent) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (articleIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (articleError || !articleData?.supportArticle) {
    if (articleError?.graphQLErrors[0]?.extensions.status === 404) {
      return (
        <StandardGrid>
          <NotFound />
        </StandardGrid>
      );
    }

    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <View style={{ backgroundColor: '#ffffff', boxSizing: 'border-box', height: '100%', maxHeight: '100%', overflow: 'scroll', width: '100%' }}>
      <View style={{ borderBottom: `1px solid ${Colors.neutral300}`, gap: '16px', padding: '16px 32px' }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
            <Button label='View' leftIcon={Icons.Eye} variant='secondary' role='link' href={`/help-center/articles/${id}`} disabled={articleData.supportArticle.publishedRevision === null} />
            <Button label='Copy Link' leftIcon={Icons.Link} variant='secondary' role='button' action={handleCopyLink} />
          </View>

          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px' }}>
            {articleData.supportArticle.canEdit &&
              (isEditing ?
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px' }}>
                  {((agentState.user.supportAgentPermissions?.canManageAllArticles || articleData.supportArticle.folder.category.canPublish) && articleData.supportArticle.draftRevision) &&
                    <ModalLauncher modal={deleteArticleDraftModal}>
                      {({ openModal }) => (
                        <Button label='Delete Draft' variant='secondary' role='button' destructive action={openModal} />
                      )}
                    </ModalLauncher>
                  }
                  <SingleSelect name='isReadyToPublish' value={isReadyToPublish ? 'TRUE' : 'FALSE'} onChange={(_, value) => { setIsReadyToPublish(value === 'TRUE'); }} style={{ width: '224px' }}>
                    <Choice label='Changes not ready' value='FALSE' />
                    <Choice label='Ready to publish' value='TRUE' />
                  </SingleSelect>
                  <Button label='Save Changes' variant='primary' role='button' action={handleSaveChanges} loading={editArticleContentIsLoading} />
                  <ModalLauncher modal={confirmDiscardChangesModal}>
                    {({ openModal }) => (
                      <Button label='Cancel' variant='tertiary' role='button' action={openModal} />
                    )}
                  </ModalLauncher>
                </View>
                :
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px' }}>
                  <Button label='Edit Article' variant='secondary' leftIcon={Icons.Edit} role='button' action={() => { setIsEditing(true); }} />

                  {(articleData.supportArticle.canPublish && articleData.supportArticle.draftRevision?.isReadyToPublish) &&
                    <ModalLauncher modal={publishArticleModal}>
                      {({ openModal }) => (
                        <Button label='Publish' variant='primary' role='button' action={openModal} />
                      )}
                    </ModalLauncher>
                  }

                  {(agentState.user.supportAgentPermissions?.canManageAllArticles || articleData.supportArticle.folder.category.canPublish || articleData.supportArticle.folder.canPublish) &&
                    <ModalLauncher modal={<ArticlePermissionsModal id={id || ''} />}>
                      {({ openModal }) => (
                        <Button label='Share' variant='tertiary' leftIcon={Icons.UserPlus} role='button' action={openModal} />
                      )}
                    </ModalLauncher>
                  }

                  {articleData.supportArticle.canPublish &&
                    <ModalLauncher modal={deleteArticleModal}>
                      {({ openModal: openDeleteModal }) => (
                        <ModalLauncher modal={unpublishArticleModal}>
                          {({ openModal: openUnpublishModal }) => (
                            <ActionMenu alignment='right'>
                              {articleData?.supportArticle?.publishedRevision && <ActionItem label='Unpublish' onClick={openUnpublishModal} />}
                              {(agentState?.user?.supportAgentPermissions?.canManageAllArticles || articleData?.supportArticle?.folder.category.canPublish) && <ActionItem label='Delete' onClick={openDeleteModal} />}
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      )}
                    </ModalLauncher>
                  }
                </View>
              )
            }
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', height: '100%', maxHeight: '100%', overflowX: 'scroll', overflowY: 'hidden' }}>
        <View style={{ alignItems: 'center', flexGrow: 1, height: '100%', padding: '32px' }}>
          <View style={{ gap: '24px', height: '100%', maxHeight: '100%', maxWidth: '1000px', width: '100%' }}>
            {(!articleData.supportArticle.publishedRevision && articleData.supportArticle.canEdit && !isEditing) && (
              <InfoPanel type='warning' style={{ minHeight: 'fit-content' }}>
                <StyledParagraph>You are viewing a draft version of this article. This article has <span style={{ fontWeight: 600 }}>no published version</span>.</StyledParagraph>
              </InfoPanel>
            )}

            {(articleData.supportArticle.publishedRevision && isViewingDraft && !isEditing) &&
              <InfoPanel type='warning' style={{ minHeight: 'fit-content' }}>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>You are viewing a <span style={{ fontWeight: 600 }}>draft version</span> of this article.</StyledParagraph>
                  <Button label='View published version' variant='tertiary' role='button' action={() => { setIsViewingDraft(false); }} style={{ height: 'fit-content' }} />
                </View>
              </InfoPanel>
            }

            {(articleData.supportArticle.draftRevision && articleData.supportArticle.canEdit && !isEditing && !isViewingDraft && articleData.supportArticle.publishedRevision) &&
              <InfoPanel type='info' style={{ minHeight: 'fit-content' }}>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>A draft version exists for this article.</StyledParagraph>
                  <Button label='View draft' variant='tertiary' role='button' action={() => { setIsViewingDraft(true); }} style={{ height: 'fit-content' }} />
                </View>
              </InfoPanel>
            }

            {isEditing ?
              <TextField name='title' value={editedTitle} onChange={(_, value) => { setEditedTitle(value); }} style={{ flexGrow: 0, minHeight: 'fit-content' }} />
              :
              <StyledHeading tag='h4'>{isViewingDraft ? articleData.supportArticle.draftRevision?.title : articleData.supportArticle.title}</StyledHeading>
            }

            {(articleData.supportArticle.publishedRevision && !isEditing && !isViewingDraft) ?
              <TextEditor name='article-viewer' key='article-viewer' value={articleData.supportArticle.publishedRevision.body} isEditMode={false} containerStyle={{ overflowY: 'auto', width: '100%' }} />
              :
              (articleData.supportArticle.canEdit ?
                <TextEditor name='article-draft-editor' key='article-draft-editor' buttonConfig={ARTICLE_EDITOR} value={editedBody} isEditMode={isEditing} onImageUpload={handleImageUpload} maxImageBytes={5 * MiB} onChange={(_, value) => { setEditedBody(value); }} innerContainerStyle={{ overflow: 'hidden' }} containerStyle={{ boxSizing: 'border-box', maxHeight: '100%', overflowY: 'auto', width: '100%' }} editorStyle={{ maxHeight: '100%', overflowY: 'scroll' }} />
                :
                <View>
                  <StyledParagraph>This article has no published content.</StyledParagraph>
                </View>
              )
            }
          </View>
        </View>

        <View style={{ borderLeft: `1px solid ${Colors.neutral300}`, flexGrow: 1, maxWidth: '400px', minWidth: '380px', padding: '32px' }}>
          <View style={{ alignItems: 'flex-start', height: '100%', width: '100%' }}>

            {searchParams.get('referrer') === 'publishQueue' &&
              <Link href='/agent/kb/publish-queue' linkStyle={{ marginBottom: '24px' }}>Return to publish queue</Link>
            }

            <View style={{ justifyContent: 'space-between', height: '100%', width: '100%' }}>
              <View style={{ gap: '24px', width: '100%' }}>
                {editedProperties ?
                  <>
                    <SingleSelect label='Category' name='categoryId' onChange={handlePropertyChange} value={editedProperties.categoryId} required>
                      {foldersData?.supportArticleCategories.map((category) => {
                        return (
                          <Choice label={category.name} value={category.id} key={category.id} />
                        );
                      })}
                    </SingleSelect>
                    <SingleSelect label='Folder' name='folderId' onChange={handlePropertyChange} value={editedProperties.folderId} required>
                      {foldersData?.supportArticleCategories.find((category) => category.id === editedProperties.categoryId)?.folders.map((folder) => {
                        return (
                          <Choice label={folder.name} value={folder.id} key={folder.id} />
                        );
                      })}
                    </SingleSelect>
                    <SingleSelect label='Visibility' name='visibility' onChange={handlePropertyChange} value={editedProperties.visibility} required>
                      <Choice label={parseArticleVisibility(ArticleVisibility.PUBLIC)} value={ArticleVisibility.PUBLIC} />
                      <Choice label={parseArticleVisibility(ArticleVisibility.LOGGED_IN_USERS)} value={ArticleVisibility.LOGGED_IN_USERS} />
                      <Choice label={parseArticleVisibility(ArticleVisibility.AGENTS_ONLY)} value={ArticleVisibility.AGENTS_ONLY} />
                    </SingleSelect>
                    <SingleSelect label='Author' name='authorId' onChange={handlePropertyChange} onFilter={handleSearch} filterable filterPlaceholder='Search by name or email' autoFocusSearch value={editedProperties.authorId} required>
                      {searchAgentsAreLoading &&
                        <View style={{ flexDirection: 'row', justifyContent: 'center' }} key='agent-search-loading'>
                          <CircularSpinner size='small' />
                        </View>
                      }

                      {searchData?.supportAgents?.filter((agent) => agent.id !== editedProperties.authorId).map((agent) => {
                        return (
                          <Choice label={`${agent.firstName} ${agent.lastName}`} description={agent.email || undefined} value={agent.id} key={agent.id} />
                        );
                      })}

                      {articleData.supportArticle.author.id === editedProperties.authorId ?
                        <Choice label={`${articleData.supportArticle.author.firstName} ${articleData.supportArticle.author.lastName}`} description={articleData.supportArticle.author.email} value={articleData.supportArticle.author.id} />
                        :
                        (() => {
                          const author = searchData?.supportAgents?.find((agent) => agent.id === editedProperties.authorId);
                          if (!author) {
                            return null;
                          }

                          return (
                            <Choice label={`${author.firstName} ${author.lastName}`} description={author.email || undefined} value={author.id} />
                          );
                        })()
                      }
                    </SingleSelect>

                    <Checkbox label='Feature on homepage' name='isFeatured' checked={editedProperties.isFeatured} onChange={(_, checked: boolean) => { let props = { ...editedProperties }; props.isFeatured = checked; setEditedProperties(props); }} disabled={!agentState.user.supportAgentPermissions?.canManageAllArticles} />

                    <Button label='Save' variant='primary' role='button' action={handleEditProperties} loading={editArticlePropertiesIsLoading} isFullWidth />
                    <Button label='Cancel' variant='secondary' role='button' action={() => { setEditedProperties(null); }} isFullWidth />
                  </>
                  :
                  <>
                    <View style={{ width: '100%' }}>
                      <StyledParagraph bold>Location</StyledParagraph>
                      <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '8px', width: '100%' }}>
                        <Link href={`/agent/kb/categories/${articleData.supportArticle.folder.category.id}`}>{articleData.supportArticle.folder.category.name}</Link>
                        <Icon icon={Icons.ChevronRight} size='small' style={{ color: Colors.neutral700, width: '8px' }} />
                        <Link href={`/agent/kb/folders/${articleData.supportArticle.folder.id}`}>{articleData.supportArticle.folder.name}</Link>
                      </View>
                    </View>

                    <View style={{ width: '100%' }}>
                      <StyledParagraph bold>Visibility</StyledParagraph>
                      <StyledParagraph>{parseArticleVisibility(articleData.supportArticle.visibility)}</StyledParagraph>
                    </View>

                    <View style={{ gap: '6px', width: '100%' }}>
                      <StyledParagraph bold>Author</StyledParagraph>
                      <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                        <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                        <StyledParagraph>{articleData.supportArticle.author.firstName} {articleData.supportArticle.author.lastName}</StyledParagraph>
                      </View>
                    </View>

                    {(articleData.supportArticle.folder.category.canPublish || agentState.user.supportAgentPermissions?.canManageAllArticles) && <Button label='Edit Properties' variant='tertiary' role='button' action={() => {
                      if (!articleData.supportArticle) {
                        return;
                      }

                      setEditedProperties({
                        visibility: articleData.supportArticle.visibility,
                        folderId: articleData.supportArticle.folder.id,
                        categoryId: articleData.supportArticle.folder.category.id,
                        authorId: articleData.supportArticle.author.id,
                        isFeatured: articleData.supportArticle.isFeatured,
                      });
                    }} isFullWidth />}

                    <View>
                      <TagSelector name='articleTags' label='Content Tags' description='Use tags to improve article suggestions' selectedTags={articleData.supportArticle.tags} onTagSearch={handleTagSearch} onTagSelect={handleTagAdd} onTagRemove={handleTagRemove} onTagCreate={handleTagCreate} style={{ width: '100%' }} disabled={!articleData.supportArticle.canPublish} />
                    </View>
                  </>
                }
              </View>

              <View style={{ gap: '12px', width: '100%' }}>
                {(articleData.supportArticle.canEdit && articleData.supportArticle.draftRevision) && <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px' }}><span style={{ color: Colors.neutral800, fontWeight: 600 }}>Last edited: </span><ReactTimeago date={articleData.supportArticle.draftRevision.lastUpdated} /> by {articleData.supportArticle.draftRevision.lastUpdatedBy.firstName} {articleData.supportArticle.draftRevision.lastUpdatedBy.lastName}</StyledParagraph>}
                {articleData.supportArticle.publishedRevision && <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px' }}><span style={{ color: Colors.neutral800, fontWeight: 600 }}>Last published: </span><ReactTimeago date={articleData.supportArticle.publishedRevision.published} /> by {articleData.supportArticle.publishedRevision.publishedBy.firstName} {articleData.supportArticle.publishedRevision.publishedBy.lastName}</StyledParagraph>}
                <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px' }}><span style={{ color: Colors.neutral800, fontWeight: 600 }}>Created: </span><ReactTimeago date={articleData.supportArticle.created} /></StyledParagraph>
                <Link href={`/agent/kb/articles/${id}/revisions`}>View previous revisions</Link>
              </View>
            </View>

            {
              blocker.state === 'blocked' &&
              <ModalLauncher modal={confirmNavigationModal}>
                {({ openModal }) => (
                  <AutoOpen openModal={openModal} />
                )}
              </ModalLauncher>
            }

          </View>
        </View>
      </View>
    </View>
  );
}

/* Get Permissions Query */
const GET_ARTICLE_PERMISSIONS = gql`
query getPermissionsForSupportArticle($id: ID!) {
  permissionsForSupportArticle(id: $id) {
    user {
      id
      firstName
      lastName
      email
    }
    permission
  }
}
`;

type GetArticlePermissionsResponse = {
  permissionsForSupportArticle: PermissionAssignment[] | null;
}

/* Grant Permission Mutation */
const GRANT_PERMISSION = gql`
mutation grantSupportArticlePermission($articleId: ID!, $userId: ID!, $permission: KnowledgeBasePermission!) {
  grantSupportArticlePermission(articleId: $articleId, userId: $userId, permission: $permission) {
    user {
      id
      firstName
      lastName
      email
    }
    permission
  }
}
`;

type GrantPermissionResponse = {
  grantSupportArticleCPermission: PermissionAssignment | null;
}

/* Edit Permission Mutation */
const EDIT_PERMISSION = gql`
mutation editSupportArticlePermission($articleId: ID!, $userId: ID!, $permission: KnowledgeBasePermission!) {
  editSupportArticlePermission(articleId: $articleId, userId: $userId, permission: $permission) {
    user {
      id
    }
    permission
  }
}
`;

type EditPermissionResponse = {
  editSupportArticlePermission: {
    user: {
      id: string;
    };
    permission: Permission;
  } | null;
}

/* Revoke Permission Mutation */
const REVOKE_PERMISSION = gql`
mutation revokeSupportArticlePermission($articleId: ID!, $userId: ID!) {
  success: revokeSupportArticlePermission(articleId: $articleId, userId: $userId)
}
`;

type RevokePermissionResponse = {
  success: boolean;
}

type ArticlePermissionsModalProps = {
  id: string;
  handleClose?: (data?: any) => void;
}

type PermissionAssignment = {
  user: PermissionUser;
  permission: Permission;
}

type PermissionUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
}

enum Permission {
  EDIT = 'EDIT',
  PUBLISH = 'PUBLISH',
}

function ArticlePermissionsModal(props: ArticlePermissionsModalProps) {
  const { addAlert } = useAlertState();
  const { loading: permissionsAreLoading } = useQuery<GetArticlePermissionsResponse>(GET_ARTICLE_PERMISSIONS, {
    variables: {
      id: props.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.permissionsForSupportArticle) {
        setPermissions(data.permissionsForSupportArticle);
      }
    }
  });
  const [grantPermission, { loading: grantPermissionIsLoading }] = useMutation<GrantPermissionResponse>(GRANT_PERMISSION);
  const [editPermission, { loading: editPermissionIsLoading }] = useMutation<EditPermissionResponse>(EDIT_PERMISSION);
  const [revokePermission, { loading: revokePermissionIsLoading }] = useMutation<RevokePermissionResponse>(REVOKE_PERMISSION);
  const [searchAgents, { data: searchData, loading: searchAgentsAreLoading }] = useLazyQuery<SearchAgentsResponse>(SEARCH_AGENTS, {
    fetchPolicy: 'cache-and-network',
  });
  const [permissions, setPermissions] = useState<PermissionAssignment[]>([]);
  const [addUserId, setAddUserId] = useState<string | null>(null);
  const [addUserRole, setAddUserRole] = useState<Permission | null>(Permission.EDIT);

  const handleGrantPermission = async () => {
    if (!addUserId || !addUserRole) {
      return;
    }

    const { errors } = await grantPermission({
      variables: {
        articleId: props.id,
        userId: addUserId,
        permission: addUserRole,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to grant permission' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Permission granted' type='success' id={id} />
      addAlert(id, alert);

      // Add the permission to the cache
      const newPermissions = [...permissions];
      newPermissions.push({
        user: {
          id: addUserId,
          firstName: searchData?.supportAgents?.find((agent) => agent.id === addUserId)?.firstName || '',
          lastName: searchData?.supportAgents?.find((agent) => agent.id === addUserId)?.lastName || '',
          email: searchData?.supportAgents?.find((agent) => agent.id === addUserId)?.email || null,
        },
        permission: addUserRole,
      });

      newPermissions.sort((a, b) => {
        const aName = `${a.user.firstName} ${a.user.lastName}`;
        const bName = `${b.user.firstName} ${b.user.lastName}`;

        return aName.localeCompare(bName);
      });

      setPermissions(newPermissions);

      // Reset the add user fields
      setAddUserId(null);
      setAddUserRole(Permission.EDIT);
    }
  }

  const handlePermissionChange = (name: string, value: string | null) => {
    if (value === null) {
      // Remove permission
      const newPermissions = [...permissions];
      const index = newPermissions.findIndex((p) => p.user.id === name);
      newPermissions.splice(index, 1);
      setPermissions(newPermissions);

      // Make API call to revoke permission
      revokePermission({
        variables: {
          articleId: props.id,
          userId: name,
        },
      }).then(({ errors }) => {
        if (errors) {
          const id = generateId();
          const alert = <StandardAlert title='Failed to remove permission' description={errors[0].message} type='error' id={id} />
          addAlert(id, alert);
        }
      });

      return;
    }

    // Update the cached permission
    const newPermissions = [...permissions];
    const index = newPermissions.findIndex((p) => p.user.id === name);
    newPermissions[index].permission = parsePermissionStringValue(value);
    setPermissions(newPermissions);

    // Make API call to update permission
    editPermission({
      variables: {
        articleId: props.id,
        userId: name,
        permission: value,
      },
    }).then(({ errors }) => {
      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Failed to update permissions' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      }
    });
  }

  const handleSearch = (name: string, value: string) => {
    setAddUserId(null);

    if (value.length < 3) {
      return;
    }

    if (value.includes('@')) {
      searchAgents({
        variables: {
          email: value,
          name: null,
        },
        fetchPolicy: 'cache-and-network',
      });
    } else {
      searchAgents({
        variables: {
          email: null,
          name: value,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }

  const foundUsers = searchData?.supportAgents?.filter((agent) => {
    // Filter out any agents that already have access
    return !permissions.some((p) => p.user.id === agent.id);
  });

  const header = <ModalHeader title='Manage Permissions' />;

  const body = (
    <ModalBody>
      {permissionsAreLoading ?
        <View style={{ alignItems: 'center' }}>
          <CircularSpinner size='medium' />
        </View>
        :
        <View style={{ gap: '48px', minHeight: 'fit-content', overflow: 'scroll' }}>
          <View style={{ gap: '16px', minHeight: 'fit-content' }}>
            <StyledHeading tag='h6'>Grant access</StyledHeading>
            <View style={{ flexDirection: 'row', gap: '16px', minHeight: 'fit-content' }}>
              <SingleSelect name='addUserId' value={addUserId} placeholder='Select a user' filterPlaceholder='Search by name or email' filterable autoFocusSearch onFilter={handleSearch} onChange={(_: string, value: string | null) => { setAddUserId(value); }}>
                {searchAgentsAreLoading ?
                  <View style={{ flexDirection: 'row', justifyContent: 'center' }} key='agent-search-loading'>
                    <CircularSpinner size='small' />
                  </View>
                  :
                  <View>
                    {foundUsers?.length === 0 ?
                      <StyledParagraph style={{ color: Colors.neutral700, margin: '16px', textAlign: 'center' }}>No agents found.</StyledParagraph>
                      :
                      foundUsers?.map((agent) => (
                        <Choice label={`${agent.firstName} ${agent.lastName}`} description={agent.email || undefined} value={agent.id} key={agent.id} />
                      ))
                    }
                  </View>
                }
              </SingleSelect>
              <SingleSelect name='addUserRole' value={addUserRole} style={{ flexGrow: 0, width: '150px' }} onChange={(_: string, value: string | null) => { setAddUserRole(parsePermissionStringValue(value)); }}>
                <Choice label='Editor' value={Permission.EDIT} />
                <Choice label='Publisher' value={Permission.PUBLISH} />
              </SingleSelect>
            </View>
            <Button label='Grant Access' variant='primary' role='button' action={handleGrantPermission} loading={grantPermissionIsLoading} disabled={addUserId === null} />
          </View>
          <View style={{ minHeight: 'fit-content' }}>
            <StyledHeading tag='h6'>People with access</StyledHeading>
            <View style={{ gap: '16px', marginTop: '16px' }}>
              {permissions.length === 0 && <StyledParagraph style={{ color: Colors.neutral700 }}>There are no permissions granted for this article.</StyledParagraph>}
              {permissions.map((p) => (
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                  <View>
                    <StyledParagraph bold>{p.user.firstName} {p.user.lastName}</StyledParagraph>
                    {p.user.email && <StyledParagraph style={{ color: Colors.neutral700 }}>{p.user.email}</StyledParagraph>}
                  </View>

                  <SingleSelect name={p.user.id} value={p.permission.toString()} style={{ flexGrow: 0, width: '200px' }} onChange={handlePermissionChange}>
                    <Choice label='Editor' value='EDIT' />
                    <Choice label='Publisher' value='PUBLISH' />
                    <Choice label='Remove' value={null} style={{ borderTop: `1px solid ${Colors.neutral300}`, marginTop: '4px' }} />
                  </SingleSelect>
                </View>
              ))}
            </View>
          </View>
        </View>
      }
    </ModalBody>
  );

  const footer = (
    <ModalFooter>
      <View style={{ alignItems: 'center', flexDirection: 'row', height: '40px', justifyContent: 'space-between', width: '100%' }}>
        {(editPermissionIsLoading || revokePermissionIsLoading) ?
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
            <StyledParagraph style={{ color: Colors.neutral700 }}>Saving changes...</StyledParagraph>
            <CircularSpinner size='xsmall' />
          </View>
          :
          <StyledParagraph style={{ color: Colors.neutral700 }}>All changes saved</StyledParagraph>
        }

        <Button label='Done' variant='primary' role='button' action={props.handleClose ? props.handleClose : () => { }} />
      </View>
    </ModalFooter>
  );

  return (
    <Modal onClose={props.handleClose} header={header} body={body} footer={footer} />
  );
}

function parsePermissionStringValue(value: string | null): Permission {
  switch (value) {
    case 'EDIT':
      return Permission.EDIT;
    case 'PUBLISH':
      return Permission.PUBLISH;
    default:
      return Permission.EDIT;
  }
}