import { Reducer } from "react";
import { SupportAgentPermissions } from "./AgentAuthStateProvider";

export type User = {
  id: string;
  isSupportAgent: boolean;
  supportAgentPermissions: SupportAgentPermissions | null;
}

export type AgentAuthState = {
  user: User | null;
}

export type AuthAction =
  | {
    type: "LOGOUT"
  }
  | {
    type: "LOGIN"
    payload: User
  }

export const agentAuthStateReducer: Reducer<AgentAuthState, AuthAction> = (state: AgentAuthState, action: AuthAction): AgentAuthState => {
  switch (action.type) {
    case "LOGOUT": {
      return {
        user: null,
      }
    }
    case "LOGIN": {
      return {
        user: action.payload
      }
    }

    default:
      return state
  }
}

export const initialState = {
  user: null,
}

export function logout(): AuthAction {
  return {
    type: "LOGOUT"
  }
}

export function login(user: User): AuthAction {
  return {
    type: "LOGIN",
    payload: user
  }
}