import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionItemSection, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Checkbox, Choice, CircularSpinner, Colors, ConfirmModal, ErrorPage, FormModal, Grid, Icon, Icons, InfoPanel, Link, MiB, ModalLauncher, NoPermission, NotFound, PageButtons, Row, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TagSelector, TextEditor, TextField, Tooltip, Typography, View, generateId, useAlertState, useAuthState, useForm } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";
import TimeAgo from 'react-timeago';
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { COMMUNITY_POST_EDITOR } from "../../util/config/textEditorConfig";
import { ImagePayload } from "@barscience/global-components/dist/typings/TextEditor/nodes/ImageNode";
import HelpCenterHeader from "../helpCenter/HelpCenterHeader";
import FlagPostModal from "./FlagPostModal";
import { createStyle } from "../../util/createStyle";

/* Get Post Query */
const GET_POST = gql`
query getCommunityPostDetails($id: ID!) {
  communityPost(id: $id) {
    id
    title
    body
    author {
      id
      nickname
      isSupportAgent
      nameBadge {
        title
        hexColor
      }
    }
    created
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    isFeatured
    isHidden
    isLocked
    isPinned
    status {
      id
      publicName
      internalName
      color
    }
    tags {
      id
      name
    }
    relatedArticles {
      id
      title
    }
    relatedPosts {
      id
      title
    }
    topic {
      id
      name
      restrictPosting
      postType {
        statuses {
          id
          publicName
          internalName
        }
      }
    }
    isFlaggedByUser
    flags {
      user {
        id
        nickname
      }
      category
    }
  }
}
`;

type GetCommunityPostResponse = {
  communityPost: CommunityPostDetails | null;
}

export type CommunityPostDetails = {
  id: string;
  title: string;
  body: string;
  author: PostAuthor;
  created: string;
  lastUpdated: string | null;
  lastUpdatedBy: PostAuthor | null;
  isFeatured: boolean;
  isHidden: boolean;
  isLocked: boolean;
  isPinned: boolean;
  status: PostStatus | null;
  tags: ContentTag[] | null;
  relatedArticles: {
    id: string;
    title: string;
  }[] | null;
  relatedPosts: {
    id: string;
    title: string;
  }[] | null;
  topic: PostTopic;
  isFlaggedByUser: boolean;
  flags: {
    user: {
      id: string;
      nickname: string;
    };
    category: string;
  }[] | null;
}

type PostAuthor = {
  id: string;
  nickname: string;
  isSupportAgent: boolean;
  nameBadge: NameBadge | null;
}

type NameBadge = {
  title: string;
  hexColor: string;
}

type PostTopic = {
  id: string;
  name: string;
  restrictPosting: boolean;
  postType: PostType;
}

type PostType = {
  statuses: PostStatus[] | null;
}

type PostStatus = {
  id: string;
  publicName: string;
  internalName: string | null;
  color: string;
}

/* Edit Post Mutation */
const EDIT_POST = gql`
mutation editCommunityPost($id: ID!, $input: EditCommunityPostInput!) {
  editCommunityPost(id: $id, input: $input) {
    id
    title
    body
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    isHidden
  }
}
`;

type EditCommunityPostResponse = {
  editCommunityPost: {
    id: string;
    title: string;
    body: string;
    lastUpdated: string | null;
    lastUpdatedBy: PostAuthor | null;
  } | null;
}

type EditCommunityPostInput = {
  title: string;
  body: string;
}

/* Delete Post Mutation */
export const DELETE_POST = gql`
mutation deleteCommunityPost($id: ID!) {
  deleteCommunityPost(id: $id) {
    id
  }
}
`;

type DeleteCommunityPostResponse = {
  deleteCommunityPost: {
    id: string;
  } | null;
}

/* Pin Post Mutation */
const PIN_POST = gql`
mutation setCommunityPostPinStatus($id: ID!, $isPinned: Boolean!) {
  setCommunityPostPinStatus(id: $id, isPinned: $isPinned) {
    id
    isPinned
  }
}
`;

/* Feature Post Mutation */
const FEATURE_POST = gql`
mutation setCommunityPostFeatureStatus($id: ID!, $isFeatured: Boolean!) {
  setCommunityPostFeatureStatus(id: $id, isFeatured: $isFeatured) {
    id
    isFeatured
  }
}
`;

/* Set Status Mutation */
const SET_STATUS = gql`
mutation setCommunityPostStatus($id: ID!, $statusId: ID) {
  setCommunityPostStatus(id: $id, statusId: $statusId) {
    id
    status {
      id
      publicName
      internalName
      color
    }
  }
}
`;

/* Lock Post Mutation */
const LOCK_POST = gql`
mutation setCommunityPostLockStatus($id: ID!, $isLocked: Boolean!) {
  setCommunityPostLockStatus(id: $id, isLocked: $isLocked) {
    id
    isLocked
  }
}
`;

/* Get Comments Query */
const GET_COMMENTS = gql`
query getCommunityPostComments($postId: ID!, $page: Int!, $sort: CommunityPostCommentSort!) {
  communityPostComments(postId: $postId, page: $page, sort: $sort) {
    id
    body
    author {
      id
      nickname
      nameBadge {
        title
        hexColor
      }
    }
    created
    isHidden
    isOfficial
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    isFlaggedByUser
    flags {
      user {
        id
        nickname
      }
      category
    }
  }
}
`;

type GetCommunityPostCommentsResponse = {
  communityPostComments: CommunityPostComment[] | null;
}

type CommunityPostComment = {
  id: string;
  body: string;
  author: PostAuthor;
  created: string;
  isHidden: boolean;
  isOfficial: boolean;
  lastUpdated: string | null;
  lastUpdatedBy: PostAuthor | null;
  isFlaggedByUser: boolean;
  flags: {
    user: {
      id: string;
      nickname: string;
    };
    category: string;
  }[] | null;
}

/* Create Comment Mutation */
const CREATE_COMMENT = gql`
mutation CreateCommunityPostComment($postId: ID!, $body: String!, $isOfficial: Boolean!) {
  createCommunityPostComment(postId: $postId, body: $body, isOfficial: $isOfficial) {
    id
    body
    author {
      id
      nickname
    }
    created
    isHidden
    isOfficial
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
  }
}
`;

type CreateCommunityPostCommentResponse = {
  createCommunityPostComment: CommunityPostComment | null;
}

type CreateCommentInput = {
  createCommentBody: string;
  isOfficial: boolean;
}

/* Edit Comment Mutation */
const EDIT_COMMENT = gql`
mutation editCommunityPostComment($id: ID!, $body: String!, $isOfficial: Boolean) {
  editCommunityPostComment(id: $id, body: $body, isOfficial: $isOfficial) {
    id
    body
    isOfficial
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    isHidden
  }
}
`;

type EditCommentInput = {
  id: string;
  body: string;
  isOfficial: boolean;
}

/* Delete Comment Mutation */
export const DELETE_COMMENT = gql`
mutation deleteCommunityPostComment($id: ID!) {
  deleteCommunityPostComment(id: $id) {
    id
  }
}
`;

/* Upload Post Image Query */
const GET_POST_IMAGE_UPLOAD_URL = gql`
query getCommunityPostImageUploadURL($fileMimeType: String!, $fileByteSize: Int!) {
  getCommunityPostImageUploadURL(fileMimeType: $fileMimeType, fileByteSize: $fileByteSize) {
    uploadURL
    publicURL
  }
}
`;

type GetPostImageUploadURLResponse = {
  getCommunityPostImageUploadURL: {
    uploadURL: string;
    publicURL: string;
  } | null;
}

/* Upload Comment Image Query */
const GET_COMMENT_IMAGE_UPLOAD_URL = gql`
query getCommunityPostCommentImageUploadURL($fileMimeType: String!, $fileByteSize: Int!) {
  getCommunityPostCommentImageUploadURL(fileMimeType: $fileMimeType, fileByteSize: $fileByteSize) {
    uploadURL
    publicURL
  }
}
`;

type GetPostCommentImageUploadURLResponse = {
  getCommunityPostCommentImageUploadURL: {
    uploadURL: string;
    publicURL: string;
  } | null;
}

/* Create Content Tag Mutation */
const CREATE_CONTENT_TAG = gql`
mutation createSupportContentTagForPost($name: String!) {
  createSupportContentTag(name: $name) {
    id
    name
  }
}
`;

type CreateContentTagResponse = {
  createSupportContentTag: ContentTag | null;
}

type ContentTag = {
  id: string;
  name: string;
}

/* Add Content Tag Mutation */
const ADD_CONTENT_TAG = gql`
mutation addCommunityPostTag($postId: ID!, $tagId: ID!) {
  addCommunityPostTag(postId: $postId, tagId: $tagId) {
    id
    name
  }
}
`;

type AddContentTagResponse = {
  addCommunityPostTag: ContentTag | null;
}

/* Remove Content Tag Mutation */
const REMOVE_CONTENT_TAG = gql`
mutation removeCommunityPostTag($postId: ID!, $tagId: ID!) {
  removeCommunityPostTag(postId: $postId, tagId: $tagId) {
    id
    name
  }
}
`;

type RemoveContentTagResponse = {
  removeCommunityPostTag: ContentTag | null;
}

/* Search Tags Query */
const SEARCH_TAGS = gql`
query searchSupportContentTagsForPost($name: String!) {
  searchSupportContentTags(name: $name) {
    id
    name
  }
}
`;

type SearchTagsResponse = {
  searchSupportContentTags: ContentTag[];
}

/* Hide Post Mutation */
const HIDE_POST = gql`
mutation hideCommunityPost($id: ID!) {
  hideCommunityPost(id: $id) {
    id
    isHidden
  }
}
`;

/* Hide Comment Mutation */
const HIDE_COMMENT = gql`
mutation hideCommunityPostComment($id: ID!) {
  hideCommunityPostComment(id: $id) {
    id
    isHidden
  }
}
`;

/* Approve Post Mutation */
export const APPROVE_POST = gql`
mutation approveCommunityPost($id: ID!) {
  approveCommunityPost(id: $id) {
    id
    isHidden
    flags {
      user {
        id
        nickname
      }
      category
    }
  }
}
`;

/* Approve Comment Mutation */
export const APPROVE_COMMENT = gql`
mutation approveCommunityPostComment($id: ID!) {
  approveCommunityPostComment(id: $id) {
    id
    isHidden
    flags {
      user {
        id
        nickname
      }
      category
    }
  }
}
`;

const styles = StyleSheet.create({
  tag: {
    backgroundColor: Colors.primary700,
    borderRadius: '4px',
    color: '#ffffff',
    fontSize: '14px',
    height: 'fit-content',
    padding: '6px 8px',
    width: 'fit-content',
  },
  statusTag: {
    borderRadius: '4px',
    color: '#ffffff',
    fontSize: '14px',
    height: 'fit-content',
    padding: '6px 8px',
    width: 'fit-content',
  },
  officialCommentTag: {
    backgroundColor: Colors.primary500,
    borderRadius: '4px',
    color: '#ffffff',
    fontSize: '14px',
    height: 'fit-content',
    left: '-20px',
    padding: '6px 8px',
    position: 'relative',
    top: '-14px',
    width: 'fit-content',
  },
  hiddenTag: {
    borderRadius: '4px',
    border: `1px solid ${Colors.neutral700}`,
    color: Colors.neutral700,
    fontSize: '14px',
    height: 'fit-content',
    padding: '2px 8px',
    width: 'fit-content',
  },
});

const officialCommentStyle: CSSProperties = {
  border: `3px solid ${Colors.primary500}`,
}

const gridStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  boxSizing: 'border-box',
  columnGap: '32px',
  flexGrow: 1,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    columnGap: '16px',
  },
  '@media (max-width: 767px)': {
    columnGap: '8px',
  },
};

const COMMENTS_PER_PAGE = 30;

export default function CommunityPost() {
  const navigate = useNavigate();
  const { state: authState } = useAuthState();
  const { state: agentState } = useAgentAuthState();
  const { addAlert } = useAlertState();
  const { id } = useParams<{ id: string }>();
  const [commentSort, setCommentSort] = useState<string>('OLDEST_FIRST');
  const [commentPage, setCommentPage] = useState<number>(0);
  const { data: postData, loading: postIsLoading, error: postError } = useQuery<GetCommunityPostResponse>(GET_POST, {
    variables: {
      id: id
    },
    onCompleted: (data) => {
      if (data.communityPost) {
        setStatus(data.communityPost.status?.id || null);
      }
    },
  });
  const { data: commentData, loading: commentsAreLoading, error: commentError, refetch: refetchComments } = useQuery<GetCommunityPostCommentsResponse>(GET_COMMENTS, {
    variables: {
      postId: id,
      page: commentPage,
      sort: commentSort,
    },
    fetchPolicy: 'network-only',
  });
  const [editPost] = useMutation<EditCommunityPostResponse>(EDIT_POST);
  const [deletePost] = useMutation<DeleteCommunityPostResponse>(DELETE_POST);
  const [pinPost] = useMutation(PIN_POST);
  const [featurePost] = useMutation(FEATURE_POST);
  const [lockPost] = useMutation(LOCK_POST);
  const [updateStatus, { loading: updateStatusIsLoading }] = useMutation(SET_STATUS);
  const [status, setStatus] = useState<string | null>(postData?.communityPost?.status?.id || null);
  const [isCreatingComment, setIsCreatingComment] = useState<boolean>(false);
  const [createComment, { loading: createCommentIsLoading }] = useMutation<CreateCommunityPostCommentResponse>(CREATE_COMMENT, {
    update: (_, { data }) => {
      if (!data?.createCommunityPostComment) {
        return;
      }

      if (commentData?.communityPostComments?.length === COMMENTS_PER_PAGE) {
        // If the current page is full, go to the next page because the new comment will be on that page
        setCommentPage(commentPage + 1);
      } else {
        refetchComments();
      }
    },
  });
  const [editComment] = useMutation(EDIT_COMMENT);
  const [deleteComment] = useMutation(DELETE_COMMENT);
  const [getPostImageUploadURL] = useLazyQuery<GetPostImageUploadURLResponse>(GET_POST_IMAGE_UPLOAD_URL, {
    fetchPolicy: 'network-only',
  });
  const [getCommentImageUploadURL] = useLazyQuery<GetPostCommentImageUploadURLResponse>(GET_COMMENT_IMAGE_UPLOAD_URL, {
    fetchPolicy: 'network-only',
  });
  const [createContentTag] = useMutation<CreateContentTagResponse>(CREATE_CONTENT_TAG, {
    update: (cache) => {
      cache.evict({
        fieldName: 'searchSupportContentTags',
      });
    },
  });
  const [addContentTag] = useMutation<AddContentTagResponse>(ADD_CONTENT_TAG, {
    update: (cache, { data }) => {
      if (!data?.addCommunityPostTag || !postData?.communityPost) {
        return;
      }

      cache.modify({
        id: cache.identify(postData.communityPost),
        fields: {
          tags(existingTagsRefs = [], { readField }) {
            return [...existingTagsRefs, cache.writeFragment({
              data: data.addCommunityPostTag,
              fragment: gql`
                fragment NewTag on SupportContentTag {
                  id
                  name
                }
              `,
            })].sort((a: any, b: any) => {
              const aName = readField('name', a)?.toString() || '';
              const bName = readField('name', b)?.toString() || '';

              return aName.localeCompare(bName);
            });
          }
        }
      });
    },
  });
  const [removeContentTag] = useMutation<RemoveContentTagResponse>(REMOVE_CONTENT_TAG, {
    update: (cache, { data }) => {
      if (!data?.removeCommunityPostTag || !postData?.communityPost) {
        return;
      }

      cache.modify({
        id: cache.identify(postData.communityPost),
        fields: {
          tags(existingTagsRefs, { readField }) {
            return existingTagsRefs.filter((tagRef: any) => {
              return readField('id', tagRef) !== data.removeCommunityPostTag?.id;
            });
          }
        }
      });
    },
  });
  const [searchTags] = useLazyQuery<SearchTagsResponse>(SEARCH_TAGS);
  const [hidePost] = useMutation(HIDE_POST);
  const [hideComment] = useMutation(HIDE_COMMENT);
  const [approvePost] = useMutation(APPROVE_POST);
  const [approveComment] = useMutation(APPROVE_COMMENT);

  /* Post Image Upload */
  const handlePostImageUpload = async (file: File): Promise<ImagePayload | null> => {
    // Get the signed URL to upload the image to cloud storage
    const { data } = await getPostImageUploadURL({
      variables: {
        fileMimeType: file.type,
        fileByteSize: file.size,
      },
    });

    if (!data?.getCommunityPostImageUploadURL) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error uploading image' type='error' id={alertId} />
      addAlert(alertId, alert);

      return null;
    }

    // Upload the image to cloud storage
    const response = await fetch(data.getCommunityPostImageUploadURL.uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (!response.ok) {
      return null;
    }

    // Return the URL
    return {
      src: data.getCommunityPostImageUploadURL.publicURL,
    };
  }

  /* Image Upload */
  const handleCommentImageUpload = async (file: File): Promise<ImagePayload | null> => {
    // Get the signed URL to upload the image to cloud storage
    const { data } = await getCommentImageUploadURL({
      variables: {
        fileMimeType: file.type,
        fileByteSize: file.size,
      },
    });

    if (!data?.getCommunityPostCommentImageUploadURL) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error uploading image' type='error' id={alertId} />
      addAlert(alertId, alert);

      return null;
    }

    // Upload the image to cloud storage
    const response = await fetch(data.getCommunityPostCommentImageUploadURL.uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (!response.ok) {
      return null;
    }

    // Return the URL
    return {
      src: data.getCommunityPostCommentImageUploadURL.publicURL,
    };
  }

  /* Edit Post */
  const handleEditPost = async (values: EditCommunityPostInput) => {
    const { errors } = await editPost({
      variables: {
        id: id,
        input: {
          title: values.title,
          body: values.body,
        }
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to update post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const editPostModal = (
    <FormModal title='Edit Post' onSubmit={handleEditPost} initialValues={{ title: '', body: '' }} style={{ maxWidth: 'min(800px, 100vw)', width: 'min(800px, 100vw)' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Title' name='title' />
        <TextEditor name='body' isEditMode onImageUpload={handlePostImageUpload} maxImageBytes={5 * MiB} buttonConfig={COMMUNITY_POST_EDITOR} editorStyle={{ maxHeight: '250px' }} />
      </View>
    </FormModal>
  );

  /* Delete Post */
  const handleDeletePost = async () => {
    const { errors } = await deletePost({
      variables: {
        id: id
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to delete post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }

    navigate('/community');
  }

  const deletePostModal = (
    <ConfirmModal title='Delete post?' confirmLabel='Delete' destructive onConfirm={handleDeletePost}>
      <StyledParagraph>This post will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  /* Post Moderation */
  const handlePinPost = async () => {
    const { errors } = await pinPost({
      variables: {
        id: id,
        isPinned: true
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to pin post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleUnpinPost = async () => {
    const { errors } = await pinPost({
      variables: {
        id: id,
        isPinned: false
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to unpin post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleFeaturePost = async () => {
    const { errors } = await featurePost({
      variables: {
        id: id,
        isFeatured: true
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to feature post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleUnfeaturePost = async () => {
    const { errors } = await featurePost({
      variables: {
        id: id,
        isFeatured: false
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to unfeature post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleLockPost = async () => {
    const { errors } = await lockPost({
      variables: {
        id: id,
        isLocked: true
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to lock post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleUnlockPost = async () => {
    const { errors } = await lockPost({
      variables: {
        id: id,
        isLocked: false
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to unlock post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleStatusChange = async () => {
    updateStatus({
      variables: {
        id: id,
        statusId: status,
      },
    }).then(({ errors }) => {
      if (errors) {
        const alertId = generateId();
        const alert = <StandardAlert title='Failed to change post status' description={errors[0].message} type='error' id={alertId} />
        addAlert(alertId, alert);
      }
    });
  }

  const handleHidePost = async () => {
    const { errors } = await hidePost({
      variables: {
        id: id
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to hide post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleApprovePost = async () => {
    const { errors } = await approvePost({
      variables: {
        id: id
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to approve post' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const approvePostModal = (
    <ConfirmModal title='Approve post?' confirmLabel='Approve' onConfirm={handleApprovePost}>
      <StyledParagraph>All flags will be removed and the post will become visible to all users.</StyledParagraph>
    </ConfirmModal>
  );

  /* Create Comment */
  const handleCreateComment = async (values: CreateCommentInput) => {
    const { errors } = await createComment({
      variables: {
        postId: id,
        body: values.createCommentBody,
        isOfficial: values.isOfficial,
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to create comment' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }

    createCommentForm.resetValues();
    setIsCreatingComment(false);
  }

  const createCommentForm = useForm<CreateCommentInput>({
    initialValues: {
      createCommentBody: '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
      isOfficial: false,
    },
    onSubmit: handleCreateComment,
  });

  /* Edit Comment */
  const handleEditComment = async (values: EditCommentInput) => {
    const { errors } = await editComment({
      variables: {
        id: values.id,
        body: values.body,
        isOfficial: values.isOfficial,
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to edit comment' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const editCommentModal = (
    <FormModal<EditCommentInput> title='Edit Comment' onSubmit={handleEditComment} initialValues={{ id: '', body: '', isOfficial: false }} style={{ maxWidth: 'min(800px, 100vw)', width: 'min(800px, 100vw)' }}>
      <View style={{ gap: '16px' }}>
        <TextEditor name='body' isEditMode buttonConfig={COMMUNITY_POST_EDITOR} onImageUpload={handleCommentImageUpload} maxImageBytes={5 * MiB} editorStyle={{ maxHeight: '250px' }} />
        {agentState.user?.isSupportAgent && <Checkbox label='Official comment' name='isOfficial' />}
      </View>
    </FormModal>
  );

  /* Delete Comment */
  const handleDeleteComment = async (id: string) => {
    const { errors } = await deleteComment({
      variables: {
        id: id
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to delete comment' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }

    if (commentData?.communityPostComments?.length === 1) {
      setCommentPage(commentPage - 1);
    } else {
      refetchComments();
    }
  }

  const deleteCommentModal = (
    <ConfirmModal title='Delete comment?' confirmLabel='Delete' destructive onConfirm={handleDeleteComment}>
      <StyledParagraph>This comment will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  /* Comment Moderation */
  const handleHideComment = async (id: string) => {
    if (!id) {
      return;
    }

    const { errors } = await hideComment({
      variables: {
        id: id,
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to hide comment' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleApproveComment = async (id: string) => {
    if (!id) {
      return;
    }

    const { errors } = await approveComment({
      variables: {
        id: id,
      }
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Failed to approve comment' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const approveCommentModal = (
    <ConfirmModal title='Approve comment?' confirmLabel='Approve' onConfirm={handleApproveComment}>
      <StyledParagraph>All flags will be removed and the comment will become visible to all users.</StyledParagraph>
    </ConfirmModal>
  );

  /* Tags */
  const handleTagCreate = async (name: string) => {
    const { data, errors } = await createContentTag({
      variables: {
        name: name,
      },
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error creating tag' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    } else {
      const { errors } = await addContentTag({
        variables: {
          postId: id,
          tagId: data?.createSupportContentTag?.id,
        },
      });

      if (errors) {
        const alertId = generateId();
        const alert = <StandardAlert title='Error adding tag' description={errors[0].message} type='error' id={alertId} />
        addAlert(alertId, alert);
      }
    }
  }

  const handleTagAdd = async (tag: ContentTag) => {
    const { errors } = await addContentTag({
      variables: {
        postId: id,
        tagId: tag.id,
      },
    });

    if (errors) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error adding tag' description={errors[0].message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }
  }

  const handleTagRemove = (tag: ContentTag) => {
    removeContentTag({
      variables: {
        postId: id,
        tagId: tag.id,
      },
    });
  }

  const handleTagSearch = async (name: string) => {
    const { data, error } = await searchTags({
      variables: {
        name: name
      },
    });

    if (error) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error searching tags' description={error.message} type='error' id={alertId} />
      addAlert(alertId, alert);
    }

    return data?.searchSupportContentTags || [];
  }

  if (postError) {
    if (postError.graphQLErrors[0]?.extensions.status === 404) {
      return (
        <StandardGrid>
          <NotFound />
        </StandardGrid>
      );
    } else if (postError.graphQLErrors[0]?.extensions.status === 403) {
      return (
        <StandardGrid>
          <NoPermission />
        </StandardGrid>
      );
    } else {
      return (
        <StandardGrid>
          <ErrorPage />
        </StandardGrid>
      );
    }
  }

  if (commentError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  if (postIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  return (
    <Grid style={gridStyle}>
      <HelpCenterHeader />

      <Cell lg={12} md={8} sm={4} style={{ padding: '32px 40px' }}>
        <BreadcrumbGroup>
          <Breadcrumb label='Help Center' />
          <Breadcrumb label='Community' to='/community' />
          <Breadcrumb label={postData?.communityPost?.topic.name || ''} to={`/community/topics/${postData?.communityPost?.topic.id}`} />
        </BreadcrumbGroup>
      </Cell>
      <Row style={{ padding: '0 40px' }}>
        <Cell lg={7} md={8} sm={4}>
          <View style={{ gap: '16px' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
              <StyledHeading tag='h4'>{postData?.communityPost?.title}</StyledHeading>
              {postData?.communityPost?.isFeatured && <span className={css(Typography.caption, styles.tag)}>Featured</span>}
              {postData?.communityPost?.isPinned && <span className={css(Typography.caption, styles.tag)}>Pinned</span>}
              {postData?.communityPost?.status && <span className={css(Typography.caption, styles.statusTag)} style={{ backgroundColor: postData.communityPost.status.color }}>{agentState.user?.isSupportAgent ? postData.communityPost.status.internalName : postData.communityPost.status.publicName}</span>}
            </View>

            <Card size='medium'>
              <View style={{ gap: '16px' }}>
                <View style={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '24px' }}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                      <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                      <Link href={`/community/users/${postData?.communityPost?.author.id}`} linkStyle={{ fontSize: '18px' }}>{postData?.communityPost?.author.nickname}</Link>
                      {postData?.communityPost?.author.nameBadge && <span className={css(Typography.caption, styles.tag, createStyle({ backgroundColor: postData.communityPost.author.nameBadge.hexColor, marginLeft: '8px' }))}>{postData.communityPost.author.nameBadge.title}</span>}
                    </View>

                    <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                      <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>Posted <TimeAgo date={postData?.communityPost?.created || ''} /></StyledParagraph>
                      {postData?.communityPost?.lastUpdated &&
                        <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>
                          {getUpdatedLabel(postData.communityPost.author, postData?.communityPost?.lastUpdated, postData?.communityPost?.lastUpdatedBy)}
                        </StyledParagraph>
                      }
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', gap: '8px' }}>
                    {postData?.communityPost?.isHidden &&
                      <span className={css(Typography.paragraph2, styles.hiddenTag)}>Pending approval</span>
                    }

                    {(postData?.communityPost?.author.id === authState.user?.id || agentState.user?.supportAgentPermissions?.canModerateCommunityContent || (agentState.user?.isSupportAgent && !postData?.communityPost?.isHidden)) &&
                      <ModalLauncher modal={editPostModal}>
                        {({ openModal: openEditModal }) => (
                          <ModalLauncher modal={deletePostModal}>
                            {({ openModal: openDeleteModal }) => (
                              <ModalLauncher modal={approvePostModal}>
                                {({ openModal: openApproveModal }) => (
                                  <ActionMenu alignment='right'>
                                    {(postData?.communityPost?.author.id === authState.user?.id || agentState.user?.supportAgentPermissions?.canModerateCommunityContent) &&
                                      <ActionItemSection>
                                        <ActionItem label='Edit' onClick={() => { openEditModal({ title: postData?.communityPost?.title, body: postData?.communityPost?.body }); }} />
                                        <ActionItem label='Delete' onClick={openDeleteModal} />
                                      </ActionItemSection>
                                    }

                                    {agentState.user?.isSupportAgent &&
                                      <ActionItemSection>
                                        {agentState.user.supportAgentPermissions?.canModerateCommunityContent &&
                                          <>
                                            {postData?.communityPost?.isFeatured ?
                                              <ActionItem label='Unfeature' onClick={handleUnfeaturePost} />
                                              :
                                              <ActionItem label='Feature' onClick={handleFeaturePost} />
                                            }

                                            {postData?.communityPost?.isPinned ?
                                              <ActionItem label='Unpin' onClick={handleUnpinPost} />
                                              :
                                              <ActionItem label='Pin' onClick={handlePinPost} />
                                            }

                                            {postData?.communityPost?.isLocked ?
                                              <ActionItem label='Unlock' onClick={handleUnlockPost} />
                                              :
                                              <ActionItem label='Lock' onClick={handleLockPost} />
                                            }
                                          </>
                                        }

                                        {(!postData?.communityPost?.isHidden && !postData?.communityPost?.author.isSupportAgent) &&
                                          <ActionItem label='Hide for moderation' onClick={handleHidePost} />
                                        }

                                        {(postData?.communityPost?.isHidden || (postData?.communityPost?.flags?.length || 0) > 0) &&
                                          <ActionItem label='Approve' onClick={openApproveModal} />
                                        }
                                      </ActionItemSection>
                                    }
                                  </ActionMenu>
                                )}
                              </ModalLauncher>
                            )}
                          </ModalLauncher>
                        )}
                      </ModalLauncher>
                    }
                  </View>
                </View>

                <TextEditor name='post-view' value={postData?.communityPost?.body} isEditMode={false} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} />

                {(!postData?.communityPost?.author.isSupportAgent && postData?.communityPost?.author.id !== authState.user?.id && authState.user) &&
                  <View style={{ alignItems: 'baseline', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    {agentState.user?.supportAgentPermissions?.canModerateCommunityContent ?
                      <>
                        <Tooltip strategy='fixed' content={
                          <View>
                            {!postData?.communityPost?.flags?.length && <StyledParagraph style={{ fontSize: '12px' }}>No flags on this post</StyledParagraph>}
                            {postData?.communityPost?.flags?.map((flag) => (
                              <StyledParagraph style={{ fontSize: '12px' }} key={flag.user.id}>{flag.category} - {`${flag.user.nickname} (${flag.user.id})`}</StyledParagraph>
                            ))}
                          </View>
                        }>
                          <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{`Flags (${postData?.communityPost?.flags?.length || 0})`}</StyledParagraph>
                        </Tooltip>
                      </>
                      :
                      (postData?.communityPost?.isFlaggedByUser ?
                        <View style={{ alignItems: 'center', cursor: 'default', flexDirection: 'row', gap: '4px' }}>
                          <Icon icon={Icons.FlagSolid} size='small' style={{ color: Colors.error500, fill: Colors.error500 }} key='flagged-post-icon' />
                          <StyledParagraph style={{ color: Colors.error500, fontSize: '12px' }}>Flagged</StyledParagraph>
                        </View>
                        :
                        <ModalLauncher modal={<FlagPostModal postID={postData?.communityPost?.id || ''} postType='post' />}>
                          {({ openModal }) => (
                            <View onClick={openModal} style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '4px' }}>
                              <Icon icon={Icons.Flag} size='small' style={{ color: Colors.neutral700 }} />
                              <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>Flag</StyledParagraph>
                            </View>
                          )}
                        </ModalLauncher>
                      )
                    }
                  </View>
                }
              </View>
            </Card>
          </View>
          <View style={{ gap: '32px', marginTop: '128px', marginBottom: '128px' }}>
            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
              <StyledHeading tag='h5'>Comments</StyledHeading>

              <SingleSelect label='Sort by' name='commentSort' value={commentSort} onChange={(_, value) => { setCommentSort(value || 'OLDEST_FIRST'); }} style={{ maxWidth: '150px' }}>
                <Choice label='Date' value='OLDEST_FIRST' />
              </SingleSelect>
            </View>

            <View style={{ borderBottom: `1px solid ${Colors.neutral300}`, paddingBottom: '32px' }}>
              {authState.user ?
                (postData?.communityPost?.isLocked ?
                  <StyledParagraph>This post is closed for comments.</StyledParagraph>
                  :
                  <View style={{ gap: '16px', alignItems: 'flex-end', width: '100%' }}>
                    <TextEditor name='createCommentBody' value={createCommentForm.values.createCommentBody} isEditMode={isCreatingComment} placeholder='Post a comment...' buttonConfig={COMMUNITY_POST_EDITOR} onImageUpload={handleCommentImageUpload} maxImageBytes={5 * MiB} containerStyle={{ width: '100%' }} editorStyle={isCreatingComment ? { minHeight: '200px', height: 'fit-content', maxHeight: '600px' } : { border: `1px solid ${Colors.neutral300}`, borderRadius: '4px', cursor: 'text', minHeight: '100px' }} onClick={() => { setIsCreatingComment(true); }} onChange={createCommentForm.handleChange} />
                    {isCreatingComment &&
                      <View style={{ gap: '16px', width: '100%' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                          {agentState.user?.isSupportAgent &&
                            <Checkbox label='Official comment' checked={createCommentForm.values.isOfficial} name='isOfficial' onChange={createCommentForm.handleChange} />
                          }

                          <View style={{ flexDirection: 'row', gap: '16px' }}>
                            <Button label='Cancel' variant='tertiary' role='button' action={() => { createCommentForm.resetValues(); setIsCreatingComment(false); }} />
                            <Button label='Post Comment' variant='primary' role='button' action={createCommentForm.handleSubmit} disabled={createCommentForm.values.createCommentBody === '' || createCommentForm.values.createCommentBody === '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'} loading={createCommentIsLoading} />
                          </View>
                        </View>

                        <InfoPanel type='info'>
                          <StyledParagraph>All posts must adhere to our <Link href='/help-center/articles/69f65578-a458-4e7e-ab1d-8b8c0c4711dc'>Community Guidelines</Link>.</StyledParagraph>
                        </InfoPanel>
                      </View>
                    }
                  </View>
                )
                :
                <StyledParagraph>Sign in to post a comment.</StyledParagraph>
              }
            </View>

            {commentsAreLoading ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='medium' />
              </View>
              :
              <View style={{ gap: '40px' }}>
                {commentData?.communityPostComments?.length === 0 &&
                  <StyledParagraph style={{ textAlign: 'center', width: '100%' }}>This post has no comments.</StyledParagraph>
                }

                {commentData?.communityPostComments?.map((comment) => (
                  <Card size='medium' key={comment.id} style={{ ...(comment.isOfficial ? officialCommentStyle : {}) }}>
                    {comment.isOfficial && <span className={css(Typography.caption, styles.officialCommentTag)}>Official Comment</span>}

                    <View style={{ gap: '16px', ...(comment.isOfficial ? { marginTop: '24px' } : {}) }}>
                      <View style={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '24px' }}>
                          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                            <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                            <Link href={`/community/users/${comment.author.id}`} linkStyle={{ fontSize: '18px' }}>{comment.author.nickname}</Link>
                            {comment.author.nameBadge && <span className={css(Typography.caption, styles.tag, createStyle({ backgroundColor: comment.author.nameBadge.hexColor, marginLeft: '8px' }))}>{comment.author.nameBadge.title}</span>}
                          </View>

                          <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                            <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>Posted <TimeAgo date={comment.created} /></StyledParagraph>
                            {comment.lastUpdated &&
                              <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>
                                {getUpdatedLabel(comment.author, comment.lastUpdated, comment.lastUpdatedBy)}
                              </StyledParagraph>
                            }
                          </View>
                        </View>

                        <View style={{ flexDirection: 'row', gap: '8px' }}>
                          {comment.isHidden &&
                            <span className={css(Typography.paragraph2, styles.hiddenTag)}>Pending approval</span>
                          }

                          {(comment.author.id === authState.user?.id || agentState.user?.supportAgentPermissions?.canModerateCommunityContent || (agentState.user?.isSupportAgent && !comment.isHidden)) &&
                            <ModalLauncher modal={editCommentModal}>
                              {({ openModal: openEditModal }) => (
                                <ModalLauncher modal={deleteCommentModal}>
                                  {({ openModal: openDeleteModal }) => (
                                    <ModalLauncher modal={approveCommentModal}>
                                      {({ openModal: openApproveModal }) => (
                                        <ActionMenu alignment='right'>
                                          {(comment.author.id === authState.user?.id || agentState.user?.supportAgentPermissions?.canModerateCommunityContent) &&
                                            <ActionItemSection>
                                              <ActionItem label='Edit' onClick={() => { openEditModal({ id: comment.id, body: comment.body, isOfficial: comment.isOfficial }); }} />
                                              <ActionItem label='Delete' onClick={openDeleteModal} data={comment.id} />
                                            </ActionItemSection>
                                          }
                                          {(agentState.user?.supportAgentPermissions?.canModerateCommunityContent || (agentState.user?.isSupportAgent && !comment.isHidden)) &&
                                            <>
                                              <ActionItemSection>
                                                {(!comment.isHidden && !comment.author.isSupportAgent) &&
                                                  <ActionItem label='Hide for moderation' onClick={handleHideComment} data={comment.id} />
                                                }
                                                {(comment.isHidden || (comment.flags?.length || 0) > 0) &&
                                                  <ActionItem label='Approve' onClick={openApproveModal} data={comment.id} />
                                                }
                                              </ActionItemSection>
                                            </>
                                          }
                                        </ActionMenu>
                                      )}
                                    </ModalLauncher>
                                  )}
                                </ModalLauncher>
                              )}
                            </ModalLauncher>
                          }
                        </View>
                      </View>

                      <TextEditor name={`comment-view-${comment.id}`} value={comment.body} isEditMode={false} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} />

                      {(!comment.author.isSupportAgent && comment.author.id !== authState.user?.id && authState.user) &&
                        <View style={{ alignItems: 'baseline', flexDirection: 'row', justifyContent: 'flex-end' }}>
                          {agentState.user?.supportAgentPermissions?.canModerateCommunityContent ?
                            <>
                              <Tooltip strategy='fixed' content={
                                <View>
                                  {!comment.flags?.length && <StyledParagraph style={{ fontSize: '12px' }}>No flags on this post</StyledParagraph>}
                                  {comment.flags?.map((flag) => (
                                    <StyledParagraph style={{ fontSize: '12px' }} key={flag.user.id}>{flag.category} - {`${flag.user.nickname} (${flag.user.id})`}</StyledParagraph>
                                  ))}
                                </View>
                              }>
                                <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{`Flags (${comment.flags?.length || 0})`}</StyledParagraph>
                              </Tooltip>
                            </>
                            :
                            (comment.isFlaggedByUser ?
                              <View style={{ alignItems: 'center', cursor: 'default', flexDirection: 'row', gap: '4px' }}>
                                <Icon icon={Icons.FlagSolid} size='small' style={{ color: Colors.error500, fill: Colors.error500 }} key='flagged-comment-icon' />
                                <StyledParagraph style={{ color: Colors.error500, fontSize: '12px' }}>Flagged</StyledParagraph>
                              </View>
                              :
                              <ModalLauncher modal={<FlagPostModal postID={comment.id || ''} postType='comment' />}>
                                {({ openModal }) => (
                                  <View onClick={openModal} style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '4px' }}>
                                    <Icon icon={Icons.Flag} size='small' style={{ color: Colors.neutral700 }} />
                                    <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>Flag</StyledParagraph>
                                  </View>
                                )}
                              </ModalLauncher>
                            )
                          }
                        </View>
                      }
                    </View>
                  </Card>
                ))}

                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <PageButtons currentPage={commentPage} onPageChange={setCommentPage} numPages={commentData?.communityPostComments?.length === COMMENTS_PER_PAGE ? commentPage + 2 : commentPage + 1} />
                </View>
              </View>
            }
          </View>
        </Cell>
        <Cell lg={1} md={0} sm={0}>

        </Cell>
        <Cell lg={3} md={8} sm={4} style={{ paddingBottom: '32px' }}>
          <View style={{ gap: '128px' }}>
            {(agentState.user?.supportAgentPermissions?.canSetPostStatuses || agentState.user?.supportAgentPermissions?.canModerateCommunityContent) && <View style={{ gap: '40px' }}>
              {agentState.user?.supportAgentPermissions?.canSetPostStatuses &&
                <View style={{ gap: '32px' }}>
                  <SingleSelect label='Status' name='status' value={status} onChange={(_: string, value: string | null) => { setStatus(value); }}>
                    <Choice label='None' value={null} />
                    {postData?.communityPost?.topic.postType.statuses?.map((status) => (
                      <Choice label={`${status.internalName} (${status.publicName})`} value={status.id} key={status.id} />
                    ))}
                  </SingleSelect>
                  <Button label='Save changes' variant='primary' role='button' action={handleStatusChange} isFullWidth disabled={(postData?.communityPost?.status?.id || null) === status} loading={updateStatusIsLoading} />
                </View>
              }

              {agentState.user?.supportAgentPermissions?.canModerateCommunityContent &&
                <TagSelector name='postTags' label='Content Tags' description='Use tags to improve post suggestions' selectedTags={postData?.communityPost?.tags || []} onTagSearch={handleTagSearch} onTagSelect={handleTagAdd} onTagRemove={handleTagRemove} onTagCreate={handleTagCreate} style={{ width: '100%' }} />
              }
            </View>}

            <View style={{ gap: '64px' }}>
              {(postData?.communityPost?.relatedArticles?.length || 0) > 0 &&
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Related articles</StyledHeading>

                  <View style={{ gap: '12px' }}>
                    {postData?.communityPost?.relatedArticles?.map((article) => (
                      <Link href={`/help-center/articles/${article.id}`} key={article.id}>{article.title}</Link>
                    ))}
                  </View>
                </View>
              }

              {(postData?.communityPost?.relatedPosts?.length || 0) > 0 &&
                <View style={{ gap: '16px' }}>
                  <StyledHeading tag='h6'>Related posts</StyledHeading>

                  <View style={{ gap: '12px' }}>
                    {postData?.communityPost?.relatedPosts?.map((post) => (
                      <Link href={`/community/posts/${post.id}`} key={post.id}>{post.title}</Link>
                    ))}
                  </View>
                </View>
              }
            </View>

            <Card size='medium' style={{ backgroundColor: Colors.primary50 }}>
              <View style={{ gap: '16px' }}>
                <StyledHeading tag='h6' style={{ fontSize: '18px' }}>Didn't find what you're looking for?</StyledHeading>
                <Button label='Create a post' variant='primary' role='link' href='/community/posts/create' isFullWidth />
              </View>
            </Card>
          </View>
        </Cell>
      </Row>
    </Grid>
  );
}

function getUpdatedLabel(author: PostAuthor, updatedTimestamp: string | null, updatedBy: PostAuthor | null) {
  if (!updatedTimestamp) {
    return null;
  }

  if (updatedBy && updatedBy.id !== author.id) {
    return (
      <>
        (Updated <TimeAgo date={updatedTimestamp} /> by <Link href={`/community/users/${updatedBy.id}`} linkStyle={{ color: Colors.neutral700, fontSize: '14px' }}>{updatedBy.nickname}</Link>)
      </>
    );
  } else {
    return (
      <>
        (Updated <TimeAgo date={updatedTimestamp} />)
      </>
    );
  }
}