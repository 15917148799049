export enum ArticleVisibility {
  PUBLIC = 'PUBLIC',
  LOGGED_IN_USERS = 'LOGGED_IN_USERS',
  AGENTS_ONLY = 'AGENTS_ONLY',
}

export function parseArticleVisibility(visibility: ArticleVisibility): string {
  switch (visibility) {
    case ArticleVisibility.PUBLIC:
      return 'Public';
    case ArticleVisibility.LOGGED_IN_USERS:
      return 'Logged in users';
    case ArticleVisibility.AGENTS_ONLY:
      return 'Agents only';
    default:
      return '';
  }
}