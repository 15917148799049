import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, Card, Cell, Colors, ErrorPage, Grid, Icon, Icons, Link, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, Typography, View } from "@barscience/global-components";
import { css, CSSProperties, StyleSheet } from "aphrodite";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { Link as ReactLink, } from "react-router-dom";
import ReactTimeago from "react-timeago";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import HelpCenterHeader from "../helpCenter/HelpCenterHeader";

/* Get All Categories */
export const GET_ALL_CATEGORIES = gql`
query getAllCommunityCategories {
  communityCategories {
    id
    name
    description
    icon
    topics {
      id
      name
      restrictPosting
    }
  }
}
`;

export type GetAllCategoriesResponse = {
  communityCategories: Category[] | null;
}

type Category = {
  id: string;
  name: string;
  description: string | null;
  icon: string;
  topics: Topic[];
}

type Topic = {
  id: string;
  name: string;
  restrictPosting: boolean;
}

/* Get Featured Posts */
const GET_FEATURED_POSTS = gql`
query getFeaturedCommunityPosts {
  featuredCommunityPosts {
    id
    title
    created
    status {
      id
      publicName
      internalName
      color
    }
    author {
      id
      nickname
    }
    topic {
      id
      name
    }
  }
}
`;

type GetFeaturedPostsResponse = {
  featuredCommunityPosts: FeaturedPost[] | null;
}

type FeaturedPost = {
  id: string;
  title: string;
  created: string;
  status: {
    id: string;
    publicName: string;
    internalName: string;
    color: string;
  } | null;
  author: {
    id: string;
    nickname: string;
  } | null;
  topic: {
    id: string;
    name: string;
  };
}

const styles = StyleSheet.create({
  cardLink: {
    color: 'inherit',
    textDecoration: 'none',
    width: '100%',
    ':hover': {
      textDecoration: 'none'
    },
  },
  statusTag: {
    borderRadius: '4px',
    color: '#ffffff',
    fontSize: '14px',
    height: 'fit-content',
    padding: '6px 8px',
    width: 'fit-content',
  },
});

const gridStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  boxSizing: 'border-box',
  columnGap: '32px',
  flexGrow: 1,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    columnGap: '16px',
  },
  '@media (max-width: 767px)': {
    columnGap: '8px',
  },
};

export default function CommunityHome() {
  const { state: agentState } = useAgentAuthState();
  const { data: categoriesData, loading: categoriesAreLoading, error: categoriesError } = useQuery<GetAllCategoriesResponse>(GET_ALL_CATEGORIES);
  const { data: featuredPostsData, loading: featuredPostsAreLoading, error: featuredPostsError } = useQuery<GetFeaturedPostsResponse>(GET_FEATURED_POSTS);

  if (categoriesError || featuredPostsError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <Grid style={gridStyle}>
      <HelpCenterHeader />

      <Cell lg={12} md={8} sm={4} style={{ padding: '32px 40px' }}>
        <View style={{ gap: '8px' }}>
          <StyledHeading tag='h3'>Welcome to the community!</StyledHeading>
          <StyledParagraph style={{ color: Colors.neutral700 }}>Browse community posts, ask questions, and share product feedback and best practices.</StyledParagraph>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ marginTop: '16px', padding: '0 40px' }}>
        {categoriesAreLoading ?
          <AtomSpinner size='medium' />
          :
          <>
            <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 1000: 2, 1500: 3 }}>
              <Masonry gutter='16px'>
                {categoriesData?.communityCategories?.map((category) => {
                  return (
                    <Card size='medium' key={category.id} style={{ height: 'fit-content', padding: '24px', display: 'grid' }}>
                      <View style={{ gap: '24px' }}>
                        <View style={{ gap: '12px' }}>
                          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                            {category.icon && <Icon icon={Icons.Community} customFaIcon={category.icon} size='medium' />}
                            <StyledHeading tag='h6'>{category.name}</StyledHeading>
                          </View>
                          {category.description && <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic' }}>{category.description}</StyledParagraph>}
                        </View>
                        <View style={{ gap: '8px' }}>
                          {category.topics.map((topic) => (
                            <Link href={`/community/topics/${topic.id}`} key={topic.id}>{topic.name}</Link>
                          ))}
                        </View>
                      </View>
                    </Card>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </>
        }
      </Cell>
      <Cell lg={8} md={8} sm={4} style={{ marginTop: '64px', padding: '32px 40px' }}>
        <View style={{ gap: '16px' }}>
          <StyledHeading tag='h5'>Featured Posts</StyledHeading>

          {featuredPostsAreLoading ?
            <AtomSpinner size='medium' />
            :
            <View style={{ gap: '16px' }}>
              {featuredPostsData?.featuredCommunityPosts?.map((post) => (
                <ReactLink to={`/community/posts/${post.id}`} key={post.id} className={css(styles.cardLink)}>
                  <Card size='medium' style={{ ':hover': { backgroundColor: Colors.neutral100 }, ':active': { backgroundColor: Colors.neutral200 } }}>
                    <View style={{ gap: '16px' }}>
                      <View style={{ gap: '4px' }}>
                        <View style={{ flexDirection: 'row', gap: '16px' }}>
                          <StyledParagraph bold>{post.title}</StyledParagraph>
                          {post.status && <span className={css(Typography.caption, styles.statusTag)} style={{ backgroundColor: post.status.color }}>{agentState.user?.isSupportAgent ? post.status.internalName : post.status.publicName}</span>}
                        </View>

                        <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>Posted in {post.topic.name}</StyledParagraph>
                      </View>

                      <View style={{ flexDirection: 'row', gap: '8px' }}>
                        <StyledCaption>{post.author?.nickname}</StyledCaption>
                        <StyledCaption> - </StyledCaption>
                        <StyledCaption><ReactTimeago date={post.created} /></StyledCaption>
                      </View>
                    </View>
                  </Card>
                </ReactLink>
              ))}
            </View>
          }
        </View>
      </Cell>
    </Grid>
  );
}