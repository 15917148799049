import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Cell, Choice, Colors, ConfirmModal, ErrorPage, FormModal, Icon, Icons, InfoModal, Link, ModalLauncher, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { TopicVisibility, getTopicVisibilityLabel } from "../../../util/types/posts";

/* Get All Categories and Topics Query */
const GET_ALL_CATEGORIES_AND_TOPICS = gql`
query getAllCommunityCategoriesForAdmins {
  communityCategories {
    id
    name
    description
    icon
    topics {
      id
      name
      description
      postType {
        id
        name
      }
      restrictPosting
      visibility
    }
  }
}
`;

type GetAllCategoriesAndTopicsResponse = {
  communityCategories: Category[] | null;
}

type Category = {
  id: string;
  name: string;
  description: string | null;
  icon: string | null;
  topics: Topic[];
}

type Topic = {
  id: string;
  name: string;
  description: string | null;
  postType: {
    id: string;
    name: string;
  };
  restrictPosting: boolean;
  visibility: TopicVisibility;
}

/* Create Category Mutation */
const CREATE_CATEGORY = gql`
mutation createCommunityCategory($input: CreateCommunityCategoryInput!) {
  createCommunityCategory(input: $input) {
    id
    name
    description
    icon
    topics {
      id
      name
      description
      restrictPosting
      visibility
      postType {
        id
        name
      }
    }
  }
}
`;

type CreateCategoryResponse = {
  createCommunityCategory: Category | null;
}

type CreateCategoryInput = {
  name: string;
  description: string;
  icon: string;
}

/* Edit Category Mutation */
const EDIT_CATEGORY = gql`
mutation editCommunityCategory($id: ID!, $input: EditCommunityCategoryInput!) {
  editCommunityCategory(id: $id, input: $input) {
    id
    name
    description
    icon
    topics {
      id
      name
      description
      restrictPosting
      visibility
      postType {
        id
        name
      }
    }
  }
}
`;

type EditCategoryResponse = {
  editCommunityCategory: Category | null;
}

type EditCategoryInput = {
  id: string;
  name: string;
  description: string;
  icon: string;
}

/* Delete Category Mutation */
const DELETE_CATEGORY = gql`
mutation deleteCommunityCategory($id: ID!) {
  deleteCommunityCategory(id: $id) {
    id
  }
}
`;

type DeleteCategoryResponse = {
  deleteCommunityCategory: {
    id: string;
  } | null;
}

/* Edit Category Order Mutation */
const EDIT_CATEGORY_ORDER = gql`
mutation editCommunityCategoryOrder($order: [ID!]!) {
  success: editCommunityCategoryOrder(order: $order)
}
`;

type EditCategoryOrderResponse = {
  success: boolean;
}

/* Get All Post Types Query */
const GET_ALL_POST_TYPES = gql`
query getCommunityPostTypesSummary {
  communityPostTypes {
    id
    name
  }
}
`;

type GetAllPostTypesResponse = {
  communityPostTypes: {
    id: string;
    name: string;
  }[] | null;
}

/* Create Topic Mutation */
const CREATE_TOPIC = gql`
mutation createCommunityTopic($input: CreateCommunityTopicInput!) {
  createCommunityTopic(input: $input) {
    id
    name
    description
    visibility
    restrictPosting
    postType {
      id
      name
    }
  }
}
`;

type CreateTopicResponse = {
  createCommunityTopic: Topic | null;
}

type CreateTopicInput = {
  categoryId: string | null;
  name: string;
  description: string;
  visibility: string | null;
  restrictPosting: string | null;
  postTypeId: string | null;
}

/* Edit Topic Mutation */
const EDIT_TOPIC = gql`
mutation editCommunityTopic($id: ID!, $input: EditCommunityTopicInput!) {
  editCommunityTopic(id: $id, input: $input) {
    id
    name
    description
    visibility
    restrictPosting
    postType {
      id
      name
    }
  }
}
`;

type EditTopicResponse = {
  editCommunityTopic: Topic | null;
}

type EditTopicInput = {
  id: string;
  name: string;
  description: string;
  visibility: string;
  restrictPosting: string;
  categoryId: string;
}

/* Delete Topic Mutation */
const DELETE_TOPIC = gql`
mutation deleteCommunityTopic($id: ID!) {
  deleteCommunityTopic(id: $id) {
    id
  }
}
`;

type DeleteTopicResponse = {
  deleteCommunityTopic: {
    id: string;
  } | null;
}

/* Edit Topic Order Mutation */
const EDIT_TOPIC_ORDER = gql`
mutation editCommunityTopicOrder($categoryId: ID!, $order: [ID!]!) {
  success: editCommunityTopicOrder(categoryId: $categoryId, order: $order)
}
`;

type EditTopicOrderResponse = {
  success: boolean;
}

/* Styles */
const styles = StyleSheet.create({
  categoryCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  draggableTopic: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '8px 16px',
    ':hover': {
      backgroundColor: Colors.neutral100,
    },
    ':active': {
      backgroundColor: Colors.neutral300,
    },
  },
  draggableContainer: {
    padding: '8px 0px',
  },
});

export default function CategoriesAndTopics() {
  const { addAlert } = useAlertState();
  const { data: categoriesData, loading: categoriesAreLoading, error: categoriesError } = useQuery<GetAllCategoriesAndTopicsResponse>(GET_ALL_CATEGORIES_AND_TOPICS, {
    onCompleted: (data) => {
      if (data?.communityCategories) {
        setCategoryOrder(data.communityCategories.map((category) => category.id));
      }
    },
  });
  const [createCategory] = useMutation<CreateCategoryResponse>(CREATE_CATEGORY, {
    update(cache, { data }) {
      if (!data?.createCommunityCategory) {
        return;
      }

      // Add the new category to the list of categories
      cache.modify({
        fields: {
          communityCategories(existingCategories = []) {
            const newCategoryRef = cache.writeFragment({
              data: data.createCommunityCategory,
              fragment: gql`
                fragment NewCategory on CommunityCategory {
                  id
                  name
                  description
                  icon
                  topics {
                    id
                    name
                    description
                    postType {
                      id
                      name
                    }
                    restrictPosting
                    visibility
                  }
                }
              `
            });

            return [...existingCategories, newCategoryRef];
          }
        },
      });

      setCategoryOrder([...categoryOrder, data.createCommunityCategory.id]);
    },
  });
  const [editCategoryOrder, { loading: editCategoryOrderIsLoading }] = useMutation<EditCategoryOrderResponse>(EDIT_CATEGORY_ORDER);
  const [isEditingCategoryOrder, setIsEditingCategoryOrder] = useState<boolean>(false);
  const [categoryOrder, setCategoryOrder] = useState<string[]>(categoriesData?.communityCategories?.map((category) => category.id) || []);

  /* Create Category */
  const handleCreateCategory = async (values: CreateCategoryInput) => {
    const { errors } = await createCategory({
      variables: {
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          icon: values.icon ? values.icon : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating category' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Category created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createCategoryModal = (
    <FormModal<CreateCategoryInput> title='Create Category' submitLabel='Create' initialValues={{ name: '', description: '', icon: '' }} onSubmit={handleCreateCategory}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' required />
        <TextField label='Description' name='description' />
        <TextField label='Icon' description='The Font Awesome ID of the icon' name='icon' />
      </View>
    </FormModal>
  );

  /* Edit Category Order */
  const handleDragEnd = async (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const newCategoryOrder = Array.from(categoryOrder);
    newCategoryOrder.splice(source.index, 1);
    newCategoryOrder.splice(destination.index, 0, categoryOrder[source.index]);

    setCategoryOrder(newCategoryOrder);
  }

  const handleSaveOrder = async () => {
    const { errors } = await editCategoryOrder({
      variables: {
        order: categoryOrder,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error saving category order' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Category order saved' type='success' id={id} />
      addAlert(id, alert);
    }

    setIsEditingCategoryOrder(false);
  }

  if (categoriesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/agent/settings' />
          <Breadcrumb label='Categories and Topics' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h4'>Categories and Topics</StyledHeading>

          <View style={{ flexDirection: 'row', gap: '32px' }}>
            {isEditingCategoryOrder ?
              <Button label='Save Order' variant='tertiary' role='button' loading={editCategoryOrderIsLoading} action={handleSaveOrder} />
              :
              <Button label='Edit Category Order' variant='tertiary' role='button' action={() => { setIsEditingCategoryOrder(true); }} />
            }

            <ModalLauncher modal={createCategoryModal}>
              {({ openModal }) => (
                <Button label='Create Category' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {categoriesAreLoading ?
          <AtomSpinner />
          :
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='community-categories'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={css(styles.categoryCardContainer)} >
                  {categoryOrder.map((categoryId, index) => {
                    const category = categoriesData?.communityCategories?.find((category) => category.id === categoryId);
                    if (!category) {
                      return null;
                    }

                    return (
                      <Draggable draggableId={category.id} isDragDisabled={!isEditingCategoryOrder} index={index} key={category.id}>
                        {(provided) => (
                          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                            <CategoryCard category={category} isEditingCategoryOrder={isEditingCategoryOrder} />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        }
      </Cell>
    </StandardGrid>
  );
}

type CategoryCardProps = {
  category: Category;
  isEditingCategoryOrder: boolean;
}

function CategoryCard(props: CategoryCardProps) {
  const { category } = props;
  const { addAlert } = useAlertState();
  const { data: categoriesData, loading: categoriesAreLoading } = useQuery<GetAllCategoriesAndTopicsResponse>(GET_ALL_CATEGORIES_AND_TOPICS, {
    fetchPolicy: 'cache-first',
  });
  const { data: postTypeData, loading: postTypesAreLoading } = useQuery<GetAllPostTypesResponse>(GET_ALL_POST_TYPES);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditingOrder, setIsEditingOrder] = useState<boolean>(false);
  const [topicOrder, setTopicOrder] = useState<string[]>(category.topics.map((topic) => topic.id));
  const [editCategory] = useMutation<EditCategoryResponse>(EDIT_CATEGORY);
  const [deleteCategory] = useMutation<DeleteCategoryResponse>(DELETE_CATEGORY, {
    update(cache, { data }) {
      if (!data?.deleteCommunityCategory) {
        return;
      }

      // Remove the deleted category from the list of categories
      cache.modify({
        fields: {
          communityCategories(existingCategories = [], { readField }) {
            return existingCategories.filter((categoryRef: any) => {
              return readField('id', categoryRef) !== data.deleteCommunityCategory?.id;
            });
          }
        },
      });
    },

  });
  const [createTopic] = useMutation<CreateTopicResponse>(CREATE_TOPIC, {
    update(cache, { data }, { variables }) {
      if (!data?.createCommunityTopic || !variables?.input?.categoryId) {
        return;
      }

      // Add the new topic to the list of topics for the category it was created in
      cache.modify({
        id: cache.identify({
          __typename: 'CommunityCategory',
          id: variables.input.categoryId,
        }),
        fields: {
          topics(existingTopics = []) {
            const newTopicRef = cache.writeFragment({
              data: data.createCommunityTopic,
              fragment: gql`
                fragment NewTopic on CommunityTopic {
                  id
                  name
                  description
                  visibility
                  restrictPosting
                  postType {
                    id
                    name
                  }
                }
              `
            });

            return [...existingTopics, newTopicRef];
          }
        },
      });
    },

  });
  const [editTopic] = useMutation<EditTopicResponse>(EDIT_TOPIC, {
    update(cache, { data }, { variables }) {
      if (!data || !variables?.id || !variables.input.categoryId || !categoriesData?.communityCategories) {
        return;
      }

      // Find the previous category the topic was in
      const previousCategory = categoriesData.communityCategories.find((category) => category.topics.some((topic) => topic.id === variables.id));
      if (!previousCategory) {
        return;
      }

      if (previousCategory.id !== variables.input.categoryId) {
        // If the topic was moved to a different category, remove it from the previous category
        cache.modify({
          id: cache.identify({
            __typename: 'CommunityCategory',
            id: previousCategory.id,
          }),
          fields: {
            topics(existingTopics = [], { readField }) {
              return existingTopics.filter((topicRef: any) => {
                return readField('id', topicRef) !== variables.id;
              });
            }
          },
        });

        // Add the topic to the new category
        cache.modify({
          id: cache.identify({
            __typename: 'CommunityCategory',
            id: variables.input.categoryId,
          }),
          fields: {
            topics(existingTopics = []) {
              const newTopicRef = cache.writeFragment({
                data: data.editCommunityTopic,
                fragment: gql`
                  fragment NewTopic on CommunityTopic {
                    id
                    name
                    description
                    visibility
                    restrictPosting
                    postType {
                      id
                      name
                    }
                  }
                `
              });

              return [...existingTopics, newTopicRef];
            }
          },
        });
      }
    },
  });
  const [deleteTopic] = useMutation<DeleteTopicResponse>(DELETE_TOPIC, {
    update(cache, { data }) {
      if (!data?.deleteCommunityTopic?.id) {
        return;
      }

      // Find the category the topic is in
      const category = categoriesData?.communityCategories?.find((category) => category.topics.some((topic) => topic.id === data.deleteCommunityTopic?.id));
      if (!category) {
        return;
      }

      // Remove the deleted topic from the category
      cache.modify({
        id: cache.identify({
          __typename: 'CommunityCategory',
          id: category.id,
        }),
        fields: {
          topics(existingTopics = [], { readField }) {
            return existingTopics.filter((topicRef: any) => {
              return readField('id', topicRef) !== data.deleteCommunityTopic?.id;
            });
          }
        },
      });
    },
  });
  const [editTopicOrder, { loading: editTopicOrderIsLoading }] = useMutation<EditTopicOrderResponse>(EDIT_TOPIC_ORDER, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables?.categoryId || !categoriesData?.communityCategories) {
        return;
      }

      // Find the category the topics are in
      const category = categoriesData.communityCategories.find((category) => category.id === variables.categoryId);
      if (!category) {
        return;
      }

      // Update the order of the topics in the category
      cache.modify({
        id: cache.identify({
          __typename: 'CommunityCategory',
          id: category.id,
        }),
        fields: {
          topics(existingTopics = [], { readField }) {
            const newTopics = [];

            for (const topicId of variables.order) {
              const topic = existingTopics.find((topic: any) => readField('id', topic) === topicId);
              if (topic) {
                newTopics.push(topic);
              }
            }

            return newTopics;
          }
        },
      });
    },
  
  });

  const postTypeModal = (
    <InfoModal title='Post Types'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>The post type controls what statuses can be assigned to posts in the topic.</StyledParagraph>
        <StyledParagraph>All posts created in this topic will be assigned to the selected post type.</StyledParagraph>
        <Link href='/agent/settings/community-post-types'>View and manage post types</Link>
      </View>
    </InfoModal>
  );

  /* Edit Category */
  const handleEditCategory = async (values: EditCategoryInput) => {
    const { errors } = await editCategory({
      variables: {
        id: values.id,
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          icon: values.icon ? values.icon : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error editing category' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editCategoryModal = (
    <FormModal<EditCategoryInput> title='Edit Category' initialValues={{ id: '', name: '', description: '', icon: '' }} onSubmit={handleEditCategory}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' required />
        <TextField label='Description' name='description' />
        <TextField label='Icon' description='The Font Awesome ID of the icon' name='icon' />
      </View>
    </FormModal>
  );

  /* Delete Category */
  const handleDeleteCategory = async (id: string) => {
    const { errors } = await deleteCategory({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting category' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Category deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteCategoryModal = (
    <ConfirmModal title='Delete category?' confirmLabel='Delete' destructive onConfirm={handleDeleteCategory}>
      <StyledParagraph>This category will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  /* Create Topic */
  const handleCreateTopic = async (values: CreateTopicInput) => {
    const { errors } = await createTopic({
      variables: {
        input: {
          categoryId: values.categoryId,
          name: values.name,
          description: values.description ? values.description : null,
          visibility: values.visibility,
          restrictPosting: values.restrictPosting === 'true',
          postTypeId: values.postTypeId,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating topic' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Topic created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createTopicModal = (
    <FormModal title='Create Topic' submitLabel='Create' onSubmit={handleCreateTopic} initialValues={{ name: '', description: '', visibility: null, restrictPosting: null, categoryId: null, postTypeId: null }}>
      <View style={{ gap: '16px' }}>
        {(categoriesAreLoading || postTypesAreLoading) ?
          <AtomSpinner />
          :
          <>
            <TextField label='Topic Name' name='name' required />
            <TextArea label='Description' name='description' />
            <SingleSelect label='Visibility' name='visibility' required>
              <Choice label='All users' value='PUBLIC' />
              <Choice label='Logged in users' value='LOGGED_IN_USERS' />
              <Choice label='Agents only' value='AGENTS_ONLY' />
            </SingleSelect>
            <SingleSelect label='Who can post?' name='restrictPosting' required>
              <Choice label='All logged in users' value='false' />
              <Choice label='Agents only' value='true' />
            </SingleSelect>
            <SingleSelect label='Category' name='categoryId' required>
              {categoriesData?.communityCategories?.map((category) => (
                <Choice label={category.name} value={category.id} key={category.id} />
              ))}
            </SingleSelect>
            <SingleSelect label='Post Type' description='All posts in this topic will be assigned to the selected post type' name='postTypeId' required>
              {postTypeData?.communityPostTypes?.map((postType) => (
                <Choice label={postType.name} value={postType.id} key={postType.id} />
              ))}
            </SingleSelect>
          </>
        }
      </View>
    </FormModal>
  );

  /* Edit Topic */
  const handleEditTopic = async (values: EditTopicInput) => {
    const { errors } = await editTopic({
      variables: {
        id: values.id,
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          visibility: values.visibility,
          restrictPosting: values.restrictPosting === 'true',
          categoryId: values.categoryId,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error editing topic' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editTopicModal = (
    <FormModal title='Edit Topic' onSubmit={handleEditTopic} initialValues={{ id: '', name: '', description: '', visibility: '', restrictPosting: '', postTypeId: '', categoryId: '' }}>
      <View style={{ gap: '16px' }}>
        {categoriesAreLoading ?
          <AtomSpinner />
          :
          <>
            <TextField label='Topic Name' name='name' required />
            <TextArea label='Description' name='description' />
            <SingleSelect label='Visibility' name='visibility' required>
              <Choice label='All users' value='PUBLIC' />
              <Choice label='Logged in users' value='LOGGED_IN_USERS' />
              <Choice label='Agents only' value='AGENTS_ONLY' />
            </SingleSelect>
            <SingleSelect label='Who can post?' name='restrictPosting' required>
              <Choice label='All logged in users' value='false' />
              <Choice label='Agents only' value='true' />
            </SingleSelect>
            <SingleSelect label='Category' name='categoryId' required>
              {categoriesData?.communityCategories?.map((category) => (
                <Choice label={category.name} value={category.id} key={category.id} />
              ))}
            </SingleSelect>
          </>
        }
      </View>
    </FormModal>
  );

  /* Delete Topic */
  const handleDeleteTopic = async (id: string) => {
    const { errors } = await deleteTopic({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting topic' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Topic deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteTopicModal = (
    <ConfirmModal title='Delete topic?' confirmLabel='Delete' destructive onConfirm={handleDeleteTopic}>
      <StyledParagraph>This topic will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  /* Edit Topic Order */
  const handleDragEnd = async (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const newTopicOrder = Array.from(topicOrder);
    newTopicOrder.splice(source.index, 1);
    newTopicOrder.splice(destination.index, 0, topicOrder[source.index]);

    setTopicOrder(newTopicOrder);
  }

  const handleSaveOrder = async () => {
    const { errors } = await editTopicOrder({
      variables: {
        categoryId: category.id,
        order: topicOrder,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error saving topic order' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Topic order saved' type='success' id={id} />
      addAlert(id, alert);
    }

    setIsEditingOrder(false);
  }

  return (
    <>
      <View style={{ backgroundColor: '#ffffff', border: `1px solid ${Colors.neutral200}`, borderRadius: '4px' }}>
        <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '4px', justifyContent: 'space-between', minHeight: '65px', padding: '12px', ...(isOpen ? { borderBottom: `1px solid ${Colors.neutral200}` } : {}) }} onClick={() => { if (props.isEditingCategoryOrder) { return; } setIsEditingOrder(false); setIsOpen(!isOpen); }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '4px' }}>
            {props.isEditingCategoryOrder && <Icon size='small' icon={Icons.Grip} />}
            {(isOpen && !props.isEditingCategoryOrder) && <Icon size='small' icon={Icons.ChevronDown} />}
            {(!isOpen && !props.isEditingCategoryOrder) && <Icon size='small' icon={Icons.ChevronRight} />}

            <StyledHeading tag='h6' style={{ display: 'flex', gap: '4px', 'user-select': 'none' }}>{category.name}</StyledHeading>
          </View>

          {(isOpen && !props.isEditingCategoryOrder) &&
            <div onClick={(e: React.MouseEvent) => { e.stopPropagation(); }}>
              <ModalLauncher modal={editCategoryModal}>
                {({ openModal: openEditModal }) => (
                  <ModalLauncher modal={deleteCategoryModal}>
                    {({ openModal: openDeleteModal }) => (
                      <ModalLauncher modal={createTopicModal}>
                        {({ openModal: openCreateTopicModal }) => (
                          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px' }}>
                            <Button label='Create Topic' leftIcon={Icons.Plus} iconSize='small' variant='tertiary' type='button' role='button' action={(e: React.MouseEvent) => {
                              e.stopPropagation();
                              openCreateTopicModal({ name: '', description: '', visibility: null, restrictPosting: null, categoryId: category.id, postTypeId: null });
                            }} style={{ height: 'fit-content' }} />

                            {isEditingOrder ?
                              <Button label='Save Order' variant='tertiary' role='button' action={() => { handleSaveOrder(); }} loading={editTopicOrderIsLoading} style={{ height: 'fit-content' }} />
                              :
                              <Button label='Edit Order' variant='tertiary' role='button' action={() => { setIsEditingOrder(true); setTopicOrder(category.topics.map((topic) => topic.id)); }} style={{ height: 'fit-content' }} />
                            }

                            <ActionMenu alignment='right'>
                              <ActionItem label='Edit' onClick={() => { openEditModal({ id: category.id, name: category.name, description: category.description || '', icon: category.icon || '' }); }} />
                              {(category.topics.length === 0) && <ActionItem label='Delete' onClick={() => { openDeleteModal(category.id); }} />}
                            </ActionMenu>
                          </View>
                        )}
                      </ModalLauncher>
                    )}
                  </ModalLauncher>
                )}
              </ModalLauncher>
            </div>
          }
        </View>
        {(isOpen && !props.isEditingCategoryOrder) &&
          (isEditingOrder ?
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={category.id}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className={css(styles.draggableContainer)}>
                    {topicOrder.map((topicId, index) => {
                      const topic = category.topics.find((topic) => topic.id === topicId);
                      if (!topic) {
                        return null;
                      }

                      return (
                        <Draggable draggableId={topic.id} index={index} key={topic.id}>
                          {(provided) => (
                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={css(styles.draggableTopic)}>
                              <Icon icon={Icons.Grip} size='medium' />
                              <StyledParagraph>{topic?.name}</StyledParagraph>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            :
            <View style={{ backgroundColor: '#ffffff', borderRadius: '4px', gap: '0px', padding: '12px' }}>
              {category.description && <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic' }}>{category.description}</StyledParagraph>}
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell style={{ minWidth: '100px' }}>Name</TableHeaderCell>
                    <TableHeaderCell>Description</TableHeaderCell>
                    <TableHeaderCell>Visibility</TableHeaderCell>
                    <TableHeaderCell>Posting Permissions</TableHeaderCell>
                    <TableHeaderCell style={{ minWidth: '100px' }}>
                      <View style={{ alignItems: 'center', flexDirection: 'row', gap: '4px' }}>
                        Post Type
                        <ModalLauncher modal={postTypeModal}>
                          {({ openModal }) => (
                            <Icon icon={Icons.Help} size='small' onClick={openModal} style={{ cursor: 'pointer' }} />
                          )}
                        </ModalLauncher>
                      </View>
                    </TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {category.topics.map((topic) => (
                    <TableRow key={topic.id}>
                      <TableCell><Link href={`/community/topics/${topic.id}`}>{topic.name}</Link></TableCell>
                      <TableCell>{topic.description}</TableCell>
                      <TableCell>{getTopicVisibilityLabel(topic.visibility)}</TableCell>
                      <TableCell>{topic.restrictPosting ? 'Agents only' : 'All users'}</TableCell>
                      <TableCell>{topic.postType.name}</TableCell>
                      <TableCell>
                        <ModalLauncher modal={editTopicModal}>
                          {({ openModal: openEditModal }) => (
                            <ModalLauncher modal={deleteTopicModal}>
                              {({ openModal: openDeleteModal }) => (
                                <View style={{ flexDirection: 'row', gap: '32px' }}>
                                  <Button label='Edit' variant='tertiary' role='button' action={() => { openEditModal({ id: topic.id, name: topic.name, description: topic.description, visibility: topic.visibility, restrictPosting: topic.restrictPosting ? 'true' : 'false', categoryId: category.id }); }} />
                                  <Button label='Delete' variant='tertiary' role='button' destructive action={() => { openDeleteModal(topic.id); }} />
                                </View>
                              )}
                            </ModalLauncher>
                          )}
                        </ModalLauncher>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </View>
          )

        }
      </View>
    </>
  );
}