import { Card, Cell, Colors, StandardGrid, StyledHeading, StyledParagraph, View } from "@barscience/global-components";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import { Link, Navigate } from "react-router-dom";
import useAgentAuthState from "../../components/auth/useAgentAuthState";

export default function Settings() {
  const { state } = useAgentAuthState();

  if (!state.user?.isSupportAgent) {
    return (
      <Navigate to='/' />
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '48px' }}>
          {(state.user.supportAgentPermissions?.canManageAgents || state.user.supportAgentPermissions?.canManageAgentRoles) &&
            <View>
              <StyledHeading tag='h4'>Agents</StyledHeading>

              <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px', paddingTop: '16px', width: '100%' }}>
                {state.user.supportAgentPermissions?.canManageAgents &&
                  <SettingsCard
                    title='Agents'
                    description='Manage agents and their assigned roles'
                    iconID='fa-user-tie'
                    href='/agent/settings/agents'
                  />
                }
                {(state.user.supportAgentPermissions?.canManageAgentRoles || state.user.supportAgentPermissions.canManageAgents) &&
                  <SettingsCard
                    title='Roles'
                    description='Manage permissions for agent roles'
                    iconID='fa-user-lock'
                    href='/agent/settings/agent-roles'
                  />
                }
              </View>

            </View>
          }
          {(state.user.supportAgentPermissions?.canManagePostTopics || state.user.supportAgentPermissions?.canManagePostStatuses || state.user.supportAgentPermissions?.canManageCommunityBadges) &&
            <View>
              <StyledHeading tag='h4'>Community</StyledHeading>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px', paddingTop: '16px', width: '100%' }}>
                {state.user.supportAgentPermissions?.canManagePostTopics &&
                  <SettingsCard
                    title='Topics'
                    description='Manage community post categories and topics'
                    iconID='fa-messages'
                    href='/agent/settings/community-topics'
                  />
                }
                {(state.user.supportAgentPermissions?.canManagePostStatuses || state.user.supportAgentPermissions.canManagePostTopics) &&
                  <SettingsCard
                    title='Post Types'
                    description='Manage community post types and statuses'
                    iconID='fa-message-dots'
                    href='/agent/settings/community-post-types'
                  />
                }
                {state.user.supportAgentPermissions?.canManagePostTopics &&
                  <SettingsCard
                    title='Offensive Terms'
                    description='Manage terms that auto-hide posts'
                    iconID='fa-comment-exclamation'
                    href='/agent/settings/offensive-terms'
                  />
                }
                {state.user.supportAgentPermissions?.canManageCommunityBadges &&
                  <SettingsCard
                    title='Badges'
                    description='Manage community badges'
                    iconID='fa-badge-check'
                    href='/agent/settings/badges'
                  />
                }
              </View>
            </View>
          }
        </View>
      </Cell>
    </StandardGrid>
  );
}

type SettingsCardProps = {
  title: string;
  description: string;
  iconID: string;
  href: string;
}

const styles = StyleSheet.create({
  cardLink: {
    textDecoration: 'none',
    color: 'inherit',
    height: '110px',
    width: 'calc((100% - 32px) / 3)',
    '@media (min-width: 768px) and (max-width: 1151px)': {
      width: 'calc((100% - 32px) / 2)'
    },
    '@media (max-width: 767px)': {
      width: '100%'
    },
  },
});

const cardStyle: CSSProperties = {
  boxSizing: 'border-box',
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  ':hover': {
    backgroundColor: Colors.neutral100,
  },
  ':active': {
    backgroundColor: Colors.neutral300,
  },
}

function SettingsCard(props: SettingsCardProps) {
  return (
    <Link to={props.href} className={css(styles.cardLink)}>
      <Card size='medium' style={cardStyle}>
        <View style={{ alignItems: 'flex-start', flexDirection: 'row', gap: '16px' }}>
          <i className={"fa-sharp fa-regular fa-xl " + props.iconID}></i>
          <View style={{ gap: '4px' }}>
            <StyledHeading tag='h6'>{props.title}</StyledHeading>
            <StyledParagraph style={{ color: Colors.neutral700 }}>{props.description}</StyledParagraph>
          </View>
        </View>
      </Card>
    </Link>
  );
}