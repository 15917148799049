export enum TopicVisibility {
    PUBLIC = "PUBLIC",
    LOGGED_IN_USERS = "LOGGED_IN_USERS",
    AGENTS_ONLY = "AGENTS_ONLY",
}

export function getTopicVisibilityLabel(visibility: TopicVisibility) {
    switch (visibility) {
        case TopicVisibility.PUBLIC:
            return "All users";
        case TopicVisibility.LOGGED_IN_USERS:
            return "Logged in users";
        case TopicVisibility.AGENTS_ONLY:
            return "Agents only";
    }
}