import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import queriesData from './query-hashes.json';
import { AlertStateProvider, AuthStateProvider } from '@barscience/global-components';
import QueueLink from 'apollo-link-queue';
import SerializingLink from 'apollo-link-serialize';
import { RetryLink } from "@apollo/client/link/retry";
import { AgentAuthStateProvider } from './components/auth/AgentAuthStateProvider';

let uri = 'https://api.barscience.us';
if (window.location.href.includes("-dev")) {
  uri = 'https://api-dev.barscience.us';
} else if (!window.location.href.includes("barscience.us")) {
  uri = `http://${window.location.hostname}:6001/`;
}

const queueLink = new QueueLink();
const serializingLink = new SerializingLink();

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    if (!error || error.toString().includes('status code 400')) {
      return false;
    }

    return count < 5;
  },
  delay: (count, operation, error) => {
    return Math.pow(2, count) * 1000 * Math.random();
  },
});

const httpLink = new HttpLink({
  uri: uri,
  credentials: 'include',
});

const setHashLink = setContext((request, previousContext) => {
  let queries = queriesData as unknown as { [query: string]: string };
  let query = request.query.loc?.source.body?.trim() || '';

  const queryLookupKey = query.replaceAll('__typename', '').replaceAll('\n', '').replaceAll(' ', '').replaceAll(',', '');

  return {
    headers: {
      'query-hash': request.query.loc?.source.body ? queries[queryLookupKey] : '',
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((e) => {
      console.log(
        `[GraphQL error]: Message: ${e.message}, Location: ${e.locations}, Path: ${e.path}`,
      );
    }
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  link: from([errorLink, queueLink, serializingLink, setHashLink, retryLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: 'include',
  name: 'AdminUI',
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthStateProvider>
        <AgentAuthStateProvider>
          <AlertStateProvider>
            <App />
          </AlertStateProvider>
        </AgentAuthStateProvider>
      </AuthStateProvider>
    </ApolloProvider>
  </React.StrictMode>
);
