import { useParams } from "react-router-dom";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Colors, ConfirmModal, ErrorPage, ModalLauncher, NoPermission, NotFound, PageButtons, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextEditor, Typography, View, generateId, useAlertState } from "@barscience/global-components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import ReactTimeago from "react-timeago";
import { StyleSheet, css } from "aphrodite";

/* Get Article Revisions Query */
const GET_ARTICLE_REVISIONS = gql`
query getSupportArticleRevisionHistory($id: ID!, $page: Int!) {
  supportArticle(id: $id) {
    id
    canPublish
    publishedRevision {
      id
    }
    title
    revisions(page: $page) {
      id
      title
      body
      created
      published
      publishedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
}
`;

export type GetArticleRevisionsResponse = {
  supportArticle: Article | null;
}

type Article = {
  id: string;
  canPublish: boolean;
  publishedRevision: {
    id: string;
  };
  title: string;
  revisions: Revision[];
}

type Revision = {
  id: string;
  title: string;
  body: string;
  created: string;
  published: string;
  publishedBy: User;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

/* Change Published Revision Mutation */
const CHANGE_PUBLISHED_REVISION = gql`
mutation changeSupportArticlePublishedRevision($articleId: ID!, $revisionId: ID!) {
  changeSupportArticlePublishedRevision(articleId: $articleId, revisionId: $revisionId) {
    id
    title
    publishedRevision {
      id
    }
  }
}
`;

const styles = StyleSheet.create({
  tag: {
    backgroundColor: Colors.primary500,
    borderRadius: '4px',
    color: '#ffffff',
    fontSize: '14px',
    height: 'fit-content',
    padding: '6px 8px',
    width: 'fit-content',
  },
});

const REVISIONS_PER_PAGE = 10;

export default function KBAdminArticleRevisionHistory() {
  const { id } = useParams<{ id: string }>();
  const { state: agentState } = useAgentAuthState();
  const { addAlert } = useAlertState();
  const [page, setPage] = useState<number>(0);
  const { data: revisionData, loading: revisionsAreLoading, error: revisionError } = useQuery<GetArticleRevisionsResponse>(GET_ARTICLE_REVISIONS, {
    variables: {
      id: id,
      page: page,
    },
    onCompleted: (data) => {
      if (!selectedRevision) {
        setSelectedRevision(data.supportArticle?.revisions[0] || null);
      }
    }
  });
  const [changePublishedRevision] = useMutation(CHANGE_PUBLISHED_REVISION);
  const [selectedRevision, setSelectedRevision] = useState<Revision | null>(null);

  const handleChangePublishedRevision = async () => {
    if (!selectedRevision) {
      return;
    }

    const { errors } = await changePublishedRevision({
      variables: {
        articleId: id,
        revisionId: selectedRevision.id,
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error publishing version' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Version published' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const changePublishedVersionModal = (
    <ConfirmModal title='Change version?' confirmLabel='Publish Version' onConfirm={handleChangePublishedRevision}>
      <StyledParagraph>The currently published version will be replaced with the selected version. Users will see the new version immediately.</StyledParagraph>
    </ConfirmModal>
  );

  if (!agentState.user?.isSupportAgent) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (revisionsAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (revisionError || !revisionData?.supportArticle) {
    if (revisionError?.graphQLErrors[0]?.extensions.status === 404) {
      return (
        <StandardGrid>
          <NotFound />
        </StandardGrid>
      );
    }

    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <View style={{ backgroundColor: '#ffffff', boxSizing: 'border-box', height: '100%', maxHeight: '100%', overflow: 'scroll', width: '100%' }}>
      <View style={{ borderBottom: `1px solid ${Colors.neutral300}`, gap: '16px', height: '68px', padding: '16px 32px' }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', height: '100%', justifyContent: 'space-between' }}>
          <BreadcrumbGroup>
            <Breadcrumb label={revisionData.supportArticle.title} to={`/agent/kb/articles/${revisionData.supportArticle.id}`} />
            <Breadcrumb label='Revision History' />
          </BreadcrumbGroup>

          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px' }}>
            {(selectedRevision && selectedRevision.id !== revisionData.supportArticle.publishedRevision.id) && (revisionData.supportArticle.canPublish) && (
              <ModalLauncher modal={changePublishedVersionModal}>
                {({ openModal }) => (
                  <Button label='Publish Version' variant='secondary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            )}
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', height: '100%', maxHeight: '100%', overflowX: 'scroll', overflowY: 'hidden' }}>
        <View style={{ alignItems: 'center', flexGrow: 1, height: '100%', padding: '32px' }}>
          <View style={{ gap: '24px', height: '100%', maxHeight: '100%', maxWidth: '1000px', overflowY: 'auto', width: '100%' }}>
            <StyledHeading tag='h4'>{selectedRevision?.title}</StyledHeading>
            <TextEditor name='article-viewer' value={selectedRevision?.body} isEditMode={false} containerStyle={{ minHeight: 'fit-content', width: '100%' }} />
          </View>
        </View>

        <View style={{ borderLeft: `1px solid ${Colors.neutral300}`, maxWidth: '450px', minWidth: '380px', padding: '32px' }}>
          <View style={{ alignItems: 'center', gap: '24px', height: '100%', width: '100%' }}>
            {revisionData.supportArticle.revisions.map((revision) => (
              <View key={revision.id} style={{ borderRadius: '4px', boxSizing: 'border-box', cursor: 'pointer', width: '100%', ...(revision.id === selectedRevision?.id ? { border: `1px solid ${Colors.primary500}` } : {}) }} onClick={() => { setSelectedRevision(revision); }}>
                <Card style={{ boxShadow: 'none', boxSizing: 'border-box', width: '100%', ':hover': { backgroundColor: Colors.neutral100 }, ':active': { backgroundColor: Colors.neutral200 }, ...(revision.id === selectedRevision?.id ? { padding: '15px' } : {}) }}>
                  <View style={{ gap: '8px' }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                      <StyledParagraph bold>{revision.title}</StyledParagraph>
                      {revision.id === revisionData.supportArticle?.publishedRevision.id && (
                        <span className={css(Typography.caption, styles.tag)}>Published</span>
                      )}
                    </View>
                    <StyledParagraph style={{ color: Colors.neutral700 }}>Published <ReactTimeago date={revision.published} /> by {revision.publishedBy.firstName} {revision.publishedBy.lastName}</StyledParagraph>
                  </View>
                </Card>
              </View>
            ))}
            <PageButtons currentPage={page} onPageChange={setPage} numPages={revisionData.supportArticle.revisions.length === REVISIONS_PER_PAGE ? page + 1 : page} />
          </View>
        </View>
      </View>
    </View>
  );
}