import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Button, Card, Cell, Choice, CircularSpinner, Colors, Icon, Icons, Link, NoPermission, SingleSelect, StandardGrid, StyledHeading, StyledParagraph, TextEditor, Tooltip, Typography, View } from "@barscience/global-components";
import { StyleSheet, css } from "aphrodite";
import { useEffect, useState } from "react";
import { APPROVE_COMMENT, APPROVE_POST, DELETE_COMMENT, DELETE_POST } from "../community/CommunityPost";
import useAgentAuthState from "../../components/auth/useAgentAuthState";

/* Get Hidden Posts Query */
const GET_HIDDEN_POSTS = gql`
query getHiddenCommunityPosts($page: Int!) {
  hiddenCommunityPosts(page: $page) {
    id
    title
    body
    author {
      id
      nickname
    }
    created
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    flags {
      category
      user {
        id
        nickname
      }
    }
    isHidden
    topic {
      id
      name
    }
  }
}
`;

type GetHiddenPostsResponse = {
  hiddenCommunityPosts: Post[];
}

type Post = {
  id: string;
  title: string;
  body: string;
  author: {
    id: string;
    nickname: string;
  };
  created: string;
  lastUpdated: string | null;
  lastUpdatedBy: {
    id: string;
    nickname: string;
  } | null;
  flags: {
    category: string;
    user: {
      id: string;
      nickname: string;
    };
  }[];
  isHidden: boolean;
  topic: {
    id: string;
    name: string;
  };
}

/* Get Hidden Comments Query */
const GET_HIDDEN_COMMENTS = gql`
query getHiddenCommunityPostComments($page: Int!) {
  hiddenCommunityPostComments(page: $page) {
    id
    body
    author {
      id
      nickname
    }
    created
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    flags {
      category
      user {
        id
        nickname
      }
    }
    isHidden
    post {
      id
      title
    }
  }
}
`;

type GetHiddenCommentsResponse = {
  hiddenCommunityPostComments: Comment[];
}

type Comment = {
  id: string;
  body: string;
  author: {
    id: string;
    nickname: string;
  };
  created: string;
  lastUpdated: string | null;
  lastUpdatedBy: {
    id: string;
    nickname: string;
  } | null;
  flags: {
    category: string;
    user: {
      id: string;
      nickname: string;
    };
  }[];
  isHidden: boolean;
  post: {
    id: string;
    title: string;
  };
}

/* Get Flagged Posts Query */
const GET_FLAGGED_POSTS = gql`
query getFlaggedCommunityPosts($page: Int!) {
  flaggedCommunityPosts(page: $page) {
    id
    title
    body
    author {
      id
      nickname
    }
    created
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    flags {
      category
      user {
        id
        nickname
      }
    }
    isHidden
    topic {
      id
      name
    }
  }
}
`;

type GetFlaggedPostsResponse = {
  flaggedCommunityPosts: Post[];
}

/* Get Flagged Comments Query */
const GET_FLAGGED_COMMENTS = gql`
query getFlaggedCommunityPostComments($page: Int!) {
  flaggedCommunityPostComments(page: $page) {
    id
    body
    author {
      id
      nickname
    }
    created
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    flags {
      category
      user {
        id
        nickname
      }
    }
    isHidden
    post {
      id
      title
    }
  }
}
`;

type GetFlaggedCommentsResponse = {
  flaggedCommunityPostComments: Comment[];
}

/* Get Unreviewed Posts Query */
const GET_UNREVIEWED_POSTS = gql`
query getUnreviewedCommunityPosts($page: Int!) {
  unreviewedCommunityPosts(page: $page) {
    id
    title
    body
    author {
      id
      nickname
    }
    created
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    flags {
      category
      user {
        id
        nickname
      }
    }
    isHidden
    topic {
      id
      name
    }
  }
}
`;

type GetUnreviewedPostsResponse = {
  unreviewedCommunityPosts: Post[];
}

/* Get Unreviewed Comments Query */
const GET_UNREVIEWED_COMMENTS = gql`
query getUnreviewedCommunityPostComments($page: Int!) {
  unreviewedCommunityPostComments(page: $page) {
    id
    body
    author {
      id
      nickname
    }
    created
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    flags {
      category
      user {
        id
        nickname
      }
    }
    isHidden
    post {
      id
      title
    }
  }
}
`;

type GetUnreviewedCommentsResponse = {
  unreviewedCommunityPostComments: Comment[];
}

/* Mark Post As Reviewed Mutation */
const MARK_POST_AS_REVIEWED = gql`
mutation markCommunityPostAsReviewed($id: ID!) {
  success: markCommunityPostAsReviewed(id: $id)
}
`;

type MarkPostAsReviewedResponse = {
  success: boolean;
}

/* Mark Comment As Reviewed Mutation */
const MARK_COMMENT_AS_REVIEWED = gql`
mutation markCommunityPostCommentAsReviewed($id: ID!) {
  success: markCommunityPostCommentAsReviewed(id: $id)
}
`;

type MarkCommentAsReviewedResponse = {
  success: boolean;
}

/* Page Settings */
const MAX_PAGE = 50;

enum Filter {
  FLAGGED = 'FLAGGED',
  HIDDEN = 'HIDDEN',
  UNREVIEWED = 'UNREVIEWED'
}

enum PostType {
  POST = 'POST',
  COMMENT = 'COMMENT',
}

const styles = StyleSheet.create({
  pageButton: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    border: `2px solid ${Colors.neutral300}`,
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '14px',
    height: '32px',
    justifyContent: 'center',
    textAlign: 'center',
    width: '32px',
    ':hover': {
      borderColor: Colors.primary500,
    },
    ':active': {
      backgroundColor: Colors.primary50,
      borderColor: Colors.primary500,
    },
  },
  pageButtonSelected: {
    backgroundColor: Colors.primary50,
    borderColor: Colors.primary500,
  },
  pageButtonDisabled: {
    backgroundColor: Colors.neutral100,
    borderColor: Colors.neutral300,
    cursor: 'default',
    ':hover': {
      borderColor: Colors.neutral300,
    },
    ':active': {
      backgroundColor: Colors.neutral100,
      borderColor: Colors.neutral300,
    },
  },
  hiddenTag: {
    borderRadius: '4px',
    border: `1px solid ${Colors.neutral700}`,
    color: Colors.neutral700,
    fontSize: '14px',
    height: 'fit-content',
    padding: '2px 8px',
    width: 'fit-content',
  },
});

export default function PostModeration() {
  const { state } = useAgentAuthState();

  const [filter, setFilter] = useState<Filter>(Filter.FLAGGED);
  const [postType, setPostType] = useState<PostType>(PostType.POST);
  const [page, setPage] = useState<number>(0);

  const [getHiddenPosts, { data: hiddenPosts, loading: hiddenPostsAreLoading }] = useLazyQuery<GetHiddenPostsResponse>(GET_HIDDEN_POSTS, {
    fetchPolicy: 'network-only',
  });
  const [getHiddenComments, { data: hiddenComments, loading: hiddenCommentsAreLoading }] = useLazyQuery<GetHiddenCommentsResponse>(GET_HIDDEN_COMMENTS, {
    fetchPolicy: 'network-only',
  });
  const [getFlaggedPosts, { data: flaggedPosts, loading: flaggedPostsAreLoading }] = useLazyQuery<GetFlaggedPostsResponse>(GET_FLAGGED_POSTS, {
    fetchPolicy: 'network-only',
  });
  const [getFlaggedComments, { data: flaggedComments, loading: flaggedCommentsAreLoading }] = useLazyQuery<GetFlaggedCommentsResponse>(GET_FLAGGED_COMMENTS, {
    fetchPolicy: 'network-only',
  });
  const [getUnreviewedPosts, { data: unreviewedPosts, loading: unreviewedPostsAreLoading }] = useLazyQuery<GetUnreviewedPostsResponse>(GET_UNREVIEWED_POSTS, {
    fetchPolicy: 'network-only',
  });
  const [getUnreviewedComments, { data: unreviewedComments, loading: unreviewedCommentsAreLoading }] = useLazyQuery<GetUnreviewedCommentsResponse>(GET_UNREVIEWED_COMMENTS, {
    fetchPolicy: 'network-only',
  });

  const [approvePost] = useMutation(APPROVE_POST, {
    update(cache, { data }, { variables }) {
      if (!variables || !variables.id) {
        return;
      }

      if (data) {
        cache.modify({
          fields: {
            hiddenCommunityPosts(existingPosts = [], { readField }) {
              return existingPosts.filter((postRef: any) => variables.id !== readField('id', postRef));
            }
          }
        });

        cache.modify({
          fields: {
            flaggedCommunityPosts(existingPosts = [], { readField }) {
              return existingPosts.filter((postRef: any) => variables.id !== readField('id', postRef));
            }
          }
        });
      }
    }
  });
  const [deletePost] = useMutation(DELETE_POST, {
    update(cache, { data }, { variables }) {
      if (!variables || !variables.id) {
        return;
      }

      if (data) {
        cache.modify({
          fields: {
            hiddenCommunityPosts(existingPosts = [], { readField }) {
              return existingPosts.filter((postRef: any) => variables.id !== readField('id', postRef));
            }
          }
        });

        cache.modify({
          fields: {
            flaggedCommunityPosts(existingPosts = [], { readField }) {
              return existingPosts.filter((postRef: any) => variables.id !== readField('id', postRef));
            }
          }
        });
      }
    }

  });
  const [approveComment] = useMutation(APPROVE_COMMENT, {
    update(cache, { data }, { variables }) {
      if (!variables || !variables.id) {
        return;
      }

      if (data) {
        cache.modify({
          fields: {
            hiddenCommunityPostComments(existingComments = [], { readField }) {
              return existingComments.filter((commentRef: any) => variables.id !== readField('id', commentRef));
            }
          }
        });

        cache.modify({
          fields: {
            flaggedCommunityPostComments(existingComments = [], { readField }) {
              return existingComments.filter((commentRef: any) => variables.id !== readField('id', commentRef));
            }
          }
        });
      }
    }

  });
  const [deleteComment] = useMutation(DELETE_COMMENT, {
    update(cache, { data }, { variables }) {
      if (!variables || !variables.id) {
        return;
      }

      if (data) {
        cache.modify({
          fields: {
            hiddenCommunityPostComments(existingComments = [], { readField }) {
              return existingComments.filter((commentRef: any) => variables.id !== readField('id', commentRef));
            }
          }
        });

        cache.modify({
          fields: {
            flaggedCommunityPostComments(existingComments = [], { readField }) {
              return existingComments.filter((commentRef: any) => variables.id !== readField('id', commentRef));
            }
          }
        });
      }
    }

  });
  const [markPostAsReviewed] = useMutation<MarkPostAsReviewedResponse>(MARK_POST_AS_REVIEWED, {
    update(cache, { data }, { variables }) {
      if (!variables || !variables.id) {
        return;
      }

      if (!data?.success) {
        return;
      }

      if (data) {
        cache.modify({
          fields: {
            unreviewedCommunityPosts(existingPosts = [], { readField }) {
              return existingPosts.filter((postRef: any) => variables.id !== readField('id', postRef));
            }
          }
        });
      }
    }
  });
  const [markCommentAsReviewed] = useMutation<MarkCommentAsReviewedResponse>(MARK_COMMENT_AS_REVIEWED, {
    update(cache, { data }, { variables }) {
      if (!variables || !variables.id) {
        return;
      }

      if (!data?.success) {
        return;
      }

      if (data) {
        cache.modify({
          fields: {
            unreviewedCommunityPostComments(existingComments = [], { readField }) {
              return existingComments.filter((commentRef: any) => variables.id !== readField('id', commentRef));
            }
          }
        });
      }
    }
  });

  useEffect(() => {
    if (filter === Filter.HIDDEN) {
      if (postType === PostType.POST) {
        getHiddenPosts({ variables: { page } });
      } else if (postType === PostType.COMMENT) {
        getHiddenComments({ variables: { page } });
      }
    } else if (filter === Filter.FLAGGED) {
      if (postType === PostType.POST) {
        getFlaggedPosts({ variables: { page } });
      } else if (postType === PostType.COMMENT) {
        getFlaggedComments({ variables: { page } });
      }
    } else if (filter === Filter.UNREVIEWED) {
      if (postType === PostType.POST) {
        getUnreviewedPosts({ variables: { page } });
      } else if (postType === PostType.COMMENT) {
        getUnreviewedComments({ variables: { page } });
      }
    }
  }, [filter, postType, page, getHiddenPosts, getHiddenComments, getFlaggedPosts, getFlaggedComments, getUnreviewedPosts, getUnreviewedComments])

  const areAllPostsReviewed = () => {
    if (filter === Filter.HIDDEN) {
      if (postType === PostType.POST) {
        return hiddenPosts?.hiddenCommunityPosts.length === 0;
      } else if (postType === PostType.COMMENT) {
        return hiddenComments?.hiddenCommunityPostComments.length === 0;
      }
    } else if (filter === Filter.FLAGGED) {
      if (postType === PostType.POST) {
        return flaggedPosts?.flaggedCommunityPosts.length === 0;
      } else if (postType === PostType.COMMENT) {
        return flaggedComments?.flaggedCommunityPostComments.length === 0;
      }
    } else if (filter === Filter.UNREVIEWED) {
      if (postType === PostType.POST) {
        return unreviewedPosts?.unreviewedCommunityPosts.length === 0;
      } else if (postType === PostType.COMMENT) {
        return unreviewedComments?.unreviewedCommunityPostComments.length === 0;
      }
    }

    return false;
  }

  const handleFilterChange = (_: string, value: string | null) => {
    if (value) {
      setFilter(value as Filter);
      setPage(0);
    }
  }

  const handlePostTypeChange = (_: string, value: string | null) => {
    if (value) {
      setPostType(value as PostType);
      setPage(0);
    }
  }

  const handleApprovePost = async (id: string) => {
    if (postType === PostType.POST) {
      await approvePost({ variables: { id: id } });
    } else if (postType === PostType.COMMENT) {
      await approveComment({ variables: { id: id } });
    }
  }

  const handleDeletePost = async (id: string) => {
    if (postType === PostType.POST) {
      await deletePost({ variables: { id: id } });
    } else if (postType === PostType.COMMENT) {
      await deleteComment({ variables: { id: id } });
    }
  }

  const handleMarkPostAsReviewed = async (id: string) => {
    if (postType === PostType.POST) {
      await markPostAsReviewed({ variables: { id: id } });
    } else if (postType === PostType.COMMENT) {
      await markCommentAsReviewed({ variables: { id: id } });
    }
  }

  if (!state.user?.supportAgentPermissions?.canModerateCommunityContent) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Post Moderation</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '48px' }}>
          <View style={{ flexDirection: 'row', gap: '24px' }}>
            <SingleSelect label='Filter' name='filter' value={filter} onChange={handleFilterChange} style={{ maxWidth: '250px' }}>
              <Choice label='Flagged posts' value={Filter.FLAGGED} />
              <Choice label='Hidden posts' value={Filter.HIDDEN} />
              <Choice label='Unreviewed posts' value={Filter.UNREVIEWED} />
            </SingleSelect>
            <SingleSelect label='Post Type' name='postType' value={postType} onChange={handlePostTypeChange} style={{ maxWidth: '250px' }}>
              <Choice label='Posts' value={PostType.POST} />
              <Choice label='Comments' value={PostType.COMMENT} />
            </SingleSelect>
          </View>

          <PageButtons page={page} setPage={setPage} />
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {(hiddenPostsAreLoading || hiddenCommentsAreLoading || flaggedPostsAreLoading || flaggedCommentsAreLoading || unreviewedPostsAreLoading || unreviewedCommentsAreLoading) &&
          <View style={{ alignItems: 'center', height: '400px', justifyContent: 'center', width: '100%' }}>
            <CircularSpinner size='medium' />
          </View>
        }

        {areAllPostsReviewed() &&
          <View style={{ alignItems: 'center', height: '500px', justifyContent: 'center', width: '100%' }}>
            <StyledHeading tag='h6'>Nice work! All posts reviewed!</StyledHeading>
          </View>
        }

        {(filter === Filter.HIDDEN && postType === PostType.POST && !hiddenPostsAreLoading) &&
          <View style={{ alignItems: 'center', gap: '40px', marginTop: '40px', width: '100%', }}>
            {hiddenPosts?.hiddenCommunityPosts.map((post) => {
              return (
                <Card size='medium' style={{ maxWidth: '800px', width: '100%' }} key={post.id}>
                  <View style={{ gap: '16px' }}>
                    <View style={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '24px' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                          <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                          <Link href={`/community/users/${post.author.id}`} target='_blank' linkStyle={{ fontSize: '18px' }}>{post.author.nickname}</Link>
                        </View>

                        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                          <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>{new Date(Date.parse(post.created)).toLocaleString()}</StyledParagraph>
                          {post?.lastUpdated &&
                            <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>
                              {getUpdatedLabel(post.author, post.lastUpdated, post.lastUpdatedBy)}
                            </StyledParagraph>
                          }
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', gap: '8px' }}>
                        {post.isHidden &&
                          <span className={css(Typography.paragraph2, styles.hiddenTag)}>Pending approval</span>
                        }
                      </View>
                    </View>

                    <StyledParagraph style={{ color: Colors.neutral700 }}>Posted in <Link href={`/community/posts/${post.id}`} target='_blank'>{post.topic.name}</Link></StyledParagraph>

                    <TextEditor name='post-view' value={post.body} isEditMode={false} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} />

                    <View style={{ alignItems: 'baseline', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Tooltip strategy='fixed' content={
                        <View>
                          {!post.flags?.length && <StyledParagraph style={{ fontSize: '12px' }}>No flags on this post</StyledParagraph>}
                          {post.flags?.map((flag) => (
                            <StyledParagraph style={{ fontSize: '12px' }} key={flag.user.id}>{flag.category} - {`${flag.user.nickname} (${flag.user.id})`}</StyledParagraph>
                          ))}
                        </View>
                      }>
                        <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{`Flags (${post.flags?.length || 0})`}</StyledParagraph>
                      </Tooltip>
                    </View>

                    <View style={{ flexDirection: 'row', gap: '24px' }}>
                      <Button label='Approve' variant='secondary' role='button' action={handleApprovePost} data={post.id} />
                      <Button label='Delete' variant='secondary' role='button' action={handleDeletePost} data={post.id} destructive />
                    </View>
                  </View>
                </Card>
              );
            })}
          </View>
        }

        {(filter === Filter.HIDDEN && postType === PostType.COMMENT && !hiddenCommentsAreLoading) &&
          <View style={{ alignItems: 'center', gap: '40px', marginTop: '40px', width: '100%', }}>
            {hiddenComments?.hiddenCommunityPostComments.map((comment) => {
              return (
                <Card size='medium' style={{ maxWidth: '800px', width: '100%' }} key={comment.id}>
                  <View style={{ gap: '16px' }}>
                    <View style={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '24px' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                          <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                          <Link href={`/community/users/${comment.author.id}`} target='_blank' linkStyle={{ fontSize: '18px' }}>{comment.author.nickname}</Link>
                        </View>

                        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                          <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>{new Date(Date.parse(comment.created)).toLocaleString()}</StyledParagraph>
                          {comment?.lastUpdated &&
                            <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>
                              {getUpdatedLabel(comment.author, comment.lastUpdated, comment.lastUpdatedBy)}
                            </StyledParagraph>
                          }
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', gap: '8px' }}>
                        {comment.isHidden &&
                          <span className={css(Typography.paragraph2, styles.hiddenTag)}>Pending approval</span>
                        }
                      </View>
                    </View>

                    <StyledParagraph style={{ color: Colors.neutral700 }}>Posted in <Link href={`/community/posts/${comment.post.id}`} target='_blank'>{comment.post.title}</Link></StyledParagraph>

                    <TextEditor name='post-view' value={comment.body} isEditMode={false} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} />

                    <View style={{ alignItems: 'baseline', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Tooltip strategy='fixed' content={
                        <View>
                          {!comment.flags?.length && <StyledParagraph style={{ fontSize: '12px' }}>No flags on this post</StyledParagraph>}
                          {comment.flags?.map((flag) => (
                            <StyledParagraph style={{ fontSize: '12px' }} key={flag.user.id}>{flag.category} - {`${flag.user.nickname} (${flag.user.id})`}</StyledParagraph>
                          ))}
                        </View>
                      }>
                        <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{`Flags (${comment.flags?.length || 0})`}</StyledParagraph>
                      </Tooltip>
                    </View>

                    <View style={{ flexDirection: 'row', gap: '24px' }}>
                      <Button label='Approve' variant='secondary' role='button' action={handleApprovePost} data={comment.id} />
                      <Button label='Delete' variant='secondary' role='button' action={handleDeletePost} data={comment.id} destructive />
                    </View>
                  </View>
                </Card>
              );
            })}
          </View>
        }

        {(filter === Filter.FLAGGED && postType === PostType.POST && !flaggedPostsAreLoading) &&
          <View style={{ alignItems: 'center', gap: '40px', marginTop: '40px', width: '100%', }}>
            {flaggedPosts?.flaggedCommunityPosts.map((post) => {
              return (
                <Card size='medium' style={{ maxWidth: '800px', width: '100%' }} key={post.id}>
                  <View style={{ gap: '16px' }}>
                    <View style={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '24px' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                          <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                          <Link href={`/community/users/${post.author.id}`} target='_blank' linkStyle={{ fontSize: '18px' }}>{post.author.nickname}</Link>
                        </View>

                        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                          <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>{new Date(Date.parse(post.created)).toLocaleString()}</StyledParagraph>
                          {post?.lastUpdated &&
                            <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>
                              {getUpdatedLabel(post.author, post.lastUpdated, post.lastUpdatedBy)}
                            </StyledParagraph>
                          }
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', gap: '8px' }}>
                        {post.isHidden &&
                          <span className={css(Typography.paragraph2, styles.hiddenTag)}>Pending approval</span>
                        }
                      </View>
                    </View>

                    <StyledParagraph style={{ color: Colors.neutral700 }}>Posted in <Link href={`/community/posts/${post.id}`} target='_blank'>{post.topic.name}</Link></StyledParagraph>

                    <TextEditor name='post-view' value={post.body} isEditMode={false} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} />

                    <View style={{ alignItems: 'baseline', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Tooltip strategy='fixed' content={
                        <View>
                          {!post.flags?.length && <StyledParagraph style={{ fontSize: '12px' }}>No flags on this post</StyledParagraph>}
                          {post.flags?.map((flag) => (
                            <StyledParagraph style={{ fontSize: '12px' }} key={flag.user.id}>{flag.category} - {`${flag.user.nickname} (${flag.user.id})`}</StyledParagraph>
                          ))}
                        </View>
                      }>
                        <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{`Flags (${post.flags?.length || 0})`}</StyledParagraph>
                      </Tooltip>
                    </View>

                    <View style={{ flexDirection: 'row', gap: '24px' }}>
                      <Button label='Approve' variant='secondary' role='button' action={handleApprovePost} data={post.id} />
                      <Button label='Delete' variant='secondary' role='button' action={handleDeletePost} data={post.id} destructive />
                    </View>
                  </View>
                </Card>
              );
            })}
          </View>
        }

        {(filter === Filter.FLAGGED && postType === PostType.COMMENT && !flaggedCommentsAreLoading) &&
          <View style={{ alignItems: 'center', gap: '40px', marginTop: '40px', width: '100%', }}>
            {flaggedComments?.flaggedCommunityPostComments.map((comment) => {
              return (
                <Card size='medium' style={{ maxWidth: '800px', width: '100%' }} key={comment.id}>
                  <View style={{ gap: '16px' }}>
                    <View style={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '24px' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                          <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                          <Link href={`/community/users/${comment.author.id}`} target='_blank' linkStyle={{ fontSize: '18px' }}>{comment.author.nickname}</Link>
                        </View>

                        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                          <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>{new Date(Date.parse(comment.created)).toLocaleString()}</StyledParagraph>
                          {comment?.lastUpdated &&
                            <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>
                              {getUpdatedLabel(comment.author, comment.lastUpdated, comment.lastUpdatedBy)}
                            </StyledParagraph>
                          }
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', gap: '8px' }}>
                        {comment.isHidden &&
                          <span className={css(Typography.paragraph2, styles.hiddenTag)}>Pending approval</span>
                        }
                      </View>
                    </View>

                    <StyledParagraph style={{ color: Colors.neutral700 }}>Posted in <Link href={`/community/posts/${comment.post.id}`} target='_blank'>{comment.post.title}</Link></StyledParagraph>

                    <TextEditor name='post-view' value={comment.body} isEditMode={false} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} />

                    <View style={{ alignItems: 'baseline', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Tooltip strategy='fixed' content={
                        <View>
                          {!comment.flags?.length && <StyledParagraph style={{ fontSize: '12px' }}>No flags on this post</StyledParagraph>}
                          {comment.flags?.map((flag) => (
                            <StyledParagraph style={{ fontSize: '12px' }} key={flag.user.id}>{flag.category} - {`${flag.user.nickname} (${flag.user.id})`}</StyledParagraph>
                          ))}
                        </View>
                      }>
                        <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{`Flags (${comment.flags?.length || 0})`}</StyledParagraph>
                      </Tooltip>
                    </View>

                    <View style={{ flexDirection: 'row', gap: '24px' }}>
                      <Button label='Approve' variant='secondary' role='button' action={handleApprovePost} data={comment.id} />
                      <Button label='Delete' variant='secondary' role='button' action={handleDeletePost} data={comment.id} destructive />
                    </View>
                  </View>
                </Card>
              );
            })}
          </View>
        }

        {(filter === Filter.UNREVIEWED && postType === PostType.POST && !unreviewedPostsAreLoading) &&
          <View style={{ alignItems: 'center', gap: '40px', marginTop: '40px', width: '100%', }}>
            {unreviewedPosts?.unreviewedCommunityPosts.map((post) => {
              return (
                <Card size='medium' style={{ maxWidth: '800px', width: '100%' }} key={post.id}>
                  <View style={{ gap: '16px' }}>
                    <View style={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '24px' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                          <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                          <Link href={`/community/users/${post.author.id}`} target='_blank' linkStyle={{ fontSize: '18px' }}>{post.author.nickname}</Link>
                        </View>

                        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                          <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>{new Date(Date.parse(post.created)).toLocaleString()}</StyledParagraph>
                          {post?.lastUpdated &&
                            <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>
                              {getUpdatedLabel(post.author, post.lastUpdated, post.lastUpdatedBy)}
                            </StyledParagraph>
                          }
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', gap: '8px' }}>
                        {post.isHidden &&
                          <span className={css(Typography.paragraph2, styles.hiddenTag)}>Pending approval</span>
                        }
                      </View>
                    </View>

                    <StyledParagraph style={{ color: Colors.neutral700 }}>Posted in <Link href={`/community/posts/${post.id}`} target='_blank'>{post.topic.name}</Link></StyledParagraph>

                    <TextEditor name='post-view' value={post.body} isEditMode={false} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} />

                    <View style={{ alignItems: 'baseline', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Tooltip strategy='fixed' content={
                        <View>
                          {!post.flags?.length && <StyledParagraph style={{ fontSize: '12px' }}>No flags on this post</StyledParagraph>}
                          {post.flags?.map((flag) => (
                            <StyledParagraph style={{ fontSize: '12px' }} key={flag.user.id}>{flag.category} - {`${flag.user.nickname} (${flag.user.id})`}</StyledParagraph>
                          ))}
                        </View>
                      }>
                        <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{`Flags (${post.flags?.length || 0})`}</StyledParagraph>
                      </Tooltip>
                    </View>

                    <View style={{ flexDirection: 'row', gap: '24px' }}>
                      <Button label='Mark as reviewed' variant='secondary' role='button' action={handleMarkPostAsReviewed} data={post.id} />
                    </View>
                  </View>
                </Card>
              );
            })}
          </View>
        }

        {(filter === Filter.UNREVIEWED && postType === PostType.COMMENT && !unreviewedCommentsAreLoading) &&
          <View style={{ alignItems: 'center', gap: '40px', marginTop: '40px', width: '100%', }}>
            {unreviewedComments?.unreviewedCommunityPostComments.map((comment) => {
              return (
                <Card size='medium' style={{ maxWidth: '800px', width: '100%' }} key={comment.id}>
                  <View style={{ gap: '16px' }}>
                    <View style={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '24px' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                          <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                          <Link href={`/community/users/${comment.author.id}`} target='_blank' linkStyle={{ fontSize: '18px' }}>{comment.author.nickname}</Link>
                        </View>

                        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}>
                          <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>{new Date(Date.parse(comment.created)).toLocaleString()}</StyledParagraph>
                          {comment?.lastUpdated &&
                            <StyledParagraph style={{ color: Colors.neutral700, fontSize: '14px', fontStyle: 'italic' }}>
                              {getUpdatedLabel(comment.author, comment.lastUpdated, comment.lastUpdatedBy)}
                            </StyledParagraph>
                          }
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', gap: '8px' }}>
                        {comment.isHidden &&
                          <span className={css(Typography.paragraph2, styles.hiddenTag)}>Pending approval</span>
                        }
                      </View>
                    </View>

                    <StyledParagraph style={{ color: Colors.neutral700 }}>Posted in <Link href={`/community/posts/${comment.post.id}`} target='_blank'>{comment.post.title}</Link></StyledParagraph>

                    <TextEditor name='post-view' value={comment.body} isEditMode={false} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} />

                    <View style={{ alignItems: 'baseline', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Tooltip strategy='fixed' content={
                        <View>
                          {!comment.flags?.length && <StyledParagraph style={{ fontSize: '12px' }}>No flags on this post</StyledParagraph>}
                          {comment.flags?.map((flag) => (
                            <StyledParagraph style={{ fontSize: '12px' }} key={flag.user.id}>{flag.category} - {`${flag.user.nickname} (${flag.user.id})`}</StyledParagraph>
                          ))}
                        </View>
                      }>
                        <StyledParagraph style={{ color: Colors.neutral700, fontSize: '12px' }}>{`Flags (${comment.flags?.length || 0})`}</StyledParagraph>
                      </Tooltip>
                    </View>

                    <View style={{ flexDirection: 'row', gap: '24px' }}>
                      <Button label='Mark as reviewed' variant='secondary' role='button' action={handleMarkPostAsReviewed} data={comment.id} />
                    </View>
                  </View>
                </Card>
              );
            })}
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}


type PageButtonsProps = {
  page: number;
  setPage: (page: number) => void;
}

function PageButtons(props: PageButtonsProps) {
  const { page, setPage } = props;
  const minPage = Math.min(Math.max(0, page - 4), MAX_PAGE - 10);

  const isBackDisabled = page <= 0;
  const isForwardDisabled = page >= (MAX_PAGE - 1);

  return (
    <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'center', width: '100%' }}>
      <button onClick={() => setPage(page - 1)} className={css(styles.pageButton, isBackDisabled && styles.pageButtonDisabled)} disabled={isBackDisabled}><Icon icon={Icons.ChevronLeft} size='small' /></button>

      {Array.from({ length: 10 }, (_, i) => {
        const pageNumber = i + minPage;

        return (
          <button key={i} onClick={() => setPage(pageNumber)} className={css(Typography.paragraph2, styles.pageButton, page === pageNumber && styles.pageButtonSelected)}>{pageNumber + 1}</button>
        );
      }
      )}

      <button onClick={() => setPage(page + 1)} className={css(styles.pageButton, isForwardDisabled && styles.pageButtonDisabled)} disabled={isForwardDisabled}><Icon icon={Icons.ChevronRight} size='small' /></button>
    </View>
  );
}

function getUpdatedLabel(author: { id: string; nickname: string; }, updatedTimestamp: string | null, updatedBy: { id: string; nickname: string; } | null) {
  if (!updatedTimestamp) {
    return null;
  }

  if (updatedBy && updatedBy.id !== author.id) {
    return (
      <>
        (Updated {new Date(Date.parse(updatedTimestamp)).toLocaleString()} by <Link href={`/community/users/${updatedBy.id}`} target='_blank' linkStyle={{ color: Colors.neutral700, fontSize: '14px' }}>{updatedBy.nickname}</Link>)
      </>
    );
  } else {
    return (
      <>
        (Updated {Date.parse(updatedTimestamp).toLocaleString()})
      </>
    );
  }
}