import { AuthenticatedRoute, useAuthState } from '@barscience/global-components';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import CommunityHome from './pages/community/CommunityHome';
import Settings from './pages/settings/Settings';
import Agents from './pages/settings/agents/Agents';
import AgentRoles from './pages/settings/agents/AgentRoles';
import AgentRoleDetails from './pages/settings/agents/AgentRoleDetails';
import PostTypes from './pages/settings/community/PostTypes';
import CategoriesAndTopics from './pages/settings/community/CategoriesAndTopics';
import CommunityPost from './pages/community/CommunityPost';
import CreateCommunityPost from './pages/community/CreateCommunityPost';
import CommunityTopic from './pages/community/CommunityTopic';
import ProtectedRoute from './components/routes/ProtectedRoute';
import KBAdminHome from './pages/knowledge/KBAdminHome';
import KBAdminCategory from './pages/knowledge/KBAdminCategory';
import KBAdminFolder from './pages/knowledge/KBAdminFolder';
import KBAdminArticle from './pages/knowledge/KBAdminArticle';
import KBAdminArticleRevisionHistory from './pages/knowledge/KBAdminArticleRevisionHistory';
import KBAdminPublishQueue from './pages/knowledge/KBAdminPublishQueue';
import HelpCenterHome from './pages/helpCenter/HelpCenterHome';
import HelpCenterCategory from './pages/helpCenter/HelpCenterCategory';
import HelpCenterArticle from './pages/helpCenter/HelpCenterArticle';
import HelpCenterSearchResults from './pages/helpCenter/HelpCenterSearchResults';
import PostModeration from './pages/moderation/PostModeration';
import OffensiveTerms from './pages/settings/community/OffensiveTerms';
import PageWrapper from './components/PageWrapper';
import Badges from './pages/settings/badges/Badges';
import UserProfile from './pages/community/UserProfile';

function App() {
  const { state } = useAuthState();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<Navigate to='/help-center' />} />

        <Route path='/help-center' element={<PageWrapper><HelpCenterHome /></PageWrapper>} />
        <Route path='/help-center/search' element={<PageWrapper><HelpCenterSearchResults /></PageWrapper>} />
        <Route path='/help-center/categories/:id' element={<PageWrapper><HelpCenterCategory /></PageWrapper>} />
        <Route path='/help-center/articles/:id' element={<PageWrapper><HelpCenterArticle /></PageWrapper>} />

        <Route path='/community' element={<PageWrapper><CommunityHome /></PageWrapper>} />
        <Route path='/community/topics/:id' element={<PageWrapper><CommunityTopic /></PageWrapper>} />
        <Route path='/community/posts/create' element={<PageWrapper><AuthenticatedRoute><CreateCommunityPost /></AuthenticatedRoute></PageWrapper>} />
        <Route path='/community/posts/:id' element={<PageWrapper><CommunityPost /></PageWrapper>} />
        <Route path='/community/users/me' element={state.user ? <Navigate to={`/community/users/${state.user.id}`} /> : <Navigate to='/community' />} />
        <Route path='/community/users/:userId' element={<PageWrapper><UserProfile /></PageWrapper>} />

        <Route path='/agent/kb' element={<PageWrapper><AuthenticatedRoute><KBAdminHome /></AuthenticatedRoute></PageWrapper>} />
        <Route path='/agent/kb/categories/:id' element={<PageWrapper><AuthenticatedRoute><KBAdminCategory /></AuthenticatedRoute></PageWrapper>} />
        <Route path='/agent/kb/folders/:id' element={<PageWrapper><AuthenticatedRoute><KBAdminFolder /></AuthenticatedRoute></PageWrapper>} />
        <Route path='/agent/kb/articles/:id' element={<PageWrapper><AuthenticatedRoute><KBAdminArticle /></AuthenticatedRoute></PageWrapper>} />
        <Route path='/agent/kb/articles/:id/revisions' element={<PageWrapper><AuthenticatedRoute><KBAdminArticleRevisionHistory /></AuthenticatedRoute></PageWrapper>} />
        <Route path='/agent/kb/publish-queue' element={<PageWrapper><AuthenticatedRoute><KBAdminPublishQueue /></AuthenticatedRoute></PageWrapper>} />

        <Route path='/agent/moderation' element={<PageWrapper><AuthenticatedRoute><PostModeration /></AuthenticatedRoute></PageWrapper>} />

        <Route path='/agent/settings' element={<PageWrapper><Settings /></PageWrapper>} />

        <Route path='/agent/settings/agents' element={<PageWrapper><ProtectedRoute permissions={['canManageAgents']}><Agents /></ProtectedRoute></PageWrapper>} />
        <Route path='/agent/settings/agent-roles' element={<PageWrapper><ProtectedRoute permissions={['canManageAgents', 'canManageAgentRoles']}><AgentRoles /></ProtectedRoute></PageWrapper>} />
        <Route path='/agent/settings/agent-roles/:id' element={<PageWrapper><ProtectedRoute permissions={['canManageAgents', 'canManageAgentRoles']}><AgentRoleDetails /></ProtectedRoute></PageWrapper>} />

        <Route path='/agent/settings/community-topics' element={<PageWrapper><ProtectedRoute permissions={['canManagePostTopics']}><CategoriesAndTopics /></ProtectedRoute></PageWrapper>} />
        <Route path='/agent/settings/community-post-types' element={<PageWrapper><ProtectedRoute permissions={['canManagePostTopics', 'canManagePostStatuses']}><PostTypes /></ProtectedRoute></PageWrapper>} />
        <Route path='/agent/settings/offensive-terms' element={<PageWrapper><ProtectedRoute permissions={['canManagePostTopics']}><OffensiveTerms /></ProtectedRoute></PageWrapper>} />
        <Route path='/agent/settings/badges' element={<PageWrapper><ProtectedRoute permissions={['canManageCommunityBadges']}><Badges /></ProtectedRoute></PageWrapper>} />

        <Route path='/*' element={<Navigate to='/help-center' />} />
      </>
    )
  );

  return (
    <RouterProvider router={router} />
  );
}

export default App;
