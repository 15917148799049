import { AtomSpinner, Card, Cell, Colors, Grid, Icon, Icons, PageButtons, Row, StyledHeading, StyledParagraph, Typography, View } from "@barscience/global-components";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import HelpCenterHeader from "./HelpCenterHeader";
import { gql, useQuery } from "@apollo/client";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import parse from 'html-react-parser';

/* Search Posts Query */
const SEARCH_POSTS = gql`
query searchCommunityPosts($query: String!, $page: Int!) {
  searchCommunityPosts(query: $query, page: $page) {
    posts {
      post {
        id
        title
        topic {
          id
          name
        }
      }
      textPreview
    }
    totalResultCount
    pages
  }
}
`;

type SearchPostsResponse = {
  searchCommunityPosts: {
    posts: PostSearchResult[];
    totalResultCount: number;
    pages: number;
  }
}

type PostSearchResult = {
  post: {
    id: string;
    title: string;
    topic: {
      id: string;
      name: string;
    }
  }
  textPreview: string;
}

/* Search Articles Query */
const SEARCH_ARTICLES = gql`
query searchSupportArticles($query: String!, $page: Int!) {
  searchSupportArticles(query: $query, page: $page) {
    articles {
      article {
        id
        title
        folder {
          id
          name
          category {
            id
            name
          }
        }
      }
      textPreview
    }
    pages
    totalResultCount
  }
}
`;

type SearchArticlesResponse = {
  searchSupportArticles: {
    articles: ArticleSearchResult[];
    pages: number;
    totalResultCount: number;
  }
}

type ArticleSearchResult = {
  article: {
    id: string;
    title: string;
    folder: {
      id: string;
      name: string;
      category: {
        id: string;
        name: string;
      }
    }
  }
  textPreview: string;
}

/* Page Styles */
const gridStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  boxSizing: 'border-box',
  columnGap: '32px',
  flexGrow: 1,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    columnGap: '16px',
  },
  '@media (max-width: 767px)': {
    columnGap: '8px',
  },
};

const cardStyle: CSSProperties = {
  ':hover': {
    backgroundColor: Colors.neutral50,
  },
  ':active': {
    backgroundColor: Colors.neutral100,
  },
}

const styles = StyleSheet.create({
  categoryLink: {
    color: Colors.neutral700,
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  highlight: {
    backgroundColor: Colors.secondary100,
    color: Colors.neutral900,
  },
});

export default function HelpCenterSearchResults() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(0);
  const { data: postData, loading: postsAreLoading } = useQuery<SearchPostsResponse>(SEARCH_POSTS, {
    variables: {
      query: decodeURIComponent(searchParams.get('query') || ''),
      page: page,
    },
  });
  const { data: articleData, loading: articlesAreLoading } = useQuery<SearchArticlesResponse>(SEARCH_ARTICLES, {
    variables: {
      query: decodeURIComponent(searchParams.get('query') || ''),
      page: page,
    },
  });

  return (
    <Grid style={gridStyle}>
      <HelpCenterHeader searchQuery={decodeURIComponent(searchParams.get('query') || '')} />

      {(!postsAreLoading && !articlesAreLoading) &&
        <Cell lg={12} md={8} sm={4} style={{ marginTop: '32px', padding: '0 32px', '@media (min-width: 768px) and (max-width: 1151px)': { padding: '0 16px' }, '@media (max-width: 767px)': { padding: '0 16px' } }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <StyledParagraph>{(postData?.searchCommunityPosts.totalResultCount || 0) + (articleData?.searchSupportArticles.totalResultCount || 0)} result{((postData?.searchCommunityPosts.totalResultCount || 0) + (articleData?.searchSupportArticles.totalResultCount || 0)) === 1 ? '' : 's'} found</StyledParagraph>
            <PageButtons currentPage={page} onPageChange={setPage} numPages={Math.max(postData?.searchCommunityPosts.pages || 0, articleData?.searchSupportArticles.pages || 0)} />
          </View>
        </Cell>
      }

      {(postsAreLoading || articlesAreLoading) ?
        <Cell lg={12} md={8} sm={4} style={{ marginTop: '32px', padding: '0 32px', '@media (min-width: 768px) and (max-width: 1151px)': { padding: '0 16px' }, '@media (max-width: 767px)': { padding: '0 16px' } }}>
          <AtomSpinner size='large' />
        </Cell>
        :
        <Row style={{ marginTop: '32px', padding: '0 32px 48px 32px', '@media (min-width: 1152px)': { columnGap: '48px' }, '@media (min-width: 768px) and (max-width: 1151px)': { padding: '0 16px' }, '@media (max-width: 767px)': { padding: '0 16px' } }}>
          <Cell lg={6} md={4} sm={4}>
            <StyledHeading tag='h4'>Help Center</StyledHeading>
            <View style={{ gap: '16px', marginTop: '32px' }}>
              {articleData?.searchSupportArticles.articles.map((article) => {
                return (
                  <View onClick={() => { navigate(`/help-center/articles/${article.article.id}`); }} style={{ cursor: 'pointer' }} key={article.article.id}>
                    <Card size='medium' style={cardStyle}>
                      <View style={{ gap: '8px' }}>
                        <StyledParagraph bold>{article.article.title}</StyledParagraph>
                        <StyledParagraph style={{ color: Colors.neutral700 }}>{parse(article.textPreview, {
                          replace: (domNode) => {
                            if (domNode.type === 'tag' && domNode.name === 'b') {
                              const child = domNode.firstChild;

                              if (child && child.type === 'text') {
                                return <span className={css(styles.highlight)}>{child.data}</span>;
                              }
                            }
                          },
                        })}</StyledParagraph>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px', marginTop: '16px' }}>
                          <Link to={`/help-center/categories/${article.article.folder.category.id}`} className={css(Typography.paragraph2, styles.categoryLink)}>{article.article.folder.category.name}</Link>
                          <Icon icon={Icons.ChevronRight} size='small' />
                          <StyledParagraph style={{ color: Colors.neutral700 }}>{article.article.folder.name}</StyledParagraph>
                        </View>
                      </View>
                    </Card>
                  </View>
                );
              })}
            </View>
            {articleData?.searchSupportArticles.articles.length === 0 &&
              <StyledParagraph>No results found.</StyledParagraph>
            }
          </Cell>
          <Cell lg={6} md={4} sm={4}>
            <StyledHeading tag='h4'>Community</StyledHeading>
            <View style={{ gap: '16px', marginTop: '32px' }}>
              {postData?.searchCommunityPosts.posts.map((post) => {
                return (
                  <View onClick={() => { navigate(`/community/posts/${post.post.id}`); }} style={{ cursor: 'pointer' }} key={post.post.id}>
                    <Card size='medium' style={cardStyle}>
                      <View style={{ gap: '8px' }}>
                        <StyledParagraph bold>{post.post.title}</StyledParagraph>
                        <StyledParagraph style={{ color: Colors.neutral700 }}>{parse(post.textPreview, {
                          replace: (domNode) => {
                            if (domNode.type === 'tag' && domNode.name === 'b') {
                              const child = domNode.firstChild;

                              if (child && child.type === 'text') {
                                return <span className={css(styles.highlight)}>{child.data}</span>;
                              }
                            }
                          },
                        })}</StyledParagraph>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px', marginTop: '16px' }}>
                          <Link to='/community' className={css(Typography.paragraph2, styles.categoryLink)}>Community</Link>
                          <Icon icon={Icons.ChevronRight} size='small' />
                          <Link to={`/community/topics/${post.post.topic.id}`} className={css(Typography.paragraph2, styles.categoryLink)}>{post.post.topic.name}</Link>
                        </View>
                      </View>
                    </Card>
                  </View>
                );
              })}
            </View>
            {postData?.searchCommunityPosts.posts.length === 0 &&
              <StyledParagraph>No results found.</StyledParagraph>
            }
          </Cell>
        </Row>}
    </Grid>
  )
}