import { Cell, Colors, Form, Icons, StyledHeading, TextField, View } from "@barscience/global-components";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

type HelpCenterHeaderProps = {
  searchQuery?: string;
}

export default function HelpCenterHeader(props: HelpCenterHeaderProps) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>(props.searchQuery || '');

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();

    if (!searchQuery || searchQuery.length < 3) return;
    
    navigate(`/help-center/search?query=${encodeURIComponent(searchQuery)}`);
  }

  return (
    <Cell lg={12} md={8} sm={4} style={{ backgroundColor: Colors.primary50, padding: '64px 32px' }}>
      <View style={{ alignItems: 'center', gap: '24px', marginTop: '32px', marginBottom: '64px', width: '100%' }}>
        <StyledHeading tag='h2' style={{ textAlign: 'center' }}>How can we help?</StyledHeading>
        <Form handleSubmit={handleSearch}>
          <TextField name='query' value={searchQuery} onChange={(_, value: string) => { setSearchQuery(value); }} placeholder='Start your search here...' icon={Icons.MagnifyingGlass} style={{ maxWidth: 'calc(100vw - 48px)', minWidth: '350px', width: '500px' }} />
        </Form>
      </View>
    </Cell>
  );
}