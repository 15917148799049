import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, Button, Card, Cell, Colors, ErrorPage, Grid, Icon, Icons, Link, StandardGrid, StyledHeading, StyledParagraph, View } from "@barscience/global-components";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import { Link as ReactLink } from 'react-router-dom';
import HelpCenterHeader from "./HelpCenterHeader";

/* Get Categories Query */
export const GET_CATEGORIES = gql`
query getSupportArticleCategoriesForHomepage {
  supportArticleCategoriesForHomepage {
    id
    name
    description
    icon
  }
}
`;

export type GetCategoriesResponse = {
  supportArticleCategoriesForHomepage: Category[] | null;
}

type Category = {
  id: string;
  name: string;
  description: string | null;
  icon: string | null;
}

/* Get Featured Articles Query */
const GET_FEATURED_ARTICLES = gql`
query getFeaturedSupportArticles {
  featuredSupportArticles {
    id
    title
  }
}
`;

export type GetFeaturedArticlesResponse = {
  featuredSupportArticles: {
    id: string;
    title: string;
  }[] | null;
}

/* Page Styles */
const gridStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  boxSizing: 'border-box',
  columnGap: '32px',
  flexGrow: 1,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    columnGap: '16px',
  },
  '@media (max-width: 767px)': {
    columnGap: '8px',
  },
};

const styles = StyleSheet.create({
  communityIcon: {
    height: '350px',
    width: '350px',
    '@media (min-width: 768px) and (max-width: 1151px)': {
      height: '250px',
      width: '250px',
    },
    '@media (max-width: 767px)': {
      height: '200px',
      width: '200px',
    },
  },
});

export default function HelpCenterHome() {
  const { data: categoriesData, loading: categoriesAreLoading, error: categoriesError } = useQuery<GetCategoriesResponse>(GET_CATEGORIES);
  const { data: featuredArticlesData, loading: featuredArticlesAreLoading, error: featuredArticlesError } = useQuery<GetFeaturedArticlesResponse>(GET_FEATURED_ARTICLES);

  if (categoriesError || featuredArticlesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <Grid style={gridStyle}>
      <HelpCenterHeader />

      <Cell lg={12} md={8} sm={4} style={{ marginTop: '32px', padding: '0 32px', '@media (min-width: 768px) and (max-width: 1151px)': { padding: '0 16px' }, '@media (max-width: 767px)': { padding: '0 16px' } }}>
        <View style={{ justifyContent: 'center', padding: '0 64px', '@media (min-width: 768px) and (max-width: 1151px)': { padding: '0 16px' }, '@media (max-width: 767px)': { padding: '0' } }}>
          <StyledHeading tag='h5'>Browse by category</StyledHeading>
          {categoriesAreLoading ?
            <AtomSpinner size='medium' />
            :
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '48px 24px', marginTop: '24px' }}>
              {categoriesData?.supportArticleCategoriesForHomepage?.map((category) => (
                <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, maxWidth: 'calc((100% - 48px) / 3)', gap: '16px', minWidth: 'min(100%, 250px)', '@media (min-width: 768px) and (max-width: 1151px)': { maxWidth: 'calc((100% - 24px) / 2)' }, '@media (max-width: 767px)': { maxWidth: '100%' } }} key={category.id}>
                  <Link href={`/help-center/categories/${category.id}`} linkStyle={{ alignItems: 'center', color: 'inherit', display: 'flex', flexDirection: 'row', gap: '16px', width: 'fit-content', ':active': { color: 'inherit' } }}>
                    {category.icon &&
                      <Card style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', minHeight: 'fit-content', minWidth: 'fit-content' }}>
                        <Icon size='large' icon={Icons.Folder} customFaIcon={category.icon} style={{ color: Colors.neutral900 }} />
                      </Card>
                    }
                    <View style={{ gap: '4px' }}>
                      <StyledHeading tag='h6' style={{ color: Colors.neutral900 }}>{category.name}</StyledHeading>
                      {category.description &&
                        <StyledParagraph style={{ color: Colors.neutral700 }}>{category.description}</StyledParagraph>
                      }
                    </View>
                  </Link>
                </View>
              ))}
            </View>
          }
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ marginTop: '64px', padding: '0 32px', '@media (min-width: 768px) and (max-width: 1151px)': { padding: '0 16px' } }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '32px', justifyContent: 'center' }}>
          <ReactLink to='/help-center/categories/e7ed3761-5fbd-4c6d-9f3c-61a3cc58de21' style={{ textDecoration: 'none' }}>
            <Card style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: '32px', padding: '0', ':hover': { backgroundColor: Colors.neutral100 }, ':active': { backgroundColor: Colors.neutral200 } }}>
              <View style={{ backgroundColor: Colors.primary500, color: '#ffffff', padding: '32px' }}>
                <i className='fa-sharp fa-solid fa-megaphone' style={{ height: '32px', width: '32px' }}></i>
              </View>
              <StyledParagraph style={{ color: Colors.neutral900, paddingRight: '32px' }} bold>Announcements</StyledParagraph>
            </Card>
          </ReactLink>
          <ReactLink to='/help-center/categories/1e7df893-add8-4975-b0c9-f6efdd51fca5' style={{ textDecoration: 'none' }}>
            <Card style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: '32px', padding: '0', ':hover': { backgroundColor: Colors.neutral100 }, ':active': { backgroundColor: Colors.neutral200 } }}>
              <View style={{ backgroundColor: Colors.neutral600, color: '#ffffff', padding: '32px' }}>
                <i className='fa-sharp fa-solid fa-file-contract' style={{ height: '32px', width: '32px' }}></i>
              </View>
              <StyledParagraph style={{ color: Colors.neutral900, paddingRight: '32px' }} bold>Policies and Guidelines</StyledParagraph>
            </Card>
          </ReactLink>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ marginTop: '64px', padding: '0 32px', '@media (min-width: 768px) and (max-width: 1151px)': { padding: '0 16px' } }}>
        <View style={{ gap: '8px', justifyContent: 'center', padding: '0 64px', '@media (min-width: 768px) and (max-width: 1151px)': { padding: '0 16px' }, '@media (max-width: 767px)': { padding: '0' } }}>
          <StyledHeading tag='h5'>Featured articles</StyledHeading>
          <View style={{ flexDirection: 'row', gap: '12px', width: '100%', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
            <View style={{ flexGrow: 1, gap: '12px' }}>
              {featuredArticlesAreLoading ?
                <AtomSpinner size='medium' />
                :
                <>
                  {featuredArticlesData?.featuredSupportArticles?.filter((_, i) => i < (featuredArticlesData.featuredSupportArticles?.length || 0) / 2).map((article) => (
                    <Link href={`/help-center/articles/${article.id}`} key={article.id}>{article.title}</Link>
                  ))}
                </>
              }
            </View>
            <View style={{ flexGrow: 1, gap: '12px' }}>
              {featuredArticlesAreLoading ?
                <AtomSpinner size='medium' />
                :
                <>
                  {featuredArticlesData?.featuredSupportArticles?.filter((_, i) => i >= (featuredArticlesData.featuredSupportArticles?.length || 0) / 2).map((article) => (
                    <Link href={`/help-center/articles/${article.id}`} key={article.id}>{article.title}</Link>
                  ))}
                </>
              }
            </View>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ backgroundColor: Colors.secondary50, padding: '16px 96px', marginTop: '64px', '@media (max-width: 767px)': { padding: '32px 32px' } }}>
        <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', gap: '128px', marginTop: '32px', marginBottom: '64px', width: '100%', '@media (max-width: 767px)': { flexDirection: 'column-reverse', gap: '24px' } }}>
          <View style={{ gap: '24px', '@media (max-width: 767px)': { alignItems: 'center' } }}>
            <View style={{ gap: '8px', '@media (max-width: 767px)': { alignItems: 'center' } }}>
              <StyledHeading tag='h2' style={{ '@media (max-width: 767px)': { textAlign: 'center' } }}>Ask the community</StyledHeading>
              <StyledParagraph style={{ color: Colors.neutral700, '@media (max-width: 767px)': { textAlign: 'center' } }}>Get tips, tricks, and help from our community.</StyledParagraph>
            </View>

            <Button label='Join the conversation' role='link' href='/community' variant='primary' style={{ maxWidth: 'fit-content' }} />
          </View>

          <img src='illustrations/community.svg' className={css(styles.communityIcon)} alt='An illustration of an online global community' />
        </View>
      </Cell>
    </Grid>
  );
}