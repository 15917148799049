import { Header, Icons, Sidebar, SidebarItem, SidebarSection, useAlertState, useAuthState, View } from "@barscience/global-components";
import React, { useState } from "react";
import useAgentAuthState from "./auth/useAgentAuthState";
import HeaderActions from "./HeaderActions";

type PageWrapperProps = {
  children: React.ReactNode;
}

export default function PageWrapper(props: PageWrapperProps) {
  const { state } = useAuthState();
  const { state: agentState } = useAgentAuthState();
  const { alerts } = useAlertState();
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  return (
    <>
      {alerts.length > 0 &&
        <View style={{ bottom: '24px', gap: '16px', left: '24px', position: 'fixed', zIndex: 1 }}>
          {alerts.map((a) => {
            return (
              <React.Fragment key={a.id}>
                {a.alert}
              </React.Fragment>
            );
          })}
        </View>
      }
      <View style={{ height: '100vh' }}>
        <Header product='support' onSidebarClick={toggleSidebar} showLoginButton actionButton={state.user ? <HeaderActions /> : null} />

        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          <Sidebar isOpen={sidebarIsOpen}>
            {agentState.user?.isSupportAgent &&
              <SidebarSection label='Support'>
                <SidebarItem label='Tickets' to='/agent/tickets' icon={Icons.Receipt} includeSubpaths />
                <SidebarItem label='Settings' to='/agent/settings' icon={Icons.Admin} includeSubpaths />
              </SidebarSection>
            }

            <SidebarSection label='Help Center'>
              {agentState.user?.isSupportAgent &&
                <SidebarItem label='Knowledge Base' to='/agent/kb' icon={Icons.KnowledgeBase} includeSubpaths />
              }

              {!agentState.user?.isSupportAgent &&
                <SidebarItem label='Help Center' to='/help-center' icon={Icons.KnowledgeBase} includeSubpaths />
              }

              {agentState.user?.supportAgentPermissions?.canModerateCommunityContent &&
                <SidebarItem label='Moderation' to='/agent/moderation' icon={Icons.Moderation} includeSubpaths />
              }

              <SidebarItem label='Community' to='/community' icon={Icons.Community} includeSubpaths excludedSubpaths={['/community/users/me', `/community/users/${state.user?.id}`]} />
              
              {state.user &&
                <SidebarItem label='Profile' to={`/community/users/${state.user.id}`} icon={Icons.User} />
              }
            </SidebarSection>
          </Sidebar>

          {props.children}

        </View>
      </View>
    </>
  );
}