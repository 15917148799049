import { AtomSpinner, AutoOpen, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, ConfirmModal, ErrorPage, InfoPanel, Link, ModalLauncher, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, View, generateId, useAlertState } from "@barscience/global-components";
import useAgentAuthState from "../../../components/auth/useAgentAuthState";
import { gql, useMutation, useQuery } from "@apollo/client";
import { GET_AGENT_ROLES, GetAgentRolesResponse } from "./AgentRoles";
import { useState } from "react";
import AddAgentModal from "./AddAgentModal";

/* Get Support Agents Query */
const GET_SUPPORT_AGENTS = gql`
query getAllSupportAgents {
  supportAgents {
    id
    firstName
    lastName
    email
    supportAgentRole {
      id
      name
    }
    isSupportAgent
  }
}
`;

type GetSupportAgentsResponse = {
  supportAgents: SupportAgent[] | null;
}

type SupportAgent = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  supportAgentRole: {
    id: string;
    name: string;
  };
  isSupportAgent: boolean;
}

/* Change Role Mutation */
export const SET_ROLE = gql`
mutation setSupportAgentRole($userId: ID!, $roleId: ID) {
  setSupportAgentRole(userId: $userId, roleId: $roleId) {
    id
    firstName
    lastName
    email
    supportAgentRole {
      id
      name
    }
    isSupportAgent
  }
}
`;

export type SetRoleResponse = {
  setSupportAgentRole: SupportAgent | null;
}

/* Page State */
type ChangeRoleInput = {
  userId: string;
  roleId: string;
}

export default function Agents() {
  const { addAlert } = useAlertState();
  const { state } = useAgentAuthState();
  const [changes, setChanges] = useState<ChangeRoleInput | null>(null);
  const { data: supportAgentData, loading: supportAgentsAreLoading, error: supportAgentsError } = useQuery<GetSupportAgentsResponse>(GET_SUPPORT_AGENTS);
  const { data: roleData, loading: rolesAreLoading, error: rolesError } = useQuery<GetAgentRolesResponse>(GET_AGENT_ROLES);
  const [setRole] = useMutation<SetRoleResponse>(SET_ROLE, {
    update(cache, { data }) {
      if (data) {
        if (!data.setSupportAgentRole?.isSupportAgent) {
          cache.modify({
            fields: {
              supportAgents(existingAgents = [], { readField }) {
                return existingAgents.filter((agentRef: any) => {
                  return data.setSupportAgentRole?.id !== readField('id', agentRef);
                });
              },
            },
          });
        }
      }
    }
  });

  const handleConfirmRoleChange = async () => {
    if (!changes) {
      return;
    }

    const { errors } = await setRole({
      variables: {
        userId: changes.userId,
        roleId: changes.roleId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to change role' description={errors[0].message} type='error' id={id} />;
      addAlert(id, alert);
    }
  }

  const confirmRoleChangeModal = () => {
    const user = supportAgentData?.supportAgents?.find((u) => u.id === changes?.userId);
    const role = roleData?.supportAgentRoles?.find((r) => r.id === changes?.roleId);

    if (!user || !role) {
      return null;
    }

    return (
      <ConfirmModal title='Confirm Role Change' onConfirm={handleConfirmRoleChange} onCancel={() => { setChanges(null); }} confirmLabel='Confirm Change'>
        <View style={{ gap: '16px' }}>
          <StyledParagraph>You are changing the role of <span style={{ fontWeight: 600 }}>{user.firstName} {user.lastName}</span> to <span style={{ fontWeight: 600 }}>{role.name}</span>.</StyledParagraph>
          <StyledParagraph>Please confirm that this is the correct role for this user.</StyledParagraph>
          <InfoPanel type='info'>
            <StyledParagraph>Not sure what this role can do? <Link href={`/agent/settings/agent-roles/${role.id}`} target='_blank'>View its permissions here.</Link></StyledParagraph>
          </InfoPanel>
        </View>
      </ConfirmModal>
    );
  }

  const handleRemoveAgent = async (userId: string) => {
    const { data, errors } = await setRole({
      variables: {
        userId: userId,
        roleId: null,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to remove support agent' description={errors[0].message} type='error' id={id} />;
      addAlert(id, alert);
    }

    if (data) {
      const id = generateId();
      const alert = <StandardAlert title='Support agent removed' type='success' id={id} />;
      addAlert(id, alert);
    }
  }

  const removeAgentModal = (
    <ConfirmModal title='Remove Support Agent?' onConfirm={handleRemoveAgent} confirmLabel='Remove' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This user will no longer have access to the support agent tools.</StyledParagraph>
        <StyledParagraph>You can always add them as an agent again later.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  if (supportAgentsError || rolesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      {changes !== null &&
        <ModalLauncher modal={confirmRoleChangeModal()} key={changes.userId + '-' + changes.roleId}>
          {({ openModal }) => (
            <AutoOpen openModal={openModal} />
          )}
        </ModalLauncher>
      }

      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/agent/settings' />
          <Breadcrumb label='Support Agents' />
        </BreadcrumbGroup>
      </Cell>

      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
          <StyledHeading tag='h4'>Support Agents</StyledHeading>
          <ModalLauncher modal={<AddAgentModal />}>
            {({ openModal }) => (
              <Button label='Add Support Agent' variant='primary' role='button' action={openModal} />
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <Card size='medium'>
          {(supportAgentsAreLoading || rolesAreLoading) ?
            <View>
              <AtomSpinner size='medium' />
            </View>
            :
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>User</TableHeaderCell>
                  <TableHeaderCell>Email</TableHeaderCell>
                  <TableHeaderCell>Role</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {supportAgentData?.supportAgents?.map((u) => {
                  return (
                    <TableRow key={u.id}>
                      <TableCell><Link href={`/agent/settings/agents/${u.id}`}>{u.firstName} {u.lastName}</Link></TableCell>
                      <TableCell>{u.email}</TableCell>
                      <TableCell style={{ padding: '8px 0' }}>
                        <SingleSelect name={`user-${u.id}-role`} value={u.supportAgentRole.id} style={{ maxWidth: '250px', minWidth: '250px' }} filterable onChange={(_, value) => { setChanges({ userId: u.id, roleId: value || '' }); }} disabled={u.id === state.user?.id} key={u.id + '-' + u.supportAgentRole.id}>
                          {roleData?.supportAgentRoles?.map((role) => (
                            <Choice label={role.name} description={role.description || undefined} value={role.id} disabled={role.id === u.supportAgentRole.id} key={role.id} />
                          ))}
                        </SingleSelect>
                      </TableCell>
                      <TableCell>
                        <ModalLauncher modal={removeAgentModal}>
                          {({ openModal }) => (
                            <Button label='Remove' variant='tertiary' role='button' action={() => { openModal(u.id); }} destructive disabled={u.id === state.user?.id} />
                          )}
                        </ModalLauncher>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          }
        </Card>
      </Cell>
    </StandardGrid>
  );
}