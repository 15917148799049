import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Checkbox, Colors, ConfirmModal, ErrorPage, FormModal, Icon, Icons, ModalLauncher, NotFound, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextArea, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";
import HasAgentPermission from "../../../components/auth/HasAgentPermission";

/* Get Agent Role Details Query */
const GET_ROLE_DETAILS = gql`
query getSupportAgentRoleDetails($id: ID!) {
  supportAgentRole(id: $id) {
    id
    name
    description
    permissions {
      canChangeTicketAssignee
      canEditTicketProperties
      canEditTicketSkills
      canMergeTickets
      canDeleteTickets
      canCreatePublicReplies
      canEditEndUserAccess
      canCreateTickets
      canManageAgents
      canManageAgentRoles
      canManageAgentGroups
      canManageAgentStatuses
      canManageViews
      canManageMacros
      canManageCannedResponses
      canManageTicketFormsAndFields
      canManageRouting
      canManageSkills
      canEditAgentSkills
      canManageSLAs
      canModerateCommunityContent
      canManagePostTopics
      canManageAllArticles
      canManageCommunityBadges
      canManagePostStatuses
      canSetPostStatuses
    }
  }
}
`;

type GetRoleDetailsResponse = {
  supportAgentRole: AgentRole | null;
}

type AgentRole = {
  id: string;
  name: string;
  description: string | null;
  permissions: AgentRolePermissions;
}

type AgentRolePermissions = {
  canChangeTicketAssignee: boolean;
  canEditTicketProperties: boolean;
  canEditTicketSkills: boolean;
  canMergeTickets: boolean;
  canDeleteTickets: boolean;
  canCreatePublicReplies: boolean;
  canEditEndUserAccess: boolean;
  canCreateTickets: boolean;
  canManageAgents: boolean;
  canManageAgentRoles: boolean;
  canManageAgentGroups: boolean;
  canManageAgentStatuses: boolean;
  canManageViews: boolean;
  canManageMacros: boolean;
  canManageCannedResponses: boolean;
  canManageTicketFormsAndFields: boolean;
  canManageRouting: boolean;
  canManageSkills: boolean;
  canEditAgentSkills: boolean;
  canManageSLAs: boolean;
  canModerateCommunityContent: boolean;
  canManagePostTopics: boolean;
  canManageAllArticles: boolean;
  canManageCommunityBadges: boolean;
  canManagePostStatuses: boolean;
  canSetPostStatuses: boolean;
}

/* Edit Role Mutation */
const EDIT_ROLE = gql`
mutation editSupportAgentRole($id: ID!, $input: EditSupportAgentRoleInput!) {
  editSupportAgentRole(id: $id, input: $input) {
    id
    name
    description
    permissions {
      canChangeTicketAssignee
      canEditTicketProperties
      canEditTicketSkills
      canMergeTickets
      canDeleteTickets
      canCreatePublicReplies
      canEditEndUserAccess
      canCreateTickets
      canManageAgents
      canManageAgentRoles
      canManageAgentGroups
      canManageAgentStatuses
      canManageViews
      canManageMacros
      canManageCannedResponses
      canManageTicketFormsAndFields
      canManageRouting
      canManageSkills
      canEditAgentSkills
      canManageSLAs
      canModerateCommunityContent
      canManagePostTopics
      canManageAllArticles
      canManageCommunityBadges
      canManagePostStatuses
      canSetPostStatuses
    }
  }
}
`;

type EditRoleResponse = {
  editSupportAgentRole: AgentRole;
}

type EditRoleInput = {
  name: string;
  description: string;
}

/* Delete Role Mutation */
const DELETE_ROLE = gql`
mutation deleteSupportAgentRole($id: ID!) {
  deleteSupportAgentRole(id: $id) {
    id
  }
}
`;

type DeleteRoleResponse = {
  deleteSupportAgentRole: {
    id: string;
  } | null;
}

/* Permission Maps */
type PermissionList = {
  id: keyof AgentRolePermissions;
  label: string;
  category: 'Tickets' | 'Users' | 'Admin' | 'Knowledge Base' | 'Community';
}

const PERMISSIONS: PermissionList[] = [
  {
    id: 'canChangeTicketAssignee',
    label: 'Change ticket assignee',
    category: 'Tickets',
  },
  {
    id: 'canEditTicketProperties',
    label: 'Edit ticket properties',
    category: 'Tickets',
  },
  {
    id: 'canEditTicketSkills',
    label: 'Edit ticket skills',
    category: 'Tickets',
  },
  {
    id: 'canMergeTickets',
    label: 'Merge tickets',
    category: 'Tickets',
  },
  {
    id: 'canDeleteTickets',
    label: 'Delete tickets',
    category: 'Tickets',
  },
  {
    id: 'canCreatePublicReplies',
    label: 'Post public replies to tickets (in addition to private notes)',
    category: 'Tickets',
  },
  {
    id: 'canCreateTickets',
    label: 'Create tickets',
    category: 'Tickets',
  },
  {
    id: 'canEditEndUserAccess',
    label: 'Manage end user ticket access',
    category: 'Users',
  },
  {
    id: 'canManageAgents',
    label: 'Manage support agents',
    category: 'Users',
  },
  {
    id: 'canManageAgentRoles',
    label: 'Manage agent roles (only grant this to super admins)',
    category: 'Users',
  },
  {
    id: 'canManageAgentGroups',
    label: 'Manage agent group assignments',
    category: 'Users',
  },
  {
    id: 'canManageAgentStatuses',
    label: 'Manage agent statuses',
    category: 'Users',
  },
  {
    id: 'canEditAgentSkills',
    label: 'Manage agent skills',
    category: 'Users',
  },
  {
    id: 'canManageViews',
    label: 'Manage global ticket views',
    category: 'Admin',
  },
  {
    id: 'canManageMacros',
    label: 'Manage global ticket macros',
    category: 'Admin',
  },
  {
    id: 'canManageCannedResponses',
    label: 'Manage global canned responses',
    category: 'Admin',
  },
  {
    id: 'canManageTicketFormsAndFields',
    label: 'Manage ticket forms and fields',
    category: 'Admin',
  },
  {
    id: 'canManageRouting',
    label: 'Manage ticket routing configurations',
    category: 'Admin',
  },
  {
    id: 'canManageSkills',
    label: 'Manage ticket skill definitions',
    category: 'Admin',
  },
  {
    id: 'canManageSLAs',
    label: 'Manage SLA definitions and assignments',
    category: 'Admin',
  },
  {
    id: 'canManageAllArticles',
    label: 'Manage and edit all articles',
    category: 'Knowledge Base',
  },
  {
    id: 'canManagePostTopics',
    label: 'Manage post topics and categories',
    category: 'Community',
  },
  {
    id: 'canModerateCommunityContent',
    label: 'Moderate community content',
    category: 'Community',
  },
  {
    id: 'canManageCommunityBadges',
    label: 'Manage community badges',
    category: 'Community',
  },
  {
    id: 'canManagePostStatuses',
    label: 'Manage post status options',
    category: 'Community',
  },
  {
    id: 'canSetPostStatuses',
    label: 'Set post statuses',
    category: 'Community',
  },
];

// A mapping of all permissions to false
const DEFAULT_PERMISSIONS = Object.fromEntries(PERMISSIONS.map(permission => [permission.id, false])) as AgentRolePermissions;

export default function AgentRoleDetails() {
  const navigate = useNavigate();
  const { addAlert } = useAlertState();
  const { id } = useParams();
  const { data: roleData, loading: roleIsLoading, error: roleError } = useQuery<GetRoleDetailsResponse>(GET_ROLE_DETAILS, {
    variables: {
      id: id,
    },
    fetchPolicy: 'network-only',
  });
  const [editRole] = useMutation<EditRoleResponse>(EDIT_ROLE);
  const [deleteRole] = useMutation<DeleteRoleResponse>(DELETE_ROLE);

  const handleEditRole = async (values: EditRoleInput) => {
    let permissions = roleData?.supportAgentRole?.permissions as any;
    delete permissions.__typename;

    const { errors } = await editRole({
      variables: {
        id: id,
        input: {
          name: values.name,
          description: values.description ? values.description : null,
          permissions: permissions,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error updating role' description={errors[0].message} type='error' id='id' />
      addAlert(id, alert);
    }
  }

  const editRoleModal = (
    <FormModal<EditRoleInput> title='Edit Role' onSubmit={handleEditRole} initialValues={{ name: roleData?.supportAgentRole?.name || '', description: roleData?.supportAgentRole?.description || '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Role Name' name='name' required />
        <TextArea label='Description' name='description' />
      </View>
    </FormModal>
  );

  const handleEditRolePermissions = async (values: AgentRolePermissions) => {
    let permissions = values as any;
    delete permissions.__typename;

    const { errors } = await editRole({
      variables: {
        id: id,
        input: {
          name: roleData?.supportAgentRole?.name,
          description: roleData?.supportAgentRole?.description,
          permissions: permissions,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error updating role permissions' description={errors[0].message} type='error' id='id' />
      addAlert(id, alert);
    }
  }

  const editPermissionsModal = (
    <FormModal<AgentRolePermissions> title='Edit Permissions' onSubmit={handleEditRolePermissions} initialValues={roleData?.supportAgentRole?.permissions || DEFAULT_PERMISSIONS}>
      <View style={{ gap: '16px' }}>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Tickets</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Tickets').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.supportAgentRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Community</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Community').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.supportAgentRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Knowledge Base</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Knowledge Base').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.supportAgentRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Users</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Users').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.supportAgentRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
        <View style={{ gap: '8px' }}>
          <StyledParagraph bold>Admin</StyledParagraph>
          <View style={{ gap: '4px' }}>
            {PERMISSIONS.filter(permission => permission.category === 'Admin').map(permission => (
              <Checkbox label={permission.label} name={permission.id} checked={roleData?.supportAgentRole?.permissions[permission.id] || false} key={permission.id} />
            ))}
          </View>
        </View>
      </View>
    </FormModal >
  );

  const handleDeleteRole = async () => {
    const { errors } = await deleteRole({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting role' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Role deleted' type='success' id={id} />
      addAlert(id, alert, 10);

      navigate('/agent/settings/agent-roles');
    }
  }

  const deleteRoleModal = (
    <ConfirmModal title='Delete Role?' onConfirm={handleDeleteRole} confirmLabel='Delete' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This role will be permanently deleted.</StyledParagraph>
        <StyledParagraph>Note: This action will fail if any agents are currently assigned to the role.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  if (roleError && roleError.graphQLErrors[0].extensions.status === 404) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  if (roleError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  if (roleIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/agent/settings' />
          <Breadcrumb label='Agent Roles' to='/agent/settings/agent-roles' />
          <Breadcrumb label={roleData?.supportAgentRole?.name || ''} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ gap: '16px' }}>
            <StyledHeading tag='h4'>{roleData?.supportAgentRole?.name}</StyledHeading>
            {roleData?.supportAgentRole?.description && <StyledParagraph style={{ color: Colors.neutral700 }}>{roleData.supportAgentRole.description}</StyledParagraph>}
          </View>

          <HasAgentPermission permissions={['canManageAgentRoles']}>
            <ModalLauncher modal={editRoleModal}>
              {({ openModal: openEditModal }) => (
                <ModalLauncher modal={deleteRoleModal}>
                  {({ openModal: openDeleteModal }) => (
                    <ActionMenu alignment='right'>
                      <ActionItem label='Edit' onClick={openEditModal} />
                      <ActionItem label='Delete' onClick={openDeleteModal} />
                    </ActionMenu>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </HasAgentPermission>
        </View>
      </Cell>
      <Cell lg={6} md={6} sm={4}>
        <Card size='medium'>
          <View style={{ gap: '24px' }}>
            <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
              <StyledHeading tag='h5'>Permissions</StyledHeading>
              <HasAgentPermission permissions={['canManageAgentRoles']}>
                <ModalLauncher modal={editPermissionsModal}>
                  {({ openModal }) => (
                    <Button label='Edit' variant='tertiary' role='button' action={openModal} />
                  )}
                </ModalLauncher>
              </HasAgentPermission>
            </View>

            <View style={{ gap: '8px' }}>
              <StyledParagraph bold>Tickets</StyledParagraph>
              <View style={{ gap: '4px' }}>
                {PERMISSIONS.filter(permission => permission.category === 'Tickets').map(permission => (
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                    <StyledParagraph>{permission.label}</StyledParagraph>
                    {roleData?.supportAgentRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                  </View>
                ))}
              </View>
            </View>

            <View style={{ gap: '8px' }}>
              <StyledParagraph bold>Community</StyledParagraph>
              <View style={{ gap: '4px' }}>
                {PERMISSIONS.filter(permission => permission.category === 'Community').map(permission => (
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                    <StyledParagraph>{permission.label}</StyledParagraph>
                    {roleData?.supportAgentRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                  </View>
                ))}
              </View>
            </View>

            <View style={{ gap: '8px' }}>
              <StyledParagraph bold>Knowledge Base</StyledParagraph>
              <View style={{ gap: '4px' }}>
                {PERMISSIONS.filter(permission => permission.category === 'Knowledge Base').map(permission => (
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                    <StyledParagraph>{permission.label}</StyledParagraph>
                    {roleData?.supportAgentRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                  </View>
                ))}
              </View>
            </View>

            <View style={{ gap: '8px' }}>
              <StyledParagraph bold>Users</StyledParagraph>
              <View style={{ gap: '4px' }}>
                {PERMISSIONS.filter(permission => permission.category === 'Users').map(permission => (
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                    <StyledParagraph>{permission.label}</StyledParagraph>
                    {roleData?.supportAgentRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                  </View>
                ))}
              </View>
            </View>

            <View style={{ gap: '8px' }}>
              <StyledParagraph bold>Admin</StyledParagraph>
              <View style={{ gap: '4px' }}>
                {PERMISSIONS.filter(permission => permission.category === 'Admin').map(permission => (
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }} key={permission.id}>
                    <StyledParagraph>{permission.label}</StyledParagraph>
                    {roleData?.supportAgentRole?.permissions[permission.id] ? <Icon size='medium' icon={Icons.CircleCheckmark} style={{ color: Colors.primary500 }} key={`${permission.id}-icon-true`} /> : <Icon size='medium' icon={Icons.CircleX} style={{ color: Colors.error500 }} key={`${permission.id}-icon-false`} />}
                  </View>
                ))}
              </View>
            </View>
          </View>
        </Card>
      </Cell>
    </StandardGrid>
  );
}