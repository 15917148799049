import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, CircularSpinner, Colors, ConfirmModal, ErrorPage, FormModal, Icons, InfoPanel, Link as StyledLink, Modal, ModalBody, ModalFooter, ModalHeader, ModalLauncher, NotFound, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextField, View, generateId, useAlertState, NoPermission, Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell, Link, Icon, Tooltip } from "@barscience/global-components";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { KNOWLEDGE_BASE_PERMISSIONS_INFO_LINK } from "./KBAdminHome";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import { ArticleVisibility, parseArticleVisibility } from "./utils";
import ReactTimeago from "react-timeago";
import { GetArticleResponse } from "./KBAdminArticle";
import { SEARCH_AGENTS, SearchAgentsResponse } from "./KBAdminCategory";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

/* Get Folder Query */
const GET_FOLDER = gql`
query getSupportArticleFolderDetailsForAdmins($id: ID!) {
  supportArticleFolder(id: $id) {
    id
    name
    description
    canEdit
    canPublish
    category {
      id
      name
      canPublish
      canEdit
    }
    articles {
      id
      created
      author {
        id
        firstName
        lastName
      }
      title
      visibility
      draftRevision {
        isReadyToPublish
        lastUpdated
      }
      publishedRevision {
        published
        lastUpdated
      }
    }
  }
}
`;

type GetFolderResponse = {
  supportArticleFolder: Folder | null;
}

type Folder = {
  id: string;
  name: string;
  description: string | null;
  canEdit: boolean;
  canPublish: boolean;
  category: {
    id: string;
    name: string;
    canPublish: boolean;
    canEdit: boolean;
  };
  articles: Article[];
}

type Article = {
  id: string;
  created: string;
  author: {
    id: string;
    firstName: string;
    lastName: string;
  }
  title: string;
  visibility: ArticleVisibility;
  draftRevision: {
    isReadyToPublish: boolean;
    lastUpdated: string;
  } | null;
  publishedRevision: {
    published: string;
    lastUpdated: string;
  } | null;
}

/* Edit Folder Mutation */
const EDIT_FOLDER = gql`
mutation editSupportArticleFolder($id: ID!, $input: EditSupportArticleFolderInput!) {
  editSupportArticleFolder(id: $id, input: $input) {
    id
    name
    description
  }
}
`;

type EditFolderInput = {
  name: string;
  description: string;
}

/* Delete Folder Mutation */
const DELETE_FOLDER = gql`
mutation deleteSupportArticleFolder($id: ID!) {
  deleteSupportArticleFolder(id: $id) {
    id
  }
}
`;

/* Edit Article Order Mutation */
const EDIT_ARTICLE_ORDER = gql`
mutation editSupportArticleOrder($folderId: ID!, $order: [ID!]!) {
  success: editSupportArticleOrder(folderId: $folderId, order: $order)
}
`;

type EditArticleOrderResponse = {
  success: boolean;
}

/* Get Folder Permissions Query */
const GET_FOLDER_PERMISSIONS = gql`
query getPermissionsForSupportArticleFolder($id: ID!) {
  permissionsForSupportArticleFolder(id: $id) {
    user {
      id
      firstName
      lastName
      email
    }
    permission
  }
}
`;

type GetFolderPermissionsResponse = {
  permissionsForSupportArticleFolder: PermissionAssignment[] | null;
}

type PermissionAssignment = {
  user: User;
  permission: Permission;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string | null;
}

enum Permission {
  EDIT = 'EDIT',
  PUBLISH = 'PUBLISH',
}

/* Grant Permission Mutation */
const GRANT_PERMISSION = gql`
mutation grantSupportArticleFolderPermission($folderId: ID!, $userId: ID!, $permission: KnowledgeBasePermission!) {
  grantSupportArticleFolderPermission(folderId: $folderId, userId: $userId, permission: $permission) {
    user {
      id
      firstName
      lastName
      email
    }
    permission
  }
}
`;

type GrantPermissionResponse = {
  grantSupportArticleFolderPermission: PermissionAssignment | null;
}

/* Edit Permission Mutation */
const EDIT_PERMISSION = gql`
mutation editSupportArticleFolderPermission($folderId: ID!, $userId: ID!, $permission: KnowledgeBasePermission!) {
  editSupportArticleFolderPermission(folderId: $folderId, userId: $userId, permission: $permission) {
    user {
      id
    }
    permission
  }
}
`;

type EditPermissionResponse = {
  editSupportArticleFolderPermission: {
    user: {
      id: string;
    };
    permission: Permission;
  } | null;
}

/* Revoke Permission Mutation */
const REVOKE_PERMISSION = gql`
mutation revokeSupportArticleFolderPermission($folderId: ID!, $userId: ID!) {
  success: revokeSupportArticleFolderPermission(folderId: $folderId, userId: $userId)
}
`;

type RevokePermissionResponse = {
  success: boolean;
}

/* Create Article Mutation */
const CREATE_ARTICLE = gql`
mutation createSupportArticle($input: CreateSupportArticleInput!) {
  supportArticle: createSupportArticle(input: $input) {
    id
    folder {
      id
      name
      category {
        id
        name
      }
    }
    title
    created
    author {
      id
      firstName
      lastName
    }
    canEdit
    canEditProperties
    canPublish
    visibility
    publishedRevision {
      id
      title
      body
      published
      publishedBy {
        id
        firstName
        lastName
      }
    }
    draftRevision {
      id
      title
      body
      created
      isReadyToPublish
      lastUpdated
      lastUpdatedBy {
        id
        firstName
        lastName
      }
    }
  }
}
`;

type CreateArticleInput = {
  title: string;
  visibility: ArticleVisibility;
}

export default function KBAdminFolder() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { addAlert } = useAlertState();
  const { state: agentState } = useAgentAuthState();
  const { data: folderData, loading: folderIsLoading, error: folderError } = useQuery<GetFolderResponse>(GET_FOLDER, {
    variables: {
      id: id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.supportArticleFolder) {
        setArticleOrder(data.supportArticleFolder.articles);
      }
    },
  });
  const [editFolder] = useMutation(EDIT_FOLDER);
  const [deleteFolder] = useMutation(DELETE_FOLDER, {
    update(cache, { data }) {
      if (data?.deleteSupportArticleFolder && folderData?.supportArticleFolder?.category) {
        cache.modify({
          id: cache.identify(folderData.supportArticleFolder.category),
          fields: {
            folders(existingFolders = [], { readField }) {
              return existingFolders.filter((folderRef: any) => data.deleteSupportArticleFolder?.id !== readField('id', folderRef));
            },
          }
        });
      }
    },
  });
  const [editArticleOrder, { loading: editArticleOrderIsLoading }] = useMutation<EditArticleOrderResponse>(EDIT_ARTICLE_ORDER);
  const [createArtice] = useMutation<GetArticleResponse>(CREATE_ARTICLE, {
    update(cache, { data }) {
      if (data?.supportArticle) {
        cache.modify({
          id: cache.identify(data.supportArticle.folder),
          fields: {
            articles(existingArticles = []) {
              const newArticleRef = cache.writeFragment({
                data: data.supportArticle,
                fragment: gql`
                  fragment NewArticle on SupportArticle {
                    id
                    title
                    created
                    author {
                      id
                      firstName
                      lastName
                    }
                    canEdit
                    canEditProperties
                    canPublish
                    visibility
                    publishedRevision {
                      id
                      title
                      body
                      published
                      publishedBy {
                        id
                        firstName
                        lastName
                      }
                    }
                    draftRevision {
                      id
                      title
                      body
                      created
                      isReadyToPublish
                      lastUpdated
                      lastUpdatedBy {
                        id
                        firstName
                        lastName
                      }
                    }
                  }
                `,
              });

              return [...existingArticles, newArticleRef];
            },
          },
        });
      }
    },
  });
  const [isEditingOrder, setIsEditingOrder] = useState<boolean>(false);
  const [articleOrder, setArticleOrder] = useState<Article[]>([]);

  /* Edit Folder */
  const handleEditFolder = async (values: EditFolderInput) => {
    const { errors } = await editFolder({
      variables: {
        id: id,
        input: {
          name: values.name,
          description: values.description ? values.description : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update folder' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editFolderModal = (
    <FormModal<EditFolderInput> title='Edit Folder' onSubmit={handleEditFolder} initialValues={{ name: folderData?.supportArticleFolder?.name || '', description: folderData?.supportArticleFolder?.description || '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Name' name='name' required />
        <TextField label='Description' name='description' />
      </View>
    </FormModal>
  );

  /* Delete Folder */
  const handleDeleteFolder = async () => {
    const { errors } = await deleteFolder({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to delete folder' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Folder deleted' type='success' id={id} />
      addAlert(id, alert);

      navigate(`/agent/kb/categories/${folderData?.supportArticleFolder?.category.id}`);
    }
  }

  const deleteFolderModal = (
    <ConfirmModal title='Delete Folder?' confirmLabel='Delete' destructive onConfirm={handleDeleteFolder}>
      <StyledParagraph>This folder will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  /* Create Article */
  const handleCreateArticle = async (values: CreateArticleInput) => {
    const { data, errors } = await createArtice({
      variables: {
        input: {
          folderId: id,
          title: values.title,
          visibility: values.visibility,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to create article' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      navigate(`/agent/kb/articles/${data?.supportArticle?.id}`);
    }
  }

  const createArticleModal = (
    <FormModal<CreateArticleInput> title='Create Article' submitLabel='Create' onSubmit={handleCreateArticle} initialValues={{ title: '', visibility: ArticleVisibility.PUBLIC }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Title' name='title' required />
        <SingleSelect label='Visibility' name='visibility' required>
          <Choice label='Public' value={ArticleVisibility.PUBLIC} />
          <Choice label='Logged in users' value={ArticleVisibility.LOGGED_IN_USERS} />
          <Choice label='Agents only' value={ArticleVisibility.AGENTS_ONLY} />
        </SingleSelect>
      </View>
    </FormModal>
  );

  /* Edit Folder Order */
  const handleDragEnd = async (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const newArticleOrder = Array.from(articleOrder);
    newArticleOrder.splice(source.index, 1);
    newArticleOrder.splice(destination.index, 0, articleOrder[source.index]);

    setArticleOrder(newArticleOrder);
  }

  const handleSaveOrder = async () => {
    const { errors } = await editArticleOrder({
      variables: {
        folderId: id,
        order: articleOrder.map((article) => article.id),
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error saving article order' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Article order saved' type='success' id={id} />
      addAlert(id, alert);
    }

    setIsEditingOrder(false);
  }

  if (!agentState.user?.isSupportAgent) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (folderIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (folderError || !folderData?.supportArticleFolder) {
    if (folderError?.graphQLErrors[0]?.extensions.status === 404) {
      return (
        <StandardGrid>
          <NotFound />
        </StandardGrid>
      );
    }

    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Knowledge Base' to='/agent/kb' />
          <Breadcrumb label={folderData.supportArticleFolder.category.name} to={`/agent/kb/categories/${folderData.supportArticleFolder.category.id}`} />
          <Breadcrumb label={folderData.supportArticleFolder.name} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
          <StyledHeading tag='h4'>{folderData.supportArticleFolder.name}</StyledHeading>

          <View style={{ flexDirection: 'row', gap: '24px' }}>
            {(agentState.user.supportAgentPermissions?.canManageAllArticles || folderData.supportArticleFolder.category.canPublish || folderData.supportArticleFolder.category.canEdit || folderData.supportArticleFolder.canPublish || folderData.supportArticleFolder.canEdit) && !isEditingOrder &&
              <ModalLauncher modal={createArticleModal}>
                {({ openModal }) => (
                  <Button label='Create Article' leftIcon={Icons.Plus} iconSize='medium' variant='secondary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            }

            {(agentState.user.supportAgentPermissions?.canManageAllArticles || folderData.supportArticleFolder.category.canPublish || folderData.supportArticleFolder.canPublish) && (
              isEditingOrder ?
                <Button label='Save Order' variant='tertiary' role='button' loading={editArticleOrderIsLoading} action={handleSaveOrder} />
                :
                <Button label='Reorder' leftIcon={Icons.Reorder} variant='tertiary' role='button' action={() => { setIsEditingOrder(true); }} />
            )}

            {(agentState.user.supportAgentPermissions?.canManageAllArticles || folderData.supportArticleFolder.category.canPublish) && !isEditingOrder &&
              <ModalLauncher modal={<FolderPermissionsModal id={id || ''} />}>
                {({ openModal }) => (
                  <Button label='Share' leftIcon={Icons.UserPlus} iconSize='medium' variant='tertiary' role='button' action={openModal} />
                )}
              </ModalLauncher>
            }

            {(agentState.user.supportAgentPermissions?.canManageAllArticles || folderData.supportArticleFolder.category.canPublish) && !isEditingOrder &&
              <ModalLauncher modal={editFolderModal}>
                {({ openModal: openEditModal }) => (
                  <ModalLauncher modal={deleteFolderModal}>
                    {({ openModal: openDeleteModal }) => (
                      <ActionMenu alignment='right'>
                        <ActionItem label='Edit' onClick={openEditModal} />
                        {folderData.supportArticleFolder?.articles.length === 0 && <ActionItem label='Delete' onClick={openDeleteModal} />}
                      </ActionMenu>
                    )}
                  </ModalLauncher>
                )}
              </ModalLauncher>
            }
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {isEditingOrder ?
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='articles'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} >
                  <View style={{ gap: '16px', marginTop: '16px' }}>
                    {articleOrder.map((article, index) => {
                      return (
                        <Draggable draggableId={article.id} index={index} key={article.id}>
                          {(provided) => (
                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                              <Card>
                                <View style={{ flexDirection: 'row', gap: '16px' }}>
                                  <Icon icon={Icons.Grip} size='medium' />
                                  <StyledHeading tag='h6'>{article.title}</StyledHeading>
                                </View>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}

                    {provided.placeholder}
                  </View>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          :
          <Card size='medium'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell>Title</TableHeaderCell>
                  <TableHeaderCell>Author</TableHeaderCell>
                  <TableHeaderCell>Last Published</TableHeaderCell>
                  <TableHeaderCell>Visibility</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {articleOrder.map((a) => {
                  return (
                    <TableRow key={a.id} style={{ height: '56px' }}>
                      <TableCell style={{ width: '48px' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                          {a.draftRevision !== null ?
                            (a.draftRevision.isReadyToPublish ?
                              <Tooltip content='Ready to publish' strategy='fixed' referenceContainerStyle={{ display: 'flex' }}>
                                <i className="fa-sharp fa-solid fa-triangle" style={{ color: Colors.primary500 }} key='icon-ready-to-publish'></i>
                              </Tooltip>
                              :
                              <Tooltip content='Changes not ready' strategy='fixed' referenceContainerStyle={{ display: 'flex' }}>
                                <i className="fa-sharp fa-solid fa-triangle" style={{ color: Colors.warning500 }} key='icon-not-ready-to-publish'></i>
                              </Tooltip>
                            )
                            :
                            <Tooltip content='Published' strategy='fixed' referenceContainerStyle={{ display: 'flex' }}>
                              <i className="fa-sharp fa-solid fa-circle" style={{ color: Colors.primary500 }} key='icon-published'></i>
                            </Tooltip>
                          }
                          {a.publishedRevision === null &&
                            <Tooltip content='Article is unpublished' strategy='fixed' referenceContainerStyle={{ display: 'flex' }}>
                              <Icon icon={Icons.Hidden} size='medium' style={{ color: Colors.neutral700 }} />
                            </Tooltip>
                          }
                        </View>
                      </TableCell>
                      <TableCell><Link href={`/agent/kb/articles/${a.id}`}>{a.title}</Link></TableCell>
                      <TableCell>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                          <img src='https://cdn.barscience.us/images/support-avatars/support-user-default.png' alt='A user avatar' style={{ height: '32px', width: '32px', borderRadius: '16px' }} />
                          <StyledParagraph>{a.author.firstName} {a.author.lastName}</StyledParagraph>
                        </View>
                      </TableCell>
                      <TableCell style={{ width: '200px' }}>{a.publishedRevision ? <ReactTimeago date={a.publishedRevision.published} /> : 'Never'}</TableCell>
                      <TableCell style={{ width: '200px' }}>{parseArticleVisibility(a.visibility)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
        }
      </Cell>
    </StandardGrid>
  );
}

type FolderPermissionsModalProps = {
  id: string;
  handleClose?: (data?: any) => void;
}

function FolderPermissionsModal(props: FolderPermissionsModalProps) {
  const { addAlert } = useAlertState();
  const { loading: permissionsAreLoading } = useQuery<GetFolderPermissionsResponse>(GET_FOLDER_PERMISSIONS, {
    variables: {
      id: props.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.permissionsForSupportArticleFolder) {
        setPermissions(data.permissionsForSupportArticleFolder);
      }
    }
  });
  const [grantPermission, { loading: grantPermissionIsLoading }] = useMutation<GrantPermissionResponse>(GRANT_PERMISSION);
  const [editPermission, { loading: editPermissionIsLoading }] = useMutation<EditPermissionResponse>(EDIT_PERMISSION);
  const [revokePermission, { loading: revokePermissionIsLoading }] = useMutation<RevokePermissionResponse>(REVOKE_PERMISSION);
  const [searchAgents, { data: searchData, loading: searchAgentsAreLoading }] = useLazyQuery<SearchAgentsResponse>(SEARCH_AGENTS, {
    fetchPolicy: 'cache-and-network',
  });
  const [permissions, setPermissions] = useState<PermissionAssignment[]>([]);
  const [addUserId, setAddUserId] = useState<string | null>(null);
  const [addUserRole, setAddUserRole] = useState<Permission | null>(Permission.EDIT);

  const handleGrantPermission = async () => {
    if (!addUserId || !addUserRole) {
      return;
    }

    const { errors } = await grantPermission({
      variables: {
        folderId: props.id,
        userId: addUserId,
        permission: addUserRole,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to grant permission' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Permission granted' type='success' id={id} />
      addAlert(id, alert);

      // Add the permission to the cache
      const newPermissions = [...permissions];
      newPermissions.push({
        user: {
          id: addUserId,
          firstName: searchData?.supportAgents?.find((agent) => agent.id === addUserId)?.firstName || '',
          lastName: searchData?.supportAgents?.find((agent) => agent.id === addUserId)?.lastName || '',
          email: searchData?.supportAgents?.find((agent) => agent.id === addUserId)?.email || null,
        },
        permission: addUserRole,
      });

      newPermissions.sort((a, b) => {
        const aName = `${a.user.firstName} ${a.user.lastName}`;
        const bName = `${b.user.firstName} ${b.user.lastName}`;

        return aName.localeCompare(bName);
      });

      setPermissions(newPermissions);

      // Reset the add user fields
      setAddUserId(null);
      setAddUserRole(Permission.EDIT);
    }
  }

  const handlePermissionChange = (name: string, value: string | null) => {
    if (value === null) {
      // Remove permission
      const newPermissions = [...permissions];
      const index = newPermissions.findIndex((p) => p.user.id === name);
      newPermissions.splice(index, 1);
      setPermissions(newPermissions);

      // Make API call to revoke permission
      revokePermission({
        variables: {
          folderId: props.id,
          userId: name,
        },
      }).then(({ errors }) => {
        if (errors) {
          const id = generateId();
          const alert = <StandardAlert title='Failed to remove permission' description={errors[0].message} type='error' id={id} />
          addAlert(id, alert);
        }
      });

      return;
    }

    // Update the cached permission
    const newPermissions = [...permissions];
    const index = newPermissions.findIndex((p) => p.user.id === name);
    newPermissions[index].permission = parsePermissionStringValue(value);
    setPermissions(newPermissions);

    // Make API call to update permission
    editPermission({
      variables: {
        folderId: props.id,
        userId: name,
        permission: value,
      },
    }).then(({ errors }) => {
      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Failed to update permissions' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      }
    });
  }

  const handleSearch = (name: string, value: string) => {
    setAddUserId(null);

    if (value.length < 3) {
      return;
    }

    if (value.includes('@')) {
      searchAgents({
        variables: {
          email: value,
          name: null,
        },
        fetchPolicy: 'cache-and-network',
      });
    } else {
      searchAgents({
        variables: {
          email: null,
          name: value,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }

  const foundUsers = searchData?.supportAgents?.filter((agent) => {
    // Filter out any agents that already have access
    return !permissions.some((p) => p.user.id === agent.id);
  });

  const header = <ModalHeader title='Manage Permissions' />;

  const body = (
    <ModalBody>
      {permissionsAreLoading ?
        <View style={{ alignItems: 'center' }}>
          <CircularSpinner size='medium' />
        </View>
        :
        <View style={{ gap: '48px', minHeight: 'fit-content', overflow: 'scroll' }}>
          <View style={{ gap: '16px', minHeight: 'fit-content' }}>
            <StyledHeading tag='h6'>Grant access</StyledHeading>
            <View style={{ flexDirection: 'row', gap: '16px', minHeight: 'fit-content' }}>
              <SingleSelect name='addUserId' value={addUserId} placeholder='Select a user' filterPlaceholder='Search by name or email' filterable autoFocusSearch onFilter={handleSearch} onChange={(_: string, value: string | null) => { setAddUserId(value); }}>
                {searchAgentsAreLoading ?
                  <View style={{ flexDirection: 'row', justifyContent: 'center' }} key='agent-search-loading'>
                    <CircularSpinner size='small' />
                  </View>
                  :
                  <View>
                    {foundUsers?.length === 0 ?
                      <StyledParagraph style={{ color: Colors.neutral700, margin: '16px', textAlign: 'center' }}>No agents found.</StyledParagraph>
                      :
                      foundUsers?.map((agent) => (
                        <Choice label={`${agent.firstName} ${agent.lastName}`} description={agent.email || undefined} value={agent.id} key={agent.id} />
                      ))
                    }
                  </View>
                }
              </SingleSelect>
              <SingleSelect name='addUserRole' value={addUserRole} style={{ flexGrow: 0, width: '150px' }} onChange={(_: string, value: string | null) => { setAddUserRole(parsePermissionStringValue(value)); }}>
                <Choice label='Editor' value={Permission.EDIT} />
                <Choice label='Publisher' value={Permission.PUBLISH} />
              </SingleSelect>
            </View>
            <Button label='Grant Access' variant='primary' role='button' action={handleGrantPermission} loading={grantPermissionIsLoading} disabled={addUserId === null} />
          </View>
          <View style={{ minHeight: 'fit-content' }}>
            <InfoPanel type='warning'>
              <View style={{ gap: '16px' }}>
                <StyledParagraph><span style={{ fontWeight: 600 }}>Grant these permissions with caution.</span> For more information on Knowledge Base permissions, visit <StyledLink href={KNOWLEDGE_BASE_PERMISSIONS_INFO_LINK}>this article</StyledLink>.</StyledParagraph>
              </View>
            </InfoPanel>
          </View>
          <View style={{ minHeight: 'fit-content' }}>
            <StyledHeading tag='h6'>People with access</StyledHeading>
            <View style={{ gap: '16px', marginTop: '16px' }}>
              {permissions.length === 0 && <StyledParagraph style={{ color: Colors.neutral700 }}>There are no permissions granted for this folder.</StyledParagraph>}
              {permissions.map((p) => (
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                  <View>
                    <StyledParagraph bold>{p.user.firstName} {p.user.lastName}</StyledParagraph>
                    {p.user.email && <StyledParagraph style={{ color: Colors.neutral700 }}>{p.user.email}</StyledParagraph>}
                  </View>

                  <SingleSelect name={p.user.id} value={p.permission.toString()} style={{ flexGrow: 0, width: '200px' }} onChange={handlePermissionChange}>
                    <Choice label='Editor' value='EDIT' />
                    <Choice label='Publisher' value='PUBLISH' />
                    <Choice label='Remove' value={null} style={{ borderTop: `1px solid ${Colors.neutral300}`, marginTop: '4px' }} />
                  </SingleSelect>
                </View>
              ))}
            </View>
          </View>
        </View>
      }
    </ModalBody>
  );

  const footer = (
    <ModalFooter>
      <View style={{ alignItems: 'center', flexDirection: 'row', height: '40px', justifyContent: 'space-between', width: '100%' }}>
        {(editPermissionIsLoading || revokePermissionIsLoading) ?
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
            <StyledParagraph style={{ color: Colors.neutral700 }}>Saving changes...</StyledParagraph>
            <CircularSpinner size='xsmall' />
          </View>
          :
          <StyledParagraph style={{ color: Colors.neutral700 }}>All changes saved</StyledParagraph>
        }

        <Button label='Done' variant='primary' role='button' action={props.handleClose ? props.handleClose : () => { }} />
      </View>
    </ModalFooter>
  );

  return (
    <Modal onClose={props.handleClose} header={header} body={body} footer={footer} />
  );
}

function parsePermissionStringValue(value: string | null): Permission {
  switch (value) {
    case 'EDIT':
      return Permission.EDIT;
    case 'PUBLISH':
      return Permission.PUBLISH;
    default:
      return Permission.EDIT;
  }
}