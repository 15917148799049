import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Cell, Choice, ErrorPage, Form, InfoPanel, Link, MiB, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextEditor, TextField, View, generateId, useAlertState, useForm } from "@barscience/global-components";
import { CommunityPostDetails } from "./CommunityPost";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GET_ALL_CATEGORIES, GetAllCategoriesResponse } from "./CommunityHome";
import { COMMUNITY_POST_EDITOR } from "../../util/config/textEditorConfig";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import { ImagePayload } from "@barscience/global-components/dist/typings/TextEditor/nodes/ImageNode";

/* Create Post Mutation */
const CREATE_POST = gql`
mutation createCommunityPost($input: CreateCommunityPostInput!) {
  createCommunityPost(input: $input) {
    id
    title
    body
    author {
      id
      nickname
    }
    created
    lastUpdated
    lastUpdatedBy {
      id
      nickname
    }
    isFeatured
    isHidden
    isLocked
    isPinned
    status {
      id
      publicName
      internalName
      color
    }
    topic {
      id
      name
      restrictPosting
    }
  }
}
`;

type CreateCommunityPostResponse = {
  createCommunityPost: CommunityPostDetails | null;
}

type CreateCommunityPostInput = {
  title: string;
  body: string;
  topicId: string;
}

/* Upload Image Query */
const GET_IMAGE_UPLOAD_URL = gql`
query getCommunityPostImageUploadURL($fileMimeType: String!, $fileByteSize: Int!) {
  getCommunityPostImageUploadURL(fileMimeType: $fileMimeType, fileByteSize: $fileByteSize) {
    uploadURL
    publicURL
  }
}
`;

type GetImageUploadURLResponse = {
  getCommunityPostImageUploadURL: {
    uploadURL: string;
    publicURL: string;
  } | null;
}

export default function CreateCommunityPost() {
  const navigate = useNavigate();
  const { addAlert } = useAlertState();
  const { state: agentState } = useAgentAuthState();
  const [searchParams] = useSearchParams();
  const { data: categoriesData, loading: categoriesAreLoading, error: categoriesError } = useQuery<GetAllCategoriesResponse>(GET_ALL_CATEGORIES);
  const [createPost, { loading: createPostIsLoading }] = useMutation<CreateCommunityPostResponse>(CREATE_POST);
  const [getImageUploadURL] = useLazyQuery<GetImageUploadURLResponse>(GET_IMAGE_UPLOAD_URL, {
    fetchPolicy: 'network-only',
  });

  /* Image Upload */
  const handleImageUpload = async (file: File): Promise<ImagePayload | null> => {
    // Get the signed URL to upload the image to cloud storage
    const { data } = await getImageUploadURL({
      variables: {
        fileMimeType: file.type,
        fileByteSize: file.size,
      },
    });

    if (!data?.getCommunityPostImageUploadURL) {
      const alertId = generateId();
      const alert = <StandardAlert title='Error uploading image' type='error' id={alertId} />
      addAlert(alertId, alert);

      return null;
    }

    // Upload the image to cloud storage
    const response = await fetch(data.getCommunityPostImageUploadURL.uploadURL, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (!response.ok) {
      return null;
    }

    // Return the URL
    return {
      src: data.getCommunityPostImageUploadURL.publicURL,
    };
  }

  const handleCreatePost = async (input: CreateCommunityPostInput) => {
    const { data, errors } = await createPost({
      variables: {
        input: {
          topicId: input.topicId,
          title: input.title,
          body: input.body,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating post' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }

    if (data?.createCommunityPost?.id) {
      navigate(`/community/posts/${data.createCommunityPost.id}`);
    }
  }

  const createPostForm = useForm<CreateCommunityPostInput>({
    onSubmit: handleCreatePost,
    initialValues: {
      title: '',
      body: '',
      topicId: searchParams.get('topicId') || '',
    },
  });
  

  if (categoriesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  if (categoriesAreLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='medium' />
        </Cell>
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Help Center' />
          <Breadcrumb label='Community' to='/community' />
          <Breadcrumb label='New Post' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={7} md={6} sm={4}>
        <View style={{ gap: '16px' }}>
          <StyledHeading tag='h4'>Create a post</StyledHeading>

          <Form handleSubmit={createPostForm.handleSubmit}>
            <View style={{ gap: '16px' }}>
              <TextField label='Title' name='title' value={createPostForm.values.title} error={createPostForm.errors.title} onChange={createPostForm.handleChange} onValidate={createPostForm.handleValidate} required />
              <TextEditor buttonConfig={COMMUNITY_POST_EDITOR} name='body' isEditMode value={createPostForm.values.body} onChange={createPostForm.handleChange} onImageUpload={handleImageUpload} maxImageBytes={5 * MiB} />
              <SingleSelect label='Topic' name='topicId' value={createPostForm.values.topicId} error={createPostForm.errors.topicId} onChange={createPostForm.handleChange} onValidate={createPostForm.handleValidate} required>
                {categoriesData?.communityCategories?.map((category) => (
                  category.topics.filter((topic) => {
                    if (topic.restrictPosting && !agentState.user?.isSupportAgent)  {
                      return false;
                    }

                    return true;
                  }).map((topic) => (
                    <Choice label={topic.name} value={topic.id} key={topic.id} />
                  ))
                ))}
              </SingleSelect>
              <View style={{ flexDirection: 'row', gap: '16px', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button label='Cancel' variant='tertiary' role='link' href='/community' />
                <Button label='Create Post' variant='primary' role='button' type='submit' action={() => { }} disabled={createPostForm.hasError || !createPostForm.values.body} loading={createPostIsLoading} />
              </View>
            </View>
          </Form>

          <InfoPanel type='info'>
            <View style={{ gap: '16px' }}>
              <StyledParagraph>This post will be <span style={{ fontWeight: 600 }}>publicly visible</span>.</StyledParagraph>
              <StyledParagraph>Need to share sensitive information? <Link href='/tickets/new'>Submit a support ticket instead.</Link></StyledParagraph>
              <StyledParagraph>All posts must adhere to our <Link href='/help-center/articles/69f65578-a458-4e7e-ab1d-8b8c0c4711dc'>Community Guidelines</Link>.</StyledParagraph>
            </View>
          </InfoPanel>
        </View>
      </Cell>
    </StandardGrid>
  );
}