import { NoPermission, StandardGrid, getLoginUrlWithContinue, useAuthState } from "@barscience/global-components";
import { SupportAgentPermissions } from "../auth/AgentAuthStateProvider";
import useAgentAuthState from "../auth/useAgentAuthState";

type ProtectedRouteProps = {
  children: JSX.Element;
  permissions: (keyof SupportAgentPermissions)[];
  requireAll?: boolean;
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { state } = useAuthState();
  const { state: agentState } = useAgentAuthState();

  if (!state.user) {
    window.location.replace(getLoginUrlWithContinue());
  }

  if (props.requireAll) {
    // Make sure the user has all of the required permissions
    const hasAllPermissions = props.permissions.every((p) => {
      if (agentState.user?.supportAgentPermissions) {
        return agentState.user?.supportAgentPermissions[p];
      } else {
        return false;
      }
    });

    if (hasAllPermissions) {
      return props.children;
    } else {
      return (
        <StandardGrid>
          <NoPermission />
        </StandardGrid>
      );
    }
  } else {
    // Make sure the user has at least one of the required permissions
    const hasAnyPermission = props.permissions.some((p) => {
      if (agentState.user?.supportAgentPermissions) {
        return agentState.user?.supportAgentPermissions[p];
      } else {
        return false;
      }
    });

    if (hasAnyPermission) {
      return props.children;
    } else {
      return (
        <StandardGrid>
          <NoPermission />
        </StandardGrid>
      );
    }
  }
}