import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, Colors, ErrorPage, Grid, MultiSelect, PageButtons, Row, SingleSelect, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, Typography, View, useAuthState } from "@barscience/global-components";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactTimeago from "react-timeago";
import useAgentAuthState from "../../components/auth/useAgentAuthState";
import HelpCenterHeader from "../helpCenter/HelpCenterHeader";

/* Get Topic Query */
const GET_TOPIC = gql`
query getCommunityTopicDetails($id: ID!) {
  communityTopic(id: $id) {
    id
    name
    description
    restrictPosting
    postType {
      statuses {
        id
        publicName
        internalName
        color
      }
    }
  }
}
`;

type GetTopicResponse = {
  communityTopic: Topic | null;
}

type Topic = {
  id: string;
  name: string;
  description: string | null;
  restrictPosting: boolean;
  postType: PostType | null;
}

type PostType = {
  statuses: Status[] | null;
}

/* Get Posts In Topic Query */
const GET_POSTS_IN_TOPIC = gql`
query getPostsInTopic($topicId: ID!, $sort: TopicPostsSort!, $page: Int!, $dateRange: DateRange, $statusIds: [ID]) {
  postsInTopic(topicId: $topicId, sort: $sort, page: $page, dateRange: $dateRange, statusIds: $statusIds) {
    id
    title
    status {
      id
      publicName
      internalName
      color
    }
    author {
      id
      nickname
    }
    created
    isFeatured
    isPinned
  }
}
`;

type GetPostsInTopicResponse = {
  postsInTopic: Post[] | null;
}

type Post = {
  id: string;
  title: string;
  status: Status | null;
  author: PostAuthor;
  created: string;
  isFeatured: boolean;
  isPinned: boolean;
}

type Status = {
  id: string;
  publicName: string;
  internalName: string | null;
  color: string;
}

type PostAuthor = {
  id: string;
  nickname: string;
}

/* Page Settings */
const POSTS_PER_PAGE = 30;

const styles = StyleSheet.create({
  cardLink: {
    color: 'inherit',
    textDecoration: 'none',
    width: '100%',
    ':hover': {
      textDecoration: 'none'
    },
  },
  tag: {
    backgroundColor: Colors.primary700,
    borderRadius: '4px',
    color: '#ffffff',
    fontSize: '14px',
    height: 'fit-content',
    padding: '6px 8px',
    width: 'fit-content',
  },
  statusTag: {
    borderRadius: '4px',
    color: '#ffffff',
    fontSize: '14px',
    height: 'fit-content',
    padding: '6px 8px',
    width: 'fit-content',
  },
});

const gridStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  boxSizing: 'border-box',
  columnGap: '32px',
  flexGrow: 1,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    columnGap: '16px',
  },
  '@media (max-width: 767px)': {
    columnGap: '8px',
  },
};

export default function CommunityTopic() {
  const { state } = useAuthState();
  const { state: agentState } = useAgentAuthState();
  const { id } = useParams<{ id: string }>();
  const [sort, setSort] = useState<string>('NEWEST_FIRST');
  const [page, setPage] = useState<number>(0);
  const [selectedStatusesData, setSelectedStatusesData] = useState<{ [statusId: string]: any }>({});
  const [selectedStatuses, setSelectedStatuses] = useState<(string | null)[]>([]);
  const { data: topicData, loading: topicIsLoading, error: topicError } = useQuery<GetTopicResponse>(GET_TOPIC, {
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      if (data.communityTopic?.postType?.statuses) {
        const statuses: { [statusId: string]: any } = {};
        data.communityTopic.postType.statuses.forEach((status) => {
          statuses[status.id] = false;
        });
        setSelectedStatusesData(statuses);
      }
    },
  });
  const { data: postsData, loading: postsAreLoading, error: postsError } = useQuery<GetPostsInTopicResponse>(GET_POSTS_IN_TOPIC, {
    variables: {
      topicId: id,
      sort: sort,
      page: page,
      dateRange: null,
      statusIds: selectedStatuses,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    let statuses: (string | null)[] = [];
    
    Object.keys(selectedStatusesData).forEach((statusId: string) => {
      if (selectedStatusesData[statusId]) {
        if (statusId === 'null')
          statuses.push(null);
        else
          statuses.push(statusId);
      }
    });

    setSelectedStatuses(statuses);
  }, [selectedStatusesData]);

  if (topicError || postsError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  if (topicIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  return (
    <Grid style={gridStyle}>
      <HelpCenterHeader />
      
      <Cell lg={12} md={8} sm={4} style={{ padding: '32px 40px' }}>
        <BreadcrumbGroup>
          <Breadcrumb label='Help Center' />
          <Breadcrumb label='Community' to='/community' />
          <Breadcrumb label={topicData?.communityTopic?.name || ''} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ padding: '0 40px' }}>
        <View style={{ flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
          <View style={{ gap: '16px' }}>
            <StyledHeading tag='h3'>{topicData?.communityTopic?.name}</StyledHeading>
            {topicData?.communityTopic?.description && <StyledParagraph style={{ color: Colors.neutral700, fontStyle: 'italic' }}>{topicData.communityTopic.description}</StyledParagraph>}
          </View>

          {state.user && <Button label='New Post' variant='primary' role='link' href={(topicData?.communityTopic?.restrictPosting && !agentState.user?.isSupportAgent) ? '/community/posts/create' : `/community/posts/create?topicId=${id}`} />}
        </View>
      </Cell>

      <Row style={{ padding: '0 40px' }}>
        <Cell lg={8} md={8} sm={4} style={{ paddingRight: '16px', '@media (max-width: 1151px)': { paddingRight: '0px' } }}>
          <View style={{ alignItems: 'flex-end', flexDirection: 'row', flexWrap: 'wrap', gap: '24px', justifyContent: 'space-between' }}>
            <SingleSelect label='Sort by' name='sort' value={sort} onChange={(_: string, value: string | null) => { setSort(value || ''); }} style={{ maxWidth: '220px', width: '220px' }}>
              <Choice label='Newest posts' value='NEWEST_FIRST' />
            </SingleSelect>

            <PageButtons currentPage={page} onPageChange={setPage} numPages={postsData?.postsInTopic?.length === POSTS_PER_PAGE ? page + 2 : page + 1} />
          </View>
        </Cell>
        <Cell lg={4} md={4} sm={4}>
          {agentState.user?.isSupportAgent &&
            <View style={{ alignItems: 'center', justifyContent: 'center', '@media (max-width: 1151px)': { alignItems: 'flex-start' } }}>
              <MultiSelect label='Filter by status' name='statusFilter' value={selectedStatusesData} onChange={(_, value) => { setSelectedStatusesData(value); }} style={{ maxWidth: '300px', width: '300px' }}>
                <Choice label='None' value={'null'} />
                {topicData?.communityTopic?.postType?.statuses?.map((status) => (
                  <Choice label={`${status.internalName} (${status.publicName})`} value={status.id} key={status.id} />
                ))}
              </MultiSelect>
            </View>
          }
        </Cell>
      </Row>

      <Cell lg={8} md={8} sm={4} style={{ padding: '32px 40px' }}>
        {postsAreLoading ?
          <AtomSpinner size='medium' />
          :
          <View style={{ gap: '16px' }}>
            {postsData?.postsInTopic?.map((post) => {
              return (
                <Link to={`/community/posts/${post.id}`} key={post.id} className={css(styles.cardLink)}>
                  <Card size='medium' style={{ ':hover': { backgroundColor: Colors.neutral100 }, ':active': { backgroundColor: Colors.neutral200 } }}>
                    <View style={{ gap: '16px' }}>
                      <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
                        <StyledParagraph bold>{post.title}</StyledParagraph>
                        {post.isPinned && <span className={css(Typography.caption, styles.tag)}>Pinned</span>}
                        {post.isFeatured && <span className={css(Typography.caption, styles.tag)}>Featured</span>}
                        {post.status && <span className={css(Typography.caption, styles.statusTag)} style={{ backgroundColor: post.status.color }}>{agentState.user?.isSupportAgent ? post.status.internalName : post.status.publicName}</span>}
                      </View>

                      <View style={{ flexDirection: 'row', gap: '8px' }}>
                        <StyledCaption>{post.author.nickname}</StyledCaption>
                        <StyledCaption> - </StyledCaption>
                        <StyledCaption><ReactTimeago date={post.created} /></StyledCaption>
                      </View>
                    </View>
                  </Card>
                </Link>
              );
            })}
          </View>
        }
      </Cell>
    </Grid>
  );
}