import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Colors, ErrorPage, Grid, Link, NotFound, StandardGrid, StyledHeading, StyledParagraph, View } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useParams } from "react-router-dom";
import HelpCenterHeader from "./HelpCenterHeader";

const GET_FOLDERS_AND_ARTICLES = gql`
query getSupportArticleCategoryAndArticles($id: ID!) {
  supportArticleCategory(id: $id) {
    id
    name
    description
    folders {
      id
      name
      description
      articles {
        id
        title
      }
    }
  }
}
`;

type GetFoldersAndArticlesResponse = {
  supportArticleCategory: Category | null;
}

type Category = {
  id: string;
  name: string;
  description: string | null;
  folders: Folder[] | null;
}

type Folder = {
  id: string;
  name: string;
  description: string | null;
  articles: Article[] | null;
}

type Article = {
  id: string;
  title: string;
}

const gridStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  boxSizing: 'border-box',
  columnGap: '32px',
  flexGrow: 1,
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  width: '100%',
  '@media (min-width: 768px) and (max-width: 1151px)': {
    columnGap: '16px',
  },
  '@media (max-width: 767px)': {
    columnGap: '8px',
  },
};

export default function HelpCenterCategory() {
  const { id } = useParams();
  const { data, loading, error } = useQuery<GetFoldersAndArticlesResponse>(GET_FOLDERS_AND_ARTICLES, {
    variables: {
      id: id,
    },
  });

  if (loading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (error || !data?.supportArticleCategory) {
    if (error?.graphQLErrors[0]?.extensions.status === 404) {
      return (
        <StandardGrid>
          <NotFound />
        </StandardGrid>
      );
    }

    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <Grid style={gridStyle}>
      <HelpCenterHeader />
      
      <Cell lg={12} md={8} sm={4} style={{ padding: '32px 40px' }}>
        <BreadcrumbGroup>
          <Breadcrumb label='Help Center' to='/help-center' />
          <Breadcrumb label={data.supportArticleCategory.name} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ padding: '0 40px' }}>
        <View style={{ gap: '4px' }}>
          <StyledHeading tag='h3'>{data.supportArticleCategory.name}</StyledHeading>
          {data.supportArticleCategory.description && <StyledParagraph style={{ color: Colors.neutral700 }}>{data.supportArticleCategory.description}</StyledParagraph>}
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4} style={{ padding: '16px 40px' }}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 1000: 2, 1500: 3 }}>
          <Masonry gutter='16px'>
            {data.supportArticleCategory.folders?.map((folder) => (
              <Card key={folder.id}>
                <View style={{ gap: '16px' }}>
                  <View style={{ gap: '4px' }}>
                    <StyledHeading tag='h6'>{folder.name}</StyledHeading>
                    {folder.description && <StyledParagraph style={{ color: Colors.neutral700 }}>{folder.description}</StyledParagraph>}
                  </View>
                  <View style={{ gap: '12px' }}>
                    {folder.articles?.map((article) => (
                      <Link href={`/help-center/articles/${article.id}`} key={article.id}>{article.title}</Link>
                    ))}
                  </View>
                </View>
              </Card>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Cell>
    </Grid>
  );
}