import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Cell, ConfirmModal, ErrorPage, Form, ModalLauncher, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, TextField, View, generateId, useAlertState, useForm } from "@barscience/global-components";

/* Get Offensive Terms Query */
const GET_OFFENSIVE_TERMS = gql`
query getOffensiveTerms {
  offensiveTerms
}
`;

type GetOffensiveTermsResponse = {
  offensiveTerms: string[];
}

/* Create Term Mutation */
const CREATE_TERM = gql`
mutation createOffensiveTerm($term: String!) {
  success: createOffensiveTerm(term: $term)
}
`;

type CreateTermResponse = {
  success: boolean;
}

type NewTermInput = {
  term: string;
}

/* Delete Term Mutation */
const DELETE_TERM = gql`
mutation deleteOffensiveTerm($term: String!) {
  success: deleteOffensiveTerm(term: $term)
}
`;

type DeleteTermResponse = {
  success: boolean;
}

export default function OffensiveTerms() {
  const { addAlert } = useAlertState();
  const { data, loading, error } = useQuery<GetOffensiveTermsResponse>(GET_OFFENSIVE_TERMS);
  const [createTerm, { loading: createTermIsLoading }] = useMutation<CreateTermResponse>(CREATE_TERM, {
    update(cache, { data }) {
      if (data?.success) {
        cache.modify({
          fields: {
            offensiveTerms(existingTerms = []) {
              return [...existingTerms, newTermForm.values.term.toLowerCase()].sort((a, b) => a.localeCompare(b));
            }
          }
        })
      }
    },
  });
  const [deleteTerm] = useMutation<DeleteTermResponse>(DELETE_TERM, {
    update(cache, { data }, { variables }) {
      if (!variables?.term) {
        return;
      }

      if (data?.success) {
        cache.modify({
          fields: {
            offensiveTerms(existingTerms = [], { readField }) {
              return existingTerms.filter((term: string) => term !== variables.term);
            }
          }
        })
      }
    },

  });

  const newTermForm = useForm<NewTermInput>({
    initialValues: {
      term: '',
    },
    onSubmit: async (values) => {
      const { data, errors } = await createTerm({ variables: { term: values.term } });

      if (data?.success) {
        const id = generateId();
        const alert = <StandardAlert title='Offensive term created' type='success' id={id} />
        addAlert(id, alert);
      }

      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Error creating offensive term' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
      }

      newTermForm.resetValues();
    },
  });

  const handleDeleteTerm = async (term: string) => {
    if (!term) {
      return;
    }

    const { data, errors } = await deleteTerm({ variables: { term } });

    if (data?.success) {
      const id = generateId();
      const alert = <StandardAlert title='Offensive term deleted' type='success' id={id} />
      addAlert(id, alert);
    }

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting offensive term' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteTermModal = (
    <ConfirmModal title='Delete term?' onConfirm={handleDeleteTerm} confirmLabel='Delete' destructive>
      <StyledParagraph>Posts with this term will no longer be auto-hidden.</StyledParagraph>
    </ConfirmModal>
  );

  if (error) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/agent/settings' />
          <Breadcrumb label='Offensive Terms' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '24px' }}>
          <StyledHeading tag='h4'>Offensive Terms</StyledHeading>


          <Form handleSubmit={newTermForm.handleSubmit}>
            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
              <TextField name='term' value={newTermForm.values.term} onChange={newTermForm.handleChange} onValidate={newTermForm.handleValidate} required style={{ flexGrow: 0, width: '300px' }} />
              <Button label='Add Term' variant='primary' role='button' action={() => { }} type='submit' disabled={newTermForm.hasError} loading={createTermIsLoading} />
            </View>
          </Form>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ gap: '16px', marginTop: '32px' }}>
          {loading ?
            <AtomSpinner size='medium' />
            :
            <>
              <ModalLauncher modal={deleteTermModal}>
                {({ openModal }) => (
                  <>
                    {data?.offensiveTerms.map((term) => (
                      <View key={term} style={{ alignItems: 'center', flexDirection: 'row', gap: '32px'  }}>
                        <StyledParagraph>{term}</StyledParagraph>
                        <Button label='Delete' variant='tertiary' role='button' action={openModal} data={term} />
                      </View>
                    ))}
                  </>
                )}
              </ModalLauncher>
            </>
          }
        </View>
      </Cell>
    </StandardGrid>
  );
}