import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Card, Cell, Colors, ConfirmModal, ErrorPage, FormModal, FormModalValueProvider, generateId, ModalLauncher, OptionBar, OptionItem, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, Typography, useAlertState, View } from "@barscience/global-components";
import { css, StyleSheet } from "aphrodite";
import { useState } from "react";
import { createStyle } from "../../../util/createStyle";
import HasAgentPermission from "../../../components/auth/HasAgentPermission";

/* Get Name Badges Query */
export const GET_NAME_BADGES = gql`
query getAllNameBadges {
  nameBadges {
    id
    title
    hexColor
  }
}
`;

export type GetNameBadgesResponse = {
  nameBadges: NameBadge[];
}

type NameBadge = {
  id: string;
  title: string;
  hexColor: string;
}

/* Create Name Badge */
const CREATE_NAME_BADGE = gql`
mutation createNameBadge($input: CreateNameBadgeInput!) {
  createNameBadge(input: $input) {
    id
    title
    hexColor
  }
}
`;

type CreateNameBadgeInput = {
  title: string;
  hexColor: string;
}

type CreateNameBadgeResponse = {
  createNameBadge: NameBadge;
}

/* Edit Name Badge */
const EDIT_NAME_BADGE = gql`
mutation editNameBadge($id: ID!, $input: EditNameBadgeInput!) {
  editNameBadge(id: $id, input: $input) {
    id
    title
    hexColor
  }
}
`;

type EditNameBadgeInput = {
  id: string;
  title: string;
  hexColor: string;
}

/* Delete Name Badge */
const DELETE_NAME_BADGE = gql`
mutation deleteNameBadge($id: ID!) {
  deleteNameBadge(id: $id)
}
`;

/* Get Custom Badges Query */
export const GET_CUSTOM_BADGES = gql`
query getAllCustomBadges {
  supportCommunityCustomBadges {
    id
    title
    description
    imageUrl
  }
}
`;

export type GetCustomBadgesResponse = {
  supportCommunityCustomBadges: CustomBadge[];
}

type CustomBadge = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
}

/* Create Custom Badge */
const CREATE_CUSTOM_BADGE = gql`
mutation createCustomBadge($input: CreateSupportCommunityCustomBadgeInput!) {
  createSupportCommunityCustomBadge(input: $input) {
    id
    title
    description
    imageUrl
  }
}
`;

type CreateCustomBadgeResponse = {
  createSupportCommunityCustomBadge: CustomBadge;
}

type CreateCustomBadgeInput = {
  title: string;
  description: string;
  imageUrl: string;
}

/* Edit Custom Badge */
const EDIT_CUSTOM_BADGE = gql`
mutation editCustomBadge($id: ID!, $input: EditSupportCommunityCustomBadgeInput!) {
  editSupportCommunityCustomBadge(id: $id, input: $input) {
    id
    title
    description
    imageUrl
  }
}
`;

type EditCustomBadgeInput = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
}

/* Delete Custom Badge */
const DELETE_CUSTOM_BADGE = gql`
mutation deleteCustomBadge($id: ID!) {
  deleteSupportCommunityCustomBadge(id: $id)
}
`;

type DeleteCustomBadgeInput = {
  id: string;
  confirmed: string;
}

/* Styles */
const styles = StyleSheet.create({
  colorSwatch: {
    border: `1px solid ${Colors.neutral300}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'inline-block',
    height: '18px',
    width: '18px',
  },
  colorSwatchLarge: {
    border: `1px solid ${Colors.neutral300}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'inline-block',
    height: '40px',
    width: '40px',
  },
  tag: {
    borderRadius: '4px',
    color: '#ffffff',
    fontSize: '14px',
    height: 'fit-content',
    padding: '6px 8px',
    width: 'fit-content',
  },
});

export default function Badges() {
  const { addAlert } = useAlertState();
  const [view, setView] = useState<string>('NAME');
  const { data: nameBadgeData, loading: nameBadgesAreLoading, error: nameBadgeError } = useQuery<GetNameBadgesResponse>(GET_NAME_BADGES);
  const { data: customBadgeData, loading: customBadgesAreLoading, error: customBadgeError } = useQuery<GetCustomBadgesResponse>(GET_CUSTOM_BADGES);
  const [createNameBadge] = useMutation<CreateNameBadgeResponse>(CREATE_NAME_BADGE, {
    update(cache, { data }) {
      const existingNameBadges = cache.readQuery<GetNameBadgesResponse>({ query: GET_NAME_BADGES });
      if (existingNameBadges && data) {
        cache.writeQuery({
          query: GET_NAME_BADGES,
          data: {
            nameBadges: [...existingNameBadges.nameBadges, data.createNameBadge].sort((a, b) => a.title.localeCompare(b.title))
          },
        });
      }
    },
  });
  const [editNameBadge] = useMutation<CreateNameBadgeResponse>(EDIT_NAME_BADGE);
  const [deleteNameBadge] = useMutation(DELETE_NAME_BADGE, {
    update(cache, { data }, { variables }) {
      const existingNameBadges = cache.readQuery<GetNameBadgesResponse>({ query: GET_NAME_BADGES });
      if (existingNameBadges && data && variables) {
        cache.writeQuery({
          query: GET_NAME_BADGES,
          data: {
            nameBadges: existingNameBadges.nameBadges.filter((badge) => badge.id !== variables.id)
          },
        });
      }
    },
  });
  const [createCustomBadge] = useMutation<CreateCustomBadgeResponse>(CREATE_CUSTOM_BADGE, {
    update(cache, { data }) {
      const existingCustomBadges = cache.readQuery<GetCustomBadgesResponse>({ query: GET_CUSTOM_BADGES });
      if (existingCustomBadges && data) {
        cache.writeQuery({
          query: GET_CUSTOM_BADGES,
          data: {
            supportCommunityCustomBadges: [...existingCustomBadges.supportCommunityCustomBadges, data.createSupportCommunityCustomBadge].sort((a, b) => a.title.localeCompare(b.title))
          },
        });
      }
    },
  });
  const [editCustomBadge] = useMutation<CreateCustomBadgeResponse>(EDIT_CUSTOM_BADGE);
  const [deleteCustomBadge] = useMutation(DELETE_CUSTOM_BADGE, {
    update(cache, { data }, { variables }) {
      const existingCustomBadges = cache.readQuery<GetCustomBadgesResponse>({ query: GET_CUSTOM_BADGES });
      if (existingCustomBadges && data && variables) {
        cache.writeQuery({
          query: GET_CUSTOM_BADGES,
          data: {
            supportCommunityCustomBadges: existingCustomBadges.supportCommunityCustomBadges.filter((badge) => badge.id !== variables.id)
          },
        });
      }
    },
  });

  const validateColor = (_: string, value: string) => {
    // Make sure the color is a valid hex color
    if (value.match(/^#([A-Fa-f0-9]{6})$/)) {
      return null;
    }

    return 'Invalid hex color';
  }

  /* Create Name Badge */
  const handleCreateNameBadge = async (values: CreateNameBadgeInput) => {
    const { errors } = await createNameBadge({
      variables: {
        input: {
          title: values.title,
          hexColor: values.hexColor,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating name badge' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Name badge created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createNameBadgeModal = (
    <FormModal<CreateNameBadgeInput> title='Create Name Badge' onSubmit={handleCreateNameBadge} submitLabel='Create' initialValues={{ title: '', hexColor: '#2B89DF' }}>
      <FormModalValueProvider>
        {({ getValue }) => (
          <View style={{ gap: '16px' }}>
            <TextField label='Title' name='title' required />
            <TextField label='Hex Color' name='hexColor' validate={validateColor} required />
            <View style={{ marginTop: '24px', gap: '16px' }}>
              <StyledHeading tag='h6'>Preview</StyledHeading>
              {(getValue && getValue('title') && getValue('hexColor')) ? <span className={css(Typography.caption, styles.tag, createStyle({ backgroundColor: getValue('hexColor') }))}>{getValue('title')}</span> : <></>}
            </View>
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  /* Edit Name Badge */
  const handleEditNameBadge = async (values: EditNameBadgeInput) => {
    const { errors } = await editNameBadge({
      variables: {
        id: values.id,
        input: {
          title: values.title,
          hexColor: values.hexColor,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error editing name badge' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editNameBadgeModal = (
    <FormModal<EditNameBadgeInput> title='Edit Badge' onSubmit={handleEditNameBadge} initialValues={{ id: '', title: '', hexColor: '' }}>
      <FormModalValueProvider>
        {({ getValue }) => (
          <View style={{ gap: '16px' }}>
            <TextField label='Title' name='title' required />
            <TextField label='Hex Color' name='hexColor' validate={validateColor} required />
            <View style={{ marginTop: '24px', gap: '16px' }}>
              <StyledHeading tag='h6'>Preview</StyledHeading>
              {(getValue && getValue('title') && getValue('hexColor')) ? <span className={css(Typography.caption, styles.tag, createStyle({ backgroundColor: getValue('hexColor') }))}>{getValue('title')}</span> : <></>}
            </View>
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  /* Delete Name Badge */
  const handleDeleteNameBadge = async (id: string) => {
    const { errors } = await deleteNameBadge({
      variables: {
        id,
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting name badge' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Name badge deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const confirmDeleteNameBadgeModal = (
    <ConfirmModal title='Delete Badge?' onConfirm={handleDeleteNameBadge} destructive confirmLabel='Delete'>
      <StyledParagraph>This name badge will be permanently deleted. Any users who are assigned this badge will now have no name badge assigned to them.</StyledParagraph>
    </ConfirmModal>
  );

  /* Create Custom Badge */
  const handleCreateCustomBadge = async (values: CreateCustomBadgeInput) => {
    const { errors } = await createCustomBadge({
      variables: {
        input: {
          title: values.title,
          description: values.description,
          imageUrl: values.imageUrl,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating custom badge' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Custom badge created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createCustomBadgeModal = (
    <FormModal<CreateCustomBadgeInput> title='Create Custom Badge' onSubmit={handleCreateCustomBadge} submitLabel='Create' initialValues={{ title: '', description: '', imageUrl: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Title' name='title' required />
        <TextField label='Description' name='description' required />
        <TextField label='Image URL' name='imageUrl' required />
      </View>
    </FormModal>
  );

  /* Edit Custom Badge */
  const handleEditCustomBadge = async (values: EditCustomBadgeInput) => {
    const { errors } = await editCustomBadge({
      variables: {
        id: values.id,
        input: {
          title: values.title,
          description: values.description,
          imageUrl: values.imageUrl,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error editing custom badge' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editCustomBadgeModal = (
    <FormModal<EditCustomBadgeInput> title='Edit Badge' onSubmit={handleEditCustomBadge} initialValues={{ id: '', title: '', description: '', imageUrl: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Title' name='title' required />
        <TextField label='Description' name='description' required />
        <TextField label='Image URL' name='imageUrl' required />
      </View>
    </FormModal>
  );

  /* Delete Custom Badge */
  const handleDeleteCustomBadge = async (input: DeleteCustomBadgeInput) => {
    const { errors } = await deleteCustomBadge({
      variables: {
        id: input.id,
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting custom badge' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Custom badge deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deleteCustomBadgeModal = (
    <FormModal<DeleteCustomBadgeInput> title='Delete Badge?' onSubmit={handleDeleteCustomBadge} submitLabel='Delete' destructive initialValues={{ id: '', confirmed: '' }}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This badge will be permanently deleted.</StyledParagraph>
        <StyledParagraph>Any users that currently own the badge will no longer have it.</StyledParagraph>
        <TextField label='Type "I understand" to proceed' name='confirmed' required validate={(_: string, value: string) => { return value === 'I understand' ? null : 'Type "I understand" to proceed' }} />
      </View>
    </FormModal>
  );


  if (nameBadgeError || customBadgeError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/agent/settings' />
          <Breadcrumb label='Community Badges' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h4'>Community Badges</StyledHeading>

          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '24px' }}>
            <OptionBar selectedValue={view} onChange={setView}>
              <OptionItem label='Name Badges' value='NAME' />
              <OptionItem label='Custom Badges' value='CUSTOM' />
            </OptionBar>


            <ModalLauncher modal={createNameBadgeModal}>
              {({ openModal: openCreateNameBadgeModal }) => (
                <ModalLauncher modal={createCustomBadgeModal}>
                  {({ openModal: openCreateCustomBadgeModal }) => (
                    <ActionMenu label='Create Badge'>
                      <HasAgentPermission permissions={['canManageAgents']}>
                        <ActionItem label='Name Badge' onClick={openCreateNameBadgeModal} />
                      </HasAgentPermission>
                      <ActionItem label='Custom Badge' onClick={openCreateCustomBadgeModal} />
                    </ActionMenu>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          </View>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {view === 'NAME' && (
          nameBadgesAreLoading ?
            <AtomSpinner size='medium' />
            :
            <Card size='medium'>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Title</TableHeaderCell>
                    <TableHeaderCell>Color</TableHeaderCell>
                    <HasAgentPermission permissions={['canManageAgents']}>
                      <TableHeaderCell style={{ padding: '0', width: '24px' }}></TableHeaderCell>
                    </HasAgentPermission>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {nameBadgeData?.nameBadges.map((badge) => (
                    <TableRow key={badge.id}>
                      <TableCell>{badge.title}</TableCell>
                      <TableCell>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '4px' }}>
                          <span className={css(styles.colorSwatch)} style={{ backgroundColor: badge.hexColor }}></span>
                          {badge.hexColor}
                        </View>
                      </TableCell>
                      <HasAgentPermission permissions={['canManageAgents']}>
                        <TableCell style={{ padding: '0', width: '24px' }}>
                          <ModalLauncher modal={editNameBadgeModal}>
                            {({ openModal: openEditModal }) => (
                              <ModalLauncher modal={confirmDeleteNameBadgeModal}>
                                {({ openModal: openDeleteModal }) => (
                                  <ActionMenu alignment='right'>
                                    <ActionItem label='Edit' onClick={() => { openEditModal({ id: badge.id, title: badge.title, hexColor: badge.hexColor }); }} />
                                    <ActionItem label='Delete' onClick={() => { openDeleteModal(badge.id); }} />
                                  </ActionMenu>
                                )}
                              </ModalLauncher>
                            )}
                          </ModalLauncher>
                        </TableCell>
                      </HasAgentPermission>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
        )}
        {view === 'CUSTOM' && (
          customBadgesAreLoading ?
            <AtomSpinner size='medium' />
            :
            <Card size='medium'>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Title</TableHeaderCell>
                    <TableHeaderCell>Description</TableHeaderCell>
                    <TableHeaderCell style={{ padding: '0', width: '24px' }}></TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {customBadgeData?.supportCommunityCustomBadges.map((badge) => (
                    <TableRow key={badge.id}>
                      <TableCell>{badge.title}</TableCell>
                      <TableCell>{badge.description}</TableCell>
                      <TableCell style={{ padding: '0', width: '24px' }}>
                        <ModalLauncher modal={editCustomBadgeModal}>
                          {({ openModal: openEditModal }) => (
                            <ModalLauncher modal={deleteCustomBadgeModal}>
                              {({ openModal: openDeleteModal }) => (
                                <ActionMenu alignment='right'>
                                  <ActionItem label='Edit' onClick={() => { openEditModal({ id: badge.id, title: badge.title, description: badge.description, imageUrl: badge.imageUrl }); }} />
                                  <ActionItem label='Delete' onClick={() => { openDeleteModal({ id: badge.id, confirmed: '' }); }} />
                                </ActionMenu>
                              )}
                            </ModalLauncher>
                          )}
                        </ModalLauncher>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
        )}
      </Cell>
    </StandardGrid>
  );
}