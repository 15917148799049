import { gql, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Cell, Choice, Colors, ConfirmModal, ErrorPage, FormModal, FormModalValueProvider, Icon, Icons, ModalLauncher, SingleSelect, StandardAlert, StandardGrid, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { useState } from "react";
import HasAgentPermission from "../../../components/auth/HasAgentPermission";
import { StyleSheet, css } from "aphrodite";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

/* Get Post Types Query */
const GET_ALL_POST_TYPES = gql`
query getAllCommunityPostTypes {
  communityPostTypes {
    id
    name
    statuses {
      id
      publicName
      internalName
      color
    }
  }
}
`;

type GetAllPostTypesResponse = {
  communityPostTypes: PostType[] | null;
}

type PostType = {
  id: string;
  name: string;
  statuses: PostStatus[];
}

type PostStatus = {
  id: string;
  publicName: string;
  internalName: string | null;
  color: string;
}

/* Create Post Type Mutation */
const CREATE_POST_TYPE = gql`
mutation createCommunityPostType($name: String!) {
  createCommunityPostType(name: $name) {
    id
    name
    statuses {
      id
      publicName
      internalName
      color
    }
  }
}
`;

type CreatePostTypeResponse = {
  createCommunityPostType: PostType | null;
}

type CreatePostTypeInput = {
  name: string;
}

/* Edit Post Type Mutation */
const EDIT_POST_TYPE = gql`
mutation editCommunityPostType($id: ID!, $name: String!) {
  editCommunityPostType(id: $id, name: $name) {
    id
    name
  }
}
`;

type EditPostTypeResponse = {
  editCommunityPostType: {
    id: string;
    name: string;
  } | null;
}

type EditPostTypeInput = {
  id: string;
  name: string;
}

/* Delete Post Type Mutation */
const DELETE_POST_TYPE = gql`
mutation deleteCommunityPostType($id: ID!) {
  deleteCommunityPostType(id: $id) {
    id
  }
}
`;

type DeletePostTypeResponse = {
  deleteCommunityPostType: {
    id: string;
  } | null;
}

/* Create Post Type Status Mutation */
const CREATE_POST_TYPE_STATUS = gql`
mutation createCommunityPostTypeStatus($postTypeId: ID!, $input: CreateCommunityPostTypeStatusInput!) {
  createCommunityPostTypeStatus(postTypeId: $postTypeId, input: $input) {
    id
    publicName
    internalName
    color
  }
}
`;

type CreatePostTypeStatusResponse = {
  createCommunityPostTypeStatus: PostStatus | null;
}

type CreatePostTypeStatusInput = {
  postTypeId: string;
  publicName: string;
  internalName: string;
  color: string;
}

/* Edit Post Type Status Mutation */
const EDIT_POST_TYPE_STATUS = gql`
mutation editCommunityPostTypeStatus($statusId: ID!, $input: EditCommunityPostTypeStatusInput!) {
  editCommunityPostTypeStatus(statusId: $statusId, input: $input) {
    id
    publicName
    internalName
    color
  }
}
`;

type EditPostTypeStatusResponse = {
  editCommunityPostTypeStatus: PostStatus | null;
}

type EditPostTypeStatusInput = {
  id: string;
  publicName: string;
  internalName: string;
  color: string;
}

/* Delete Post Type Status Mutation */
const DELETE_POST_TYPE_STATUS = gql`
mutation deleteCommunityPostTypeStatus($statusId: ID!, $replacementStatusId: ID) {
  deleteCommunityPostTypeStatus(statusId: $statusId, replacementStatusId: $replacementStatusId) {
    id
    publicName
    internalName
    color
  }
}
`;

type DeletePostTypeStatusResponse = {
  deleteCommunityPostTypeStatus: PostStatus | null;
}

type DeletePostTypeStatusInput = {
  id: string;
  replacementStatusId: string;
  confirmation: string;
}

/* Edit Post Type Status Order Mutation */
const EDIT_POST_TYPE_STATUS_ORDER = gql`
mutation editCommunityPostTypeStatusOrder($postTypeId: ID!, $order: [ID!]!) {
  success: editCommunityPostTypeStatusOrder(postTypeId: $postTypeId, order: $order)
}
`;

type EditPostTypeStatusOrderResponse = {
  success: boolean;
}

export default function PostTypes() {
  const { addAlert } = useAlertState();
  const { data: postTypeData, loading: postTypesAreLoading, error: postTypeError } = useQuery<GetAllPostTypesResponse>(GET_ALL_POST_TYPES);
  const [createPostType] = useMutation<CreatePostTypeResponse>(CREATE_POST_TYPE, {
    update(cache, { data }) {
      if (!data?.createCommunityPostType) {
        return;
      }

      cache.modify({
        fields: {
          communityPostTypes(existingPostTypes = [], { readField }) {
            const newPostTypeRef = cache.writeFragment({
              data: data.createCommunityPostType,
              fragment: gql`
                fragment NewPostType on CommunityPostType {
                  id
                  name
                  statuses {
                    id
                    publicName
                    internalName
                    color
                  }
                }
              `
            });

            return [...existingPostTypes, newPostTypeRef].sort((a, b) => {
              const aName = readField('name', a)?.toString().toLocaleLowerCase() || '';
              const bName = readField('name', b)?.toString().toLocaleLowerCase() || '';

              return aName.localeCompare(bName);
            })
          }
        },
      });
    },
  });

  /* Create Post Type */
  const handleCreatePostType = async (values: CreatePostTypeInput) => {
    const { errors } = await createPostType({
      variables: {
        name: values.name,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating post type' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Post type created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createPostTypeModal = (
    <FormModal<CreatePostTypeInput> title='Create Post Type' submitLabel='Create' initialValues={{ name: '' }} onSubmit={handleCreatePostType}>
      <TextField label='Type Name' name='name' required />
    </FormModal>
  );

  if (postTypeError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/agent/settings' />
          <Breadcrumb label='Post Types' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h4'>Post Types</StyledHeading>

          <HasAgentPermission permissions={['canManagePostStatuses']}>
            <ModalLauncher modal={createPostTypeModal}>
              {({ openModal }) => (
                <Button label='Create Type' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasAgentPermission>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {postTypesAreLoading ?
          <AtomSpinner />
          :
          <View style={{ gap: '24px' }}>
            {postTypeData?.communityPostTypes?.map((postType) => {
              return (
                <PostTypeCard postType={postType} key={postType.id} />
              );
            })}
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}

const styles = StyleSheet.create({
  colorSwatch: {
    border: `1px solid ${Colors.neutral300}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'inline-block',
    height: '18px',
    width: '18px',
  },
  colorSwatchLarge: {
    border: `1px solid ${Colors.neutral300}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    display: 'inline-block',
    height: '40px',
    width: '40px',
  },
  draggableStatus: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '8px 16px',
    ':hover': {
      backgroundColor: Colors.neutral100,
    },
    ':active': {
      backgroundColor: Colors.neutral300,
    },
  },
  draggableContainer: {
    padding: '8px 0px',
  },
});

type PostTypeCardProps = {
  postType: PostType;
}

function PostTypeCard(props: PostTypeCardProps) {
  const { postType } = props;
  const { addAlert } = useAlertState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditingOrder, setIsEditingOrder] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string[]>(postType.statuses.map((status) => status.id));
  const [createPostTypeStatus] = useMutation<CreatePostTypeStatusResponse>(CREATE_POST_TYPE_STATUS, {
    update(cache, { data }, { variables }) {
      if (!data?.createCommunityPostTypeStatus || !variables?.postTypeId) {
        return;
      }

      // Add the new status to the list of statuses for the post type
      cache.modify({
        id: cache.identify({
          __typename: 'CommunityPostType',
          id: variables.postTypeId,
        }),
        fields: {
          statuses(existingStatuses = []) {
            const newStatusRef = cache.writeFragment({
              data: data.createCommunityPostTypeStatus,
              fragment: gql`
                fragment NewPostTypeStatus on CommunityPostTypeStatus {
                  id
                  publicName
                  internalName
                  color
                }
              `
            });

            return [...existingStatuses, newStatusRef];
          }
        },
      });
    },

  });
  const [editPostTypeStatus] = useMutation<EditPostTypeStatusResponse>(EDIT_POST_TYPE_STATUS);
  const [deletePostTypeStatus] = useMutation<DeletePostTypeStatusResponse>(DELETE_POST_TYPE_STATUS, {
    update(cache, { data }, { variables }) {
      if (!data?.deleteCommunityPostTypeStatus || !variables?.statusId) {
        return;
      }

      // Remove the deleted status from the list of statuses for the post type
      cache.modify({
        id: cache.identify({
          __typename: 'CommunityPostType',
          id: postType.id,
        }),
        fields: {
          statuses(existingStatuses = [], { readField }) {
            return existingStatuses.filter((statusRef: any) => {
              return readField('id', statusRef) !== variables.statusId;
            });
          }
        },
      });
    },

  });
  const [editStatusOrder, { loading: editStatusOrderIsLoading }] = useMutation<EditPostTypeStatusOrderResponse>(EDIT_POST_TYPE_STATUS_ORDER, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables?.postTypeId || !variables?.order) {
        return;
      }

      // Update the order of the statuses for the post type
      cache.modify({
        id: cache.identify({
          __typename: 'CommunityPostType',
          id: variables.postTypeId,
        }),
        fields: {
          statuses(existingStatuses = [], { readField }) {
            return variables.order.map((id: string) => {
              return existingStatuses.find((statusRef: any) => {
                return readField('id', statusRef) === id;
              });
            });
          }
        },
      });
    },


  });
  const [editPostType] = useMutation<EditPostTypeResponse>(EDIT_POST_TYPE);
  const [deletePostType] = useMutation<DeletePostTypeResponse>(DELETE_POST_TYPE, {
    update(cache, { data }, { variables }) {
      if (!data?.deleteCommunityPostType || !variables?.id) {
        return;
      }

      // Remove the deleted post type from the list of post types
      cache.modify({
        fields: {
          communityPostTypes(existingPostTypes = [], { readField }) {
            return existingPostTypes.filter((postTypeRef: any) => {
              return readField('id', postTypeRef) !== variables.id;
            });
          }
        },
      });
    },


  });

  const validateColor = (_: string, value: string) => {
    // Make sure the color is a valid hex color
    if (value.match(/^#([A-Fa-f0-9]{6})$/)) {
      return null;
    }

    return 'Invalid hex color';
  }

  /* Create Post Type Status */
  const handleCreatePostTypeStatus = async (values: CreatePostTypeStatusInput) => {
    const { errors } = await createPostTypeStatus({
      variables: {
        postTypeId: values.postTypeId,
        input: {
          publicName: values.publicName,
          internalName: values.internalName,
          color: values.color,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating status' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Status created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createPostTypeStatusModal = (
    <FormModal<CreatePostTypeStatusInput> title='Create Status' submitLabel='Create' initialValues={{ postTypeId: '', publicName: '', internalName: '', color: '' }} onSubmit={handleCreatePostTypeStatus}>
      <FormModalValueProvider>
        {({ getValue }) => (
          <View style={{ gap: '16px' }}>
            <TextField label='Public Name' description='The name shown to end users' name='publicName' required />
            <TextField label='Internal Name' description='The name shown to agents' name='internalName' required />
            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
              <TextField label='Hex Color' name='color' validate={validateColor} required />
              <span className={css(styles.colorSwatchLarge)} style={{ backgroundColor: getValue ? getValue('color') : '#ffffff' }}></span>
            </View>
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  /* Edit Post Type Status */
  const handleEditPostTypeStatus = async (values: EditPostTypeStatusInput) => {
    const { errors } = await editPostTypeStatus({
      variables: {
        statusId: values.id,
        input: {
          publicName: values.publicName,
          internalName: values.internalName,
          color: values.color,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error editing status' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editPostTypeStatusModal = (
    <FormModal<EditPostTypeStatusInput> title='Edit Status' initialValues={{ id: '', publicName: '', internalName: '', color: '' }} onSubmit={handleEditPostTypeStatus}>
      <FormModalValueProvider>
        {({ getValue }) => (
          <View style={{ gap: '16px' }}>
            <TextField label='Public Name' description='The name shown to end users' name='publicName' required />
            <TextField label='Internal Name' description='The name shown to agents' name='internalName' required />
            <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
              <TextField label='Hex Color' name='color' validate={validateColor} required />
              <span className={css(styles.colorSwatchLarge)} style={{ backgroundColor: getValue ? getValue('color') : '#ffffff' }}></span>
            </View>
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  /* Delete Post Type Status */
  const handleDeletePostTypeStatus = async (values: DeletePostTypeStatusInput) => {
    const { errors } = await deletePostTypeStatus({
      variables: {
        statusId: values.id,
        replacementStatusId: values.replacementStatusId ? values.replacementStatusId : null,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting status' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Status deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deletePostTypeStatusModal = (
    <FormModal<DeletePostTypeStatusInput> title='Delete Status' submitLabel='Delete' initialValues={{ id: '', replacementStatusId: '', confirmation: '' }} onSubmit={handleDeletePostTypeStatus} destructive>
      <FormModalValueProvider>
        {({ getValue }) => (
          <View style={{ gap: '16px' }}>
            <SingleSelect label='New Status' description='Any posts with the deleted status will be converted to this status' name='replacementStatusId'>
              {postType.statuses.filter((status) => status.id !== (getValue ? getValue('id') : '')).map((status) => (
                <Choice label={status.internalName || status.publicName} value={status.id} key={status.id} />
              ))}
            </SingleSelect>
            <TextField label='Type "DELETE" to confirm' name='confirmation' required validate={(_: string, value: string) => { return (value === 'DELETE' ? null : 'Type DELETE to confirm'); }} />
            <StyledParagraph>This status will be <span style={{ fontWeight: 600 }}>permanently deleted</span>. Any posts with the deleted status will be updated to the New Status, or set to status None if no new status is selected.</StyledParagraph>
          </View>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  /* Reorder Statuses */
  const handleDragEnd = async (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const newStatusOrder = Array.from(statusOrder);
    newStatusOrder.splice(source.index, 1);
    newStatusOrder.splice(destination.index, 0, statusOrder[source.index]);

    setStatusOrder(newStatusOrder);
  }

  const handleSaveOrder = async () => {
    const { errors } = await editStatusOrder({
      variables: {
        postTypeId: postType.id,
        order: statusOrder,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error saving status order' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Status order saved' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  /* Edit Post Type */
  const handleEditPostType = async (values: EditPostTypeInput) => {
    const { errors } = await editPostType({
      variables: {
        id: values.id,
        name: values.name,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error editing post type' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const editPostTypeModal = (
    <FormModal<EditPostTypeInput> title='Edit Post Type' initialValues={{ id: '', name: '' }} onSubmit={handleEditPostType}>
      <TextField label='Name' name='name' required />
    </FormModal>
  );

  /* Delete Post Type */
  const handleDeletePostType = async (id: string) => {
    const { errors } = await deletePostType({
      variables: {
        id: id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting post type' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Post type deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deletePostTypeModal = (
    <ConfirmModal title='Delete post type?' confirmLabel='Delete' destructive onConfirm={handleDeletePostType}>
      <StyledParagraph>This post type will be <span style={{ fontWeight: 600 }}>permanently deleted</span>. This action will fail if any topics use this post type.</StyledParagraph>
    </ConfirmModal>
  );

  return (
    <>
      <View style={{ backgroundColor: '#ffffff', border: `1px solid ${Colors.neutral200}`, borderRadius: '4px' }}>
        <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '4px', justifyContent: 'space-between', minHeight: '65px', padding: '12px', ...(isOpen ? { borderBottom: `1px solid ${Colors.neutral200}` } : {}) }} onClick={() => { setIsEditingOrder(false); setIsOpen(!isOpen); }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '4px' }}>
            {isOpen && <Icon size='small' icon={Icons.ChevronDown} />}
            {!isOpen && <Icon size='small' icon={Icons.ChevronRight} />}

            <StyledHeading tag='h6' style={{ display: 'flex', gap: '4px', 'user-select': 'none' }}>{postType.name}</StyledHeading>
          </View>

          {isOpen &&
            <div onClick={(e: React.MouseEvent) => { e.stopPropagation(); }}>
              <ModalLauncher modal={createPostTypeStatusModal}>
                {({ openModal }) => (
                  <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px' }}>
                    <Button label='Create Status' leftIcon={Icons.Plus} iconSize='small' variant='tertiary' type='button' role='button' action={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      openModal({ postTypeId: postType.id });
                    }} style={{ height: 'fit-content' }} />

                    {isEditingOrder ?
                      <Button label='Save Order' variant='tertiary' role='button' action={() => { setIsEditingOrder(false); handleSaveOrder(); }} loading={editStatusOrderIsLoading} style={{ height: 'fit-content' }} />
                      :
                      <Button label='Edit Order' variant='tertiary' role='button' action={() => { setIsEditingOrder(true); setStatusOrder(postType.statuses.map((status) => status.id)); }} style={{ height: 'fit-content' }} />
                    }

                    <ModalLauncher modal={editPostTypeModal}>
                      {({ openModal: openEditModal }) => (
                        <ModalLauncher modal={deletePostTypeModal}>
                          {({ openModal: openDeleteModal }) => (
                            <ActionMenu alignment='right'>
                              <ActionItem label='Edit' onClick={() => { openEditModal({ id: postType.id, name: postType.name }); }} />
                              <ActionItem label='Delete' onClick={() => { openDeleteModal(postType.id); }} />
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      )}
                    </ModalLauncher>
                  </View>
                )}
              </ModalLauncher>
            </div>
          }
        </View>
        {isOpen &&
          (isEditingOrder ?
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={postType.id}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className={css(styles.draggableContainer)}>
                    {statusOrder.map((statusId, index) => {
                      const status = postType.statuses.find((status) => status.id === statusId);
                      if (!status) {
                        return null;
                      }

                      return (
                        <Draggable draggableId={status.id} index={index} key={status.id}>
                          {(provided) => (
                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={css(styles.draggableStatus)}>
                              <Icon icon={Icons.Grip} size='medium' />
                              <StyledParagraph>{status?.publicName} ({status?.internalName})</StyledParagraph>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            :
            <View style={{ backgroundColor: '#ffffff', borderRadius: '4px', gap: '0px', padding: '12px' }}>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Public Name</TableHeaderCell>
                    <TableHeaderCell>Internal Name (Shown to Agents)</TableHeaderCell>
                    <TableHeaderCell>Color (Hex)</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {postType.statuses.map((status) => (
                    <TableRow key={status.id}>
                      <TableCell>{status.publicName}</TableCell>
                      <TableCell>{status.internalName}</TableCell>
                      <TableCell>
                        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '4px' }}>
                          <span className={css(styles.colorSwatch)} style={{ backgroundColor: status.color }}></span>
                          {status.color}
                        </View>
                      </TableCell>
                      <TableCell>
                        <ModalLauncher modal={editPostTypeStatusModal}>
                          {({ openModal: openEditModal }) => (
                            <ModalLauncher modal={deletePostTypeStatusModal}>
                              {({ openModal: openDeleteModal }) => (
                                <View style={{ flexDirection: 'row', gap: '32px' }}>
                                  <Button label='Edit' variant='tertiary' role='button' action={() => { openEditModal({ id: status.id, publicName: status.publicName, internalName: status.internalName, color: status.color }); }} />
                                  <Button label='Delete' variant='tertiary' role='button' destructive action={() => { openDeleteModal({ id: status.id, replacementStatusId: '', confirmation: '' }); }} />
                                </View>
                              )}
                            </ModalLauncher>
                          )}
                        </ModalLauncher>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </View>
          )

        }
      </View>
    </>
  );
}