import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, ErrorPage, FormModal, Link, ModalLauncher, StandardAlert, StandardGrid, StyledHeading, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextArea, TextField, View, generateId, useAlertState } from "@barscience/global-components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import HasAgentPermission from "../../../components/auth/HasAgentPermission";

/* Get Agent Roles Query */
export const GET_AGENT_ROLES = gql`
query getSupportAgentRoles {
  supportAgentRoles {
    id
    name
    description
  }
}
`;

export type GetAgentRolesResponse = {
  supportAgentRoles: AgentRole[];
}

type AgentRole = {
  id: string;
  name: string;
  description: string | null;
}

/* Create Agent Role Mutation */
const CREATE_AGENT_ROLE = gql`
mutation createSupportAgentRole($input: CreateSupportAgentRoleInput!) {
  createSupportAgentRole(input: $input) {
    id
    name
    description
  }
}
`;

type CreateAgentRoleResponse = {
  createSupportAgentRole: AgentRole;
}

type CreateAgentRoleInput = {
  name: string;
  description: string;
}

export default function AgentRoles() {
  const navigate = useNavigate();
  const { addAlert } = useAlertState();
  const { data: roleData, loading: rolesAreLoading, error: rolesError } = useQuery<GetAgentRolesResponse>(GET_AGENT_ROLES, {
    fetchPolicy: 'network-only',
  });
  const [createAgentRole] = useMutation<CreateAgentRoleResponse>(CREATE_AGENT_ROLE, {
    refetchQueries: [
      {
        query: GET_AGENT_ROLES,
      },
    ],
  });

  const handleCreateAgentRole = async (input: CreateAgentRoleInput) => {
    const { data, errors } = await createAgentRole({
      variables: {
        input: {
          name: input.name,
          description: input.description ? input.description : null,
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = (
        <StandardAlert title='Error creating agent role' description={errors[0].message} type='error' id={id} />
      );
      addAlert(id, alert);
    } else {
      navigate(`/agent/settings/agent-roles/${data?.createSupportAgentRole.id}`);
    }
  }

  const createAgentRoleModal = (
    <FormModal<CreateAgentRoleInput> title='Create Agent Role' submitLabel='Create' onSubmit={handleCreateAgentRole} initialValues={{ name: '', description: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Role Name' name='name' required />
        <TextArea label='Description' name='description' />
      </View>
    </FormModal>
  );

  if (rolesError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/agent/settings' />
          <Breadcrumb label='Agent Roles' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <StyledHeading tag='h4'>Agent Roles</StyledHeading>

          <HasAgentPermission permissions={['canManageAgentRoles']}>
            <ModalLauncher modal={createAgentRoleModal}>
              {({ openModal }) => (
                <Button label='Create Role' variant='primary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasAgentPermission>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {rolesAreLoading ?
          <AtomSpinner />
          :
          <Card size='medium'>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Description</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {roleData?.supportAgentRoles.map((role, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell><Link href={'/agent/settings/agent-roles/' + role.id}>{role.name}</Link></TableCell>
                      <TableCell>{role.description}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Card>
        }
      </Cell>
    </StandardGrid>
  );
}